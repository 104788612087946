.feedback--rubric {
  &--container {
    margin-top: 0 !important;
  }

  &--button {
    padding: 0 24px;

    img {
      margin-right: 8px;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: #00819d !important;
      // height: 41px;
      font-size: 15px;
      font-family: $mainFont;
    }
  }
}

.feedback--radio--button {
  label {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
  #radio {
    appearance: none;
    border: 1px solid white;
    box-shadow: 0 0 0 2px #bbbbbb;
    appearance: none;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transition: all ease-in 0.2s;
  }
  #radio:checked {
    background-color: #284374 !important;
  }

  .feedback--radio {
    display: flex;
    align-items: center;
  }

  input[type='radio'] {
    margin: 0 16px 0 24px;
  }

  .MuiAccordionSummary-expandIcon {
    transform: rotate(313deg) !important;
  }
}
