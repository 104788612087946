.activity-feed {
  padding: 16px 18px !important;
  margin-bottom : 60px;
}
.activity--comment--container {
  justify-content: space-between;
  margin-top: 16px;
  height: auto;
  position: relative;
  padding: 12px 10px 0 10px;
  flex-wrap: wrap;

  // Accordion container
  .MuiAccordionDetails-root {
    padding: 0 !important;
    padding-bottom: 22px !important;

    .MuiTypography-root.MuiTypography-body1 {
      width: 100% !important;
    }
  }

  .Mui-expanded {
    p {
      .activity-icons {
        justify-content: center;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }
    }

    .MuiIconButton-edgeEnd {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      height: 50px;
      border-radius: 0 !important;
    }

    .MuiButtonBase-root {
      position: inherit !important;
    }

    .MuiAccordionSummary-expandIcon {
      transition: none;
    }
  }
}

.activity--comment--container.no-flex-mobile {
  .activity--comment {
    flex: 55%;
  }
  .activity--bottom--section {
    width: 100%;
    .activity--comment-box.activity-box3 {
      margin-top: 10px;
      margin-bottom: 0 !important;
    }
    .activity--comment-box.fileType-hidden {
      display: none !important;
    }
  }
}

.community {
  .coach--bar {
    // margin-bottom: 16px;
    // position: initial !important;

    .coach--tabs {
      display: flex !important;
    }

    // .btn-blue-container {
    //   display: none !important;
    //   position: fixed;
    //   bottom: 0;
    //   width: 100%;
    //   box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
    //   display: flex;
    //   justify-content: center;
    //   padding: 12px;
    //   z-index: 1000;
    //   background-color: white;

    //   .btn-blue {
    //     width: 335px !important;
    //   }
    // }
  }

  .community--public.activity {
    .activity--createGroup-btn-blue-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
      justify-content: center;
      padding: 12px;
      z-index: 99;
      background-color: white;
      display: none;

      .btn-blue {
        width: 335px !important;
      }
    }
    .activity-right {
      display: flex !important;
      background-color: white;

      .activity--myGroup-title,
      button {
        display: none;
      }

      .activity--myGroup-item {
        display: flex;
        .activity--myGroup-item__box {
          margin-right: 12px;
          .activity--myGroup-item__box__info-box {
            display: none;
          }

          .activity--myGroup-item__box__img-box {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }

    .activity-left {
      .activity--comment--pinned {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.01em;

        color: #000000;
      }

      .activity-avatar {
        display: flex !important;
        margin-top: 5px;
        margin-left: 3px;
        background: none !important;
        position: relative;

        img {
          width: 45px;
          height: 45px;
        }

        .activity-avatar-name-letter-box {
          width: 26.35px;
          height: 26.35px;
          background: #00819d;
          position: absolute;
          border-radius: 15px;
          bottom: -7px;
          right: -10px;

          .activity-avatar-name-letter {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }
      }

      // Middle box of flex comment
      .activity--bottom--section {
        padding: 0 0 0 6px;
        width: 100%;
        .activity--buttons {
          .activity-icons {
            display: flex;
            width: 95%;
            position: relative;
            top: 29px;
            z-index: 10000;
            justify-content: center;
            align-items: center;

            & > *:not(:last-of-type) {
              margin-right: 16px;
            }
            & > * span {
              pointer-events: auto !important;
            }

            & > *:not(:last-of-type) span {
              display: none;
            }

            & > :last-child {
              margin-left: auto;
              span {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.01em;

                color: #888888;
                margin-right: 5px;
              }
            }
          }
        }

        .activity--comment-box.activity-box3 {
          margin-bottom: 16px;
          margin-top: 10px;
          p {
            margin: 0;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #000000;
          }
        }

        .activity-resource-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 16px 0 0 0;

          span {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #666666;
          }

          .activity-star-rating-box {
            .activity-rating-star-number {
              font-family: 'Cabin';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #00819d;
              margin-left: 5px;
            }
          }
        }
        .activity--comment-box.fileType-show {
          .file-type-img {
            width: 100%;
          }

          .activity-resource-info {
            margin-top: 13px;
          }
        }

        .Mui-expanded {
          margin: 0 !important;
        }

        .MuiAccordion-root:before {
          display: none !important;
        }

        .MuiAccordion-root.Mui-expanded {
          margin: 0 !important;
          height: auto !important;
        }

        // Accordion top part
        .MuiAccordion-root {
          box-shadow: none !important;

          .Mui-expanded {
            min-height: auto;
            margin: 7px 0 !important;
          }

          .MuiAccordionSummary-root.Mui-expanded {
            min-height: none !important;
          }

          .MuiAccordionSummary-root {
            align-items: center;
            // top: -5px;
            padding: 0;
            // margin: 19px 0 12px 0 !important;

            .MuiAccordionSummary-content {
              display: inline-block;
              margin: 0;

              p {
                .activity-icons {
                  display: flex;
                  justify-content: start;
                  position: relative;
                  height: 50px;
                  align-items: center;

                  & > *:not(:last-of-type) {
                    margin-right: 16px;
                  }

                  & > *:not(:last-of-type) span {
                    display: none;
                  }

                  & > :last-child {
                    margin-left: auto;
                    span {
                      font-family: 'Cabin';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 18px;
                      letter-spacing: 0.01em;

                      color: #888888;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }

            .MuiAccordionSummary-expandIcon {
              padding: 0;
              margin-right: 0 !important;
            }

            .Mui-expanded {
              margin-right: 0 !important;
            }
          }
        }

        // Accordion bottom part
        .activity-comment-box {
          display: flex;
          margin-bottom: 16px;

          .activity-comment-box-left {
            .comment-avatar-name-box {
              width: 33px;
              height: 33px;
              background: #00819d;
              border-radius: 20px;

              .comment-avatar-name-letter {
                width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
              }
            }
          }

          .activity-comment-box-right {
            .activity-comment-box-right-top {
              display: flex;
              align-items: center;
              margin-top: 5px;

              .comment-createdBy {
                padding-left: 7px;
                margin-right: 32px;
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.25px;
                color: #363636;
              }

              .comment-updatedDate {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: -0.25px;
                color: #888888;
              }
            }

            .comment-box-bottom {
              background: #eeeeee;
              border: 1px solid #cccccc;
              border-radius: 10px;
              margin-top: 16px;

              .comment-text {
                margin: 0;
                padding: 12px 16px;
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.25px;
                color: #363636;
              }
            }
          }
        }
        .user-comment {
          display: flex;
          margin-top: 21px;
          margin-left: 29px;
          .comment-avatar-name-box {
            width: 33px;
            height: 33px;
            background: #00819d;
            border-radius: 20px;

            .comment-avatar-name-letter {
              width: 33px;
              height: 33px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: 'Cabin';
              font-style: normal;
              font-weight: 400;
              font-size: 17px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
              text-transform: uppercase;
            }
          }

          .activity-input {
            flex: 0 1 93%;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 10px;
            width: 280px;
            padding-left: 8px;
            height: 43px;
            margin-left: 9px;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.25px;
            color: #363636;
          }
        }
        .MuiCollapse-entered {
          .MuiAccordionDetails-root {
            // padding: 20px 0;
          }
        }
        /************ Accordion stop **********/
      }

      .activity--comment {
        margin-left: 16px;
        flex-grow: 2;
        flex: 0 1 70%;

        .activity--comment-box.activity-box1 {
          .activity--comment-title {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            margin: 0;
          }
        }

        .activity--comment-box.activity-box2 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > * {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            margin: 5px 0 10px 0;
          }
          .activity--comment-createdBy {
            font-size: 12px;
            line-height: 16px;
            color: #000000;
          }
          .activity--comment-group {
            margin-left: 16px;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.1em;
            color: #00819d;
            text-transform: uppercase;
          }

          .activity--comment-uploadedDate {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.25px;
            color: #888888;
          }
        }

        .activity--comment-box.activity-box2.no-group {
          justify-content: flex-start;
          .activity--comment-uploadedDate.no-group {
            margin-left: 30px;
          }
        }

        .activity--comment-box.activity-box3 {
          position: absolute;
          left: 18px;

          .activity--comment-notes {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #000000;
            margin: 0;
          }
        }

        .activity--comment-box.activity-box4 {
          display: flex;
          position: absolute;
          bottom: 10px;
          left: 0;
          justify-content: start;
          width: 100%;
          padding: 0 16px;

          & > :last-child {
            margin-left: auto;

            span {
              font-family: 'Cabin';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.01em;

              color: #888888;
              margin-right: 5px;
            }
          }

          & > *:not(:last-of-type) {
            margin-right: 16px;
          }

          & > *:not(:last-of-type) span {
            display: none;
          }
        }

        .activity--comment-box.fileType-show {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0 10px;
          bottom: 28px;
          .file-type-img {
            width: 100%;
          }

          .activity-resource-info {
            margin: 16px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .btn-blue-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .btn-activity-view-more {
          background-color: white !important;
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.01em;
          border-color: #00819d !important;
          color: #00819d !important;
          width: 100%;
        }
      }

      .recent-activity {
        margin-top: 16px;
      }
    }
  }
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
}

@media screen and (min-width: 768px) {
  .community {
    .coach--bar {
      margin-bottom: 0;

      .btn-blue-container {
        display: block !important;
      }
    }
  }

  .activity {
    .activity-left {
      .btn-blue-container {
        .btn-activity-view-more {
          width: 199px !important;
          margin-left: auto;
          font-size: 18px !important;
        }
      }
    }
    .activity--createGroup-btn-blue-container {
      display: none !important;

      .btn-blue {
        width: 335px !important;
      }
    }
  }
  .community--public.activity {
    flex-direction: row;

    .activity-right {
      min-height: 100vh;
      display: flex !important;
      padding: 0 24px;
      background-color: #eeeeee !important;

      .activity--myGroup-title {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #000000;

        border-bottom: 1px solid #ccc;
        padding-bottom: 2px;
      }
      .activity--myGroup-title,
      button {
        display: block !important;
      }

      .activity--myGroup-item {
        display: flex;
        flex-direction: column;
        .activity--myGroup-item__box {
          margin-right: 7px;
          display: flex;
          margin-bottom: 6px;
          .activity--myGroup-item__box__info-box {
            display: flex !important;
            flex-direction: column;

            .activity--myGroup-item__box__info-box__title {
              font-family: 'Cabin';
              font-weight: 500;
              font-size: 16px;
              color: #00819d;
              margin-bottom: 4px;

            }

            .activity--myGroup-item__box__info-box__membersCount {
              font-family: 'Cabin';
              font-weight: 400;
              font-size: 12px;
              color: #666666;
            }
          }

          .activity--myGroup-item__box__img-box {
            margin-right: 24px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: auto;
              height: auto;
            }
          }
        }
      }

      .activity-right-btn-box {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        .activity-right-btn-seeAll {
          width: 350px;
          height: 40px;
          background: #00819d;
          border-radius: 5px;
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.01em;
          color: #ffffff;
          border: 1px solid;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .activity-feed {
    padding: 0 64px 24px !important;
  }
  .activity {
    .activity-left {
      .community--public--feed--container {
        margin-top: 2rem;
      }

      .recent-activity {
        margin-top: 24px !important;
      }
    }
  }
  .community--public.activity {
    .activity-right {
      padding: 1.5rem 2.5rem !important;

      .activity--myGroup {
        width: 100%;
        .activity--myGroup-item {
          .activity--myGroup-item__box {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  span.activity--myGroup-item__box__info-box__title {
    display: none;
  }
}

@media screen and (min-width: 1110px) {
  .activity--bottom--section {
    margin: 0 auto;
    flex: 0 1 80%;
    padding: 0 10px !important;

    .activity--comment-notes {
      font-size: 15px !important;
    }
  }

  .activity--comment {
    flex: 0 1 80% !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .community
    .community--public.activity
    .activity-left
    .activity--bottom--section
    .MuiAccordion-root
    .MuiAccordionSummary-root {
    margin-bottom: 10px !important;
  }

  .activity {
    .MuiAccordionDetails-root {
      padding: 8px 10px 16px;

      .MuiAccordionSummary-root {
        margin-bottom: 10px !important;
      }
    }
    .activity-left {
      .user-comment {
        .activity-input,
        .activity-input::placeholder {
          width: 376px !important;
        }
      }
      .activity--comment {
        .activity--comment-box.activity-box1 {
          .activity--comment-title {
            font-size: 22px !important;
          }
        }

        .activity--comment-box.activity-box2 {
          margin-top: 10px !important;
          margin-bottom: 10px !important;

          justify-content: start !important;
          .activity--comment-createdBy {
            font-size: 16px !important;
            margin-right: 30px !important;
          }
          .activity--comment-group {
            margin-right: 100px !important;
          }

          .activity--comment-uploadedDate {
            font-size: 12px !important;
          }
        }
      }
      // .activity-avatar {
      //   img {
      //     width: 53.07px !important;
      //     height: 53.07px !important;
      //   }

      //   .activity-avatar-name-letter-box {
      //     top: 50px !important;
      //     left: 45px !important;
      //     top: 40px !important;
      //     border-radius: 15px;
      //     left: 33px !important;

      //     .activity-avatar-name-letter {
      //       font-size: 14p;
      //     }
      //   }
      // }
    }
  }

  .activity--comment--container {
    padding: 30px 40px 0 28px;
  }

  .community--public.activity {
    .activity-right {
      padding: 2.5rem 4rem !important;

      .activity--myGroup-item {
        margin-top: 40px;
        .activity--myGroup-item__box {
          margin-bottom: 10px;
          .activity--myGroup-item__box__info-box {
            justify-content: center;
          }

          .activity--myGroup-item__box__img-box {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: auto !important;
              height: 100% !important;
            }
          }
        }
      }
    }
  }

  p .activity-icons > :not(:last-of-type) span {
    display: inline-block !important;
    margin-left: 7px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #00819d;
  }

  p .activity-icons > :not(:last-of-type) {
    margin-right: 24px !important;
  }

  p .activity-icons > :not(:last-of-type):nth-child(2) {
    margin-left: auto;
  }

  p .activity-icons > * {
    display: flex;
    align-items: center;
  }

  p .activity-icons > :last-child {
    margin-left: initial !important;
  }

  // .Mui-expanded {
  //   height: 50px;
  //   border-top: 1px solid;
  //   border-bottom: 1px solid;
  //   flex: 0 0 auto;
  //   color: rgba(0, 0, 0, 0.54);
  //   padding: 12px;
  //   overflow: visible;
  //   font-size: 1.5rem;
  //   text-align: center;
  //   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  //   border-radius: none;
  // }
}

.activity--myGroup-desktop {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.activity--myGroup-mobile {
  display: block;

  .activity--myGroup-item {
    width: 100vw;
    overflow-x: auto;
    padding-left: 16px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    margin-top: 16px;
  }

  .activity--myGroup-item::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }

  .activity--myGroup-item::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
