.zip-code {
    .zip {
        color: #4D4D4D;
        font-size: 14px;
    }

    input {
        outline: none;
        border: none;
        // border-right: 1px solid #1CA6BA;
        // border-bottom: 1px solid #1CA6BA;
        box-shadow: 1px 1px #17A6BA;
        background-color: #F3FDFF;
        height: 44px;
        border-radius: 5px;
        padding: 8px 24px;
    }

    input[type=text] {
        color: #000;
        font-size: 18px;
        font-weight: bold;
    }
}