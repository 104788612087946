.creds-summary {
  display: flex;
  justify-content: space-between;
  // padding: 1.5rem 4rem;
  padding: 0;
}

.achievements {
  padding: 0;
  .transcript-select {
    margin: 32px 0 16px;

    @media screen and (max-width: 968px) {
      margin: 48px 0 16px;
    }
  }

  @media screen and (max-width: 968px) {
    .primary--modal .primary--modal--container {
      padding: 24px 16px 32px;
    }
  }

  .MuiSelect-select:focus {
    background: transparent;
  }

  .MuiSelect-select {
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #888888;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    background: transparent;
  }

  .MuiInputBase-root {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;

    svg {
      fill: $mainColor;
    }
  }

  .MuiMenu-list {
    height: auto !important;

    li {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
  }

  @media screen and (max-width: 968px) {
    // .creds-summary {
    //   padding: 1rem 32px;
    // }

    .creds-li-left {
      img {
        width: 30%;
      }
    }

    .creds-container .creds-ul .creds-li .creds-li-left .creds-info-box .creds-title {
      font-size: 16px;
    }

    .creds-container .creds-ul .creds-li .creds-li-left .creds-info-box .creds-date {
      font-size: 16px;
    }

    .creds-container .creds-ul .creds-li {
      flex-direction: column;
      height: auto;
      padding: 13px;
    }

    .creds-container .creds-ul .creds-li .creds-li-right .btn-share-cred,
    .creds-container .creds-ul .creds-li .creds-li-right .btn-download-cred {
      width: 49%;
    }

    .creds-container .creds-ul .creds-li .creds-li-right {
      margin-top: 16px;
    }

    .creds-container .creds-ul .creds-li .creds-li-right span {
      font-size: 14px;
    }
  }
}

.transcript-menu {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
  background: #fff;
  margin-top: -6px !important;
}

@media screen and (min-width: 1020px) {
  .achievements {
    padding: 32px 0;
  }

  .creds-container .creds-ul .creds-li {
    padding: 16px;
  }
  .creds-container .creds-ul .creds-li .creds-li-left .creds-info-box .creds-title {
    font-size: 18px;
  }
  // .creds-container .creds-ul .creds-li .creds-li-right {
  //   margin-top: 32px;
  // }
}
