.login {
  background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../images/kids.jpg') top center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: 107%;
  background-position: -100px 20%;
  height: 100vh;
  overflow: hidden;
  font-weight: bold;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .submit-button {
    height: 40px !important;
  }
  
  .MuiFormLabel-root {
    color: #fff;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
  }
  .form-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  .form-container::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .form {
    padding-top: 0 !important;
    border: 1px solid #1CA6BA;
    border-right: 3px solid #1CA6BA;
    border-bottom: 3px solid #1CA6BA;
    height: 75vh;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    padding-bottom: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    img {
      position: absolute;
      width: 80px;
      top: -35px;
      z-index: 9999;
    }
  }
  .login-form {
    padding: 56px 0 0;
    margin: auto;
  }
  .hr {
    border: 0.1px solid #1CA6BA;
    width: 100%;
    height: 0.5px;
  }
  .login-field {
    display: flex;
    align-items: center;
    position: relative;
    .show-password {
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      height: 40px;
      width: 40px;
      padding: 8px;
      border-radius: 5px;
      z-index: 5;
    }
    input {
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      padding-left: 16px;
      z-index: 1;
      font-weight: bold;
      background: rgba(141, 220, 232, 0.1);
      border-radius: 5px;
      border-right: 1px solid #1CA6BA;
      border-bottom: 1px solid #1CA6BA;
      color: #000 !important;
      font-size: 16px !important;
    }
    input[type=text] {
      color: #000 !important;
      font-size: 16px !important;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
  }
  .login-button {
    margin: auto;
    margin-top: 32px;
    border-radius: 5px;
    width: 100%;
    background-color: #1CA6BA;
    align-items: center;
    padding: 18px 0;
    text-transform: none;
    font-weight: bolder;
    border: none;
    box-shadow: none;
    height: 40px;
    span {
      font-weight: bolder;
      margin: 0;
      padding: 0;
      line-height: 0;
      font-size: 16px;
    }
  }
  .login-button:hover {
    background-color: rgba(141, 220, 232, 1);
  }
  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 32px;
      color: black;
    }
    div {
      a {
        color: #1CA6BA;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  .login-bottom-links {
    padding: 32px 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    a {
      font-weight: bold;
      text-transform: uppercase;
      color: #1CA6BA;
    }
  }
  button {
    margin-top: 32px !important;
  }
  .sign-up-button {
    text-align: center;
    font-size: 16px;
    p {
      margin-bottom: 0;
    }
    a {
      font-weight: bold;
      text-transform: uppercase;
      color: #1CA6BA;
    }
  }
  @media screen and (max-width: 1400px) {
    background-size: 112%;
  }
  @media screen and (max-width: 800px) {
    background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../images/kids.jpg');
    /* Center the image */
    background-repeat: no-repeat;
    background-size: contain;
    /* Do not repeat the image */
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000;
  }
}