.notifications {
  height: 100%;
  min-height: 90vh;
  padding: 16px;
  position: relative;
  h1 {
    margin-top: 0;
  }
  .notifications-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .notification-icon {
      width: 158px;
      height: 155px;
      transform: matrix(0.97, -0.26, 0.26, 0.97, 0, 0);
      color: #d8e4fa;
    }
    p {
      margin: 0 auto;
      font: normal normal normal 20px/26px Roboto;
      letter-spacing: 0px;
      color: #585858;
      width: 300px;
    }
  }
}
