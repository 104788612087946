.top-card:before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
}

@media screen and (min-width: 900px) {
    .top-card {
        background-position: 0 -80px !important;
    }
}

@media screen and (max-width: 900px) {
    .top-card {
        background-position: center -80px !important;
    }
}

.iframe {
    position: fixed;
    width: 50%;
    height: 100%;
    z-index: 999999999;
    border: 0;
    box-shadow: none;
    z-index: 100;
    top: 0;
    right: 0;
}

@media screen and (min-width: 900px) {
    .iframe {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
}

