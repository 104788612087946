.length-of-time {
  p {
    font-size: 18px;
  }
  .radio {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      margin: 0; 
    }
  }
  .radio input[type=radio],
  .radio input[type=radio]:after {
    width: 28px;
    height: 28px;
    margin: 0px 8px 0 0;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
  }
}