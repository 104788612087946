.completed-activities {
    margin: 16px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 9px 15px #8690A538;
    border: 1px solid #EDF0F7;
    padding: 16px;
    .completed-activities-content {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      h4,
      p {
        margin: 0;
      }
      .trophy {
        padding: 24px 24px 16px;
        margin-right: 16px;
        background-image: linear-gradient(to right top, #09009b, #1a00a9, #2801b7, #3401c5, #4001d3, #7900c4, #9a00b6, #b000a9, #c50083, #cb005f, #c7003d, #bb1f1f);
        border-radius: 10px;
        svg {
          color: #fff;
          -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
          filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        }
      }
      .text {
        width: 100%;
      }
      .completion {
        display: flex;
        justify-content: flex-end;
        svg {
          color: orange;
        }
      }
    }
  } 