$xxs-spacing: 2px;
$xs-spacing: 4px;
$sm-spacing: 8px;
$md-spacing: 16px;
$lg-spacing: 24px;
$lg-plus-spacing: 30px;
$xl-spacing: 32px;
$xl-plus-spacing: 48px;
$xxl-spacing: 64px;

$border-radius-x: 2px;
$border-radius-m: 5px;
$border-radius-l: 8px;
$border-radius-rounded: 50%;



