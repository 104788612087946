.edited-card {
    .no-image {
        width: auto;
        height: 40% !important;
    }
    
    .edited-card-top {
        display: flex;
        border-radius: 5px;

        .first-image {
            width: 61%;
            overflow: hidden;
            border-radius: 5px;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #D9F1F6;
            background-position:center center !important;
            -webkit-background-size:cover !important;
            -moz-background-size:cover !important;
            -o-background-size:cover !important;
            background-size:cover !important;
            background-repeat: no-repeat !important; 

            img {
                width: 110%;
                height: auto;
            }
        }

        .edited-card-images-right {
            overflow: hidden;
            width: 39%;
            height: 160px;
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
                height: 48.5%;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                background: #D9F1F6;
                background-position:center center !important;
                -webkit-background-size:cover !important;
                -moz-background-size:cover !important;
                -o-background-size:cover !important;
                background-size:cover !important;
                background-repeat: no-repeat !important; 

                img {
                    width: 160px;
                    height: auto;
                    border-radius: 5px;
                }
            }
        }
    }

    .edited-card-top-two {
        display: flex;
        border-radius: 5px;
        height: 160px;
        overflow: hidden;
        justify-content: space-between;

        .first-image {
            width: 49.5%;
            height: 160px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #D9F1F6;
            background-position:center !important;
            -webkit-background-size:cover !important;
            -moz-background-size:cover !important;
            -o-background-size:cover !important;
            background-size:cover !important;
            background-repeat: no-repeat !important; 

            img {
                width: 100%;
                height: auto;
            }
        }

        .second-image {
            width: 49.5%;
            height: 160px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #D9F1F6;
            background-position:center !important;
            -webkit-background-size:cover !important;
            -moz-background-size:cover !important;
            -o-background-size:cover !important;
            background-size:cover !important;
            background-repeat: no-repeat !important; 

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .edited-card-top-one {
        display: flex;
        border-radius: 5px;
        height: 160px;
        overflow: hidden;
        
        .first-image {
            width: 100%;
            height: 160px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #D9F1F6;
            background-position:center;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-size:cover;
            background-repeat: no-repeat; 

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .edited-card-bottom {
        margin-top: 12px;

        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-position:center;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-size:cover;
            background-repeat: no-repeat; 

            .edited-card-title {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                color: #00819D;
                margin: 0;
            }

            .edited-card-buttons {
                display: flex;
                align-items: center;

                img {
                    margin-left: 8px;
                }
            }
        }
        .edited-text {
            margin: 0;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #666666;
        }
    }
}

@media screen and (max-width: 500px) {
    .edited-card {
        margin-bottom: 24px;
    }
}

.recently-edited-masonry-grid {
    margin-left: -48px !important;
}

.recently-edited-masonry-column {
    padding-left: 48px !important;
}
