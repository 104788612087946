.coaching {
}

.react--table {

  // width: 100% !important;
  max-width: 1236px !important;
  padding-bottom: 64px;
}

.react--table th .table--colHeaderBox {
    justify-content: unset !important;
    align-items: center;
    padding-left: 0 !important;
    padding-right: 3rem !important;
    p {
      margin-right: 8px;
      font-size: 15px;
      font-weight: 400;
      font-family: $mainFont;
    }

    span {
      margin-top: 4px;
      margin-left: 8px;
    }
}

.coming--soon {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coaching--search-messages {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;

  @media screen and (max-width: 900px) {
    display: none;
  }

  // @media screen and (min-width: 900px) {
  //   margin: 24px 0 8px 10px;
  // }
  
  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0 8px;
  }

  ::placeholder {
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #888888;
  }
}

.coaching--messages { 
  .messages-resources {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .messages-content {
      .MuiAccordionDetails-root {
        flex-direction: column;
      }
    }

    .messages-resource {   
      width: 49%;   
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 24px 0;

      &-content {
        display: flex;
        align-items: flex-start ;
        padding-top: 8px;
        width: 100%;

        img {
          margin-right: 8px;
          margin-top: 2px;
        }

        .video-svg {
          height: 12px;
        }

        p {
          font-family: 'Cabin';
          font-weight: 500;
          font-size: 13px;
          color: #00819D;
          margin: 0;
          line-height: 1;
        }
      }

      .messages-resource-image {
        position: relative;
        background-position:center;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        background-repeat: no-repeat;
        height: 145px;
        width: 100%;

        span {
          position: absolute;
          width: 17.28px;
          height: 17.28px;
          background: #DF187D;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 6px;
          right: 6px;
          border-radius: 50%;

          img {
            width: 8.64px;
            height: 8.64px;
          }
        }
      }
    }
  }

  .coaching--messages-all {
    background: #EEEEEE;
    padding: 24px 0;
    min-height: calc(100vh - 126px);
    z-index: 99;
    margin-bottom: 60px;
    
    @media screen and (min-width: 900px) {
      width: 40vw;
      padding: 32px;
      margin-bottom: 0;

      // &-container {
      //   max-width: 544px;
      // }
    }

    .coaching--messages-all-list {
      li {
        cursor: pointer;
      }

      .coach-information {
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 28px;
        padding-bottom: 36px;
      }
    }

    .messages-title {
      color: #000;
      font-family: 'Cabin';
      font-weight: 500;
      font-size: 15px;
      margin: 0;

      @media screen and (min-width: 900px) {
        font-size: 24px;
        margin-left: 12px;
      }
    }

    .search-container {
      position: relative;

      .search-list {
        position: absolute;
        top: 44px;
        background: #fff;
        border-radius: 5px;
        width: 298px;
        margin-left: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
        z-index: 999;
        height: 400px;
        overflow-x: hidden;
        overflow-y: auto;

        @media screen and (max-width: 900px) {
          margin-left: 0;
        }

        p.search-content {
          font-weight: 600;
          padding: 8px 16px;
          margin: 0;
          border-top: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          
        }

        li {
          padding: 8px 16px;
          border: none;
        }
      }
    }

    ul {
      li {
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #CCCCCC;
        padding: 14px 0 0;

        .active-message {
          width: 3px;
          height: 51px;
          margin-right: 12px;
        }

        .active-line {
          background: #00677E;
        }

        p {
          margin: 0;
          font-family: 'Cabin';
        }

        .coaching--messages-avatars {
          position: relative;

          span {
            border-radius: 5px;
            border: 1px solid #FFFFFF;
            margin-bottom: 8px;
          }

          &-two {
            position: absolute;
            left: 24px;
            top: 10px;
          }
        }

        .coaching--messages-avatar {
          position: relative;
          margin-right: 36px;
      

          .no-image {
            width: 50px;
            height: 50px;
            background: #bdbdbd;
          }

          span {
            border-radius: 5px;
            display: flex;
          }

          .first-initial {
            background: #00819D;
            border-radius: 50%;
            width: 31px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: 'Cabin';
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.02em;
            position: absolute;
            bottom: -7px;
            right: -10px;
          }
        }

        .coaching--messages-content {
          width: 100%;

          &-top {
            display: flex;
            align-items: center;
            margin-top: 6px;

            .message-name {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #00819D;
              margin-right: 48px;
            }

            .message-type {
              font-family: 'Cabin';
              font-weight: 500;
              font-size: 10px;
              letter-spacing: 0.1em;
              color: #00819D;
              text-transform: uppercase;
            }

            .message-last-updated {
              font-weight: 400;
              font-size: 10px;
              letter-spacing: -0.25px;
              color: #888888;
            }
          }

          .message-message {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: -0.234375px;
            color: #666666;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    display: flex;
  }

  .coaching--messages-single {
    position: relative;
    padding: 0 0 16px 0;
    margin-bottom: 60px;

    @media screen and (min-width: 900px) {
      width: 60vw;
      padding: 16px 0;
      margin-bottom: 0;

      // &-container {
      //   margin-left: auto;
      //   max-width: 970px;
      // }
    }

    &-top {
      display: flex;
      align-items: center;
      padding: 0 16px 16px;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;
      }

      .coaching--messages-avatars {
        position: relative;

        @media screen and (max-width: 900px) {
          margin-right: 16px;
        }

        .coaching--messages-avatars-one {
          margin-right: 16px;
        }

        .coaching--messages-avatars-two {
          position: absolute;
          left: 24px;
          top: 10px;
          border: 1px solid #fff;
          border-radius: 6px;
        }  
      }

      @media screen and (min-width: 900px) {
        padding: 16px 84px 40px 32px;
      }

      p {
        margin: 0;
      }

      .message-name {
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin-right: 48px;
      }

      .message-type {
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 10px;
        color: #00819D;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-top: 3px;
      }

      &-avatar {
        position: relative;
        margin-right: 32px;

        .coaching--messages-avatar {
          width: 45px;
          height: 45px;
          display: flex;
          overflow: hidden;
          border-radius: 6px;
          .no-image {
            width: 50px;
            height: 50px;
            background: #bdbdbd;
          }
        }

        .first-initial {
          background: #00819D;
          border-radius: 50%;
          width: 31px;
          height: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.02em;
          position: absolute;
          bottom: -7px;
          right: -10px;
        }
      }
    }


    .message-messages::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
    }
    
    .message-messages::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    .message-messages {
      padding: 16px;
      margin-bottom: 80px;

      @media screen and (min-width: 900px) {
        padding: 16px 80px 16px 32px;
        padding-bottom: 200px;
        height: 70vh;
        overflow-y: auto;
        margin-bottom: 0;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
      }

      .messages-list:nth-child(even) {
        flex-direction: row-reverse;

        .first-initial {
          margin-right: 0;
          margin-left: 8px;
        }

        .messages-message {
          background: $mainColor;
          color: #fff;
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 12px;
          margin-left: 48px;
          margin-right: 0;

          @media screen and (max-width: 900px) {
            margin-right: 0;
            margin-left: 0;
          }
        }

        .messages-last-updated {
          margin-left: 48px;
          text-align: right;
        }
      }

      .messages-list {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        
        @media screen and (min-width: 900px) {
          margin-bottom: 24px;
        }

        .first-initial {
          width: 40px;
          height: 40px;
          background: #00819D;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.02em;
          border-radius: 50%;
          text-transform: uppercase;
          margin-right: 8px;
          margin-bottom: 22px;
          flex-shrink: 0;
        }
        
        p {
          margin: 0;
        }

        span {
          width: 100%;
        }

        .messages-message {
          padding: 16px 24px;
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 16px;
          letter-spacing: -0.25px;
          color: #363636;
          border: 1px solid #CCCCCC;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          margin-right: 80px;

          @media screen and (max-width: 900px) {
            margin-right: 0;
            margin-left: 0;
          }
        }

        .messages-last-updated {
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.01em;
          color: #666666;
          margin-top: 6px;
        }
      }
    }

    .coaching--messages-post {
      position: fixed;
      width: 60vw;
      display: flex;
      justify-content: center;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      border-top: 1px solid #CCCCCC;
      padding: 32px 16px;
      background: #fff;

      @media screen and (max-width: 900px) {
        position: fixed;
        padding: 16px;
        width: 100%;
      }

      .coaching--messages-post-form {
        width: 100%;

        @media screen and (min-width: 900px) {
          padding: 0 112px 0 32px;
          // max-width: 970px;
          // margin-left: auto;
        }

        textarea {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #BBBBBB;
          border-radius: 5px;
          height: 40px;
          padding: 8px 16px;
          font-family: $mainFont;
          outline: none;
          margin-bottom: 16px;

          @media screen and (min-width: 900px) {
            height: 100px;
            padding: 16px;
          }
        }

        .coaching--messages-buttons {
          display: flex;
          justify-content: space-between;

          @media screen and (min-width: 900px) {
            justify-content: flex-end;
          }

          button {
            border: none;
            letter-spacing: 0.01em;
            font-size: 15px;
            font-family: 'Cabin';
            font-weight: 600;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 49%;

            @media screen and (min-width: 900px) {
              width: 164px;
            }
          }

          .attach {
            background: #FFFFFF;
            border: 1px solid #00819D;
            color: #00819D;

            @media screen and (min-width: 900px) {
              margin-right: 8px;
            }

            img {
              margin-right: 12px;
            }
          }

          .send {
            background: #00819D;
            color: #FFFFFF;
          }
        }
      }
    }

    .messages-button {
      padding: 0 16px;
    }

    .my-messages {
      width: 100%;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid $mainColor;
      border-radius: 5px;
      color: $mainColor;
      font-family: 'Cabin';
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.01em;
      color: #00819D;
      margin-bottom: 16px;
      margin-top: 24px;

      img {
        margin-right: 16px;
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  }
}

.messages-button {
  padding: 0 16px;
}

.my-messages {
  width: 100%;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid $mainColor;
  border-radius: 5px;
  color: $mainColor;
  font-family: 'Cabin';
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #00819D;

  img {
    margin-right: 16px;
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.messages--accordian {
  border: none !important;
  background: none !important;
  border-radius: none !important;
  box-shadow: none !important;
  margin-bottom: 8px;

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .messages--accordian-summary {
    margin: 0;
    min-height: 0 !important;

    .messages-title {
      margin-left: 0;
      font-family: 'Cabin';
      font-weight: 600;
      font-size: 24px;
      color: #000000;
    }
  }

  .messages--accordian-details {
    flex-direction: column;
    padding: 0 16px;

    .messages-see-more {
      width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #00819D;
      border-radius: 5px;
      font-family: 'Cabin';
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.01em;
      color: #00819D;
      background: transparent;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .messages-files {
      margin-top: 8px;
      margin-bottom: 24px;

      .messages-file {
        display: flex;
        align-items: center;

        span {
          width: 40px;
          display: flex;
        }

        p {
          font-family: 'Cabin';
          font-weight: 500;
          font-size: 16px;
          color: #00819D;
        }
      }
    }

    .messages-length {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 15px;
      color: #666666;
      margin: 0;
    }
  }
}

.messages--accordian:before {
  background-color: transparent !important;
}

.session--tab {
  margin: 0 auto;
  max-width: 1236px;
  padding: 0 16px;

  @media screen and (max-width: 900px) {
    padding: 16px;
    margin-bottom: 30px;
  }
}

// sessions button
@media screen and (max-width: 900px) {
  .messages-btn {
    display: none !important;
  }
  .coach--top--btns {
    // display: none !important;
    justify-content: space-between !important;

    padding: 10px 16px !important;

    .single-btn {
      margin-left: 0 !important;
      min-width: 100%;
    }
  }
}