
.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }
  
  /* VARIABLES */
  
  :root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }
  
  /* GENERAL */
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
  }

  .month-and-year {
    @media screen and (max-width: 968px) {
      font-family: 'Cabin';
      font-weight: 700;
      font-size: 15px;
      color: #000000;
    }
  }
  
  .calendar header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    // border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
  }
  
  header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
  }
  
  header #logo .icon {
    padding-right: 0.25em;
  }
  
  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }
  
  /* GRID */
  
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
    color: #000;
    font-size: 15px;
    font-family: $mainFont;
    font-weight: 700;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    // border: 1px solid var(--border-color);
  }

  .flex-middle {
     padding: 24px 24px  12px;
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    // border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }
  
  .calendar .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {
    text-transform: uppercase;
    padding: 0.75em 0 0;
    // border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    // border-right: 1px solid var(--border-color);
    // overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    padding-bottom: 24px;
  }

  .cell {
    p {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 15px;
      color: #000000;
    }
  }
  
  .calendar .body .cell:hover {
    // background: var(--bg-color);
    transition: 0.5s ease-out;
  }
  
//   .calendar .body .selected {
//     border-left: 10px solid transparent;
//     // border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
//     border-image-slice: 1;
//   }
//   .calendar .body .today {
//     border-left: 10px solid transparent;
//     // border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
//     border-image-slice: 1;
//   }
//   .calendar .body .row {
//     // border-bottom: 1px solid var(--border-color);
//   }
  
  /* .calendar .body .row:last-child {
    border-bottom: none;
  } */
  
  .calendar .body .cell:last-child {
    border-right: none;
  }

  .progress-complete {
    .MuiCircularProgress-colorPrimary {
        color: #008A17 !important;
        background: rgba(0, 138, 23, 0.1);
        border-radius: 50%;
    }
}

.progress-incomplete {
    .MuiCircularProgress-colorPrimary {
        color: #EDC902 !important;
    }

    .progress-background {
        position: absolute;
        margin-top: 6px;
        .MuiCircularProgress-colorPrimary {
            color: rgba(237, 201, 2, 0.1) !important;
            border-radius: 50%;
        }

        circle {
            // fill: none;
            // stroke: #646464;
            // stroke-width: 1px;
            // stroke-dasharray: 2,2;
          }
    }
}

// .progress-wrapper {
//     position: relative;
// }
  
  .calendar .body .cell .number {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        position: absolute;
        font-family: $mainFont;
        font-size: 15px;
        margin: 0;
        color: #000;

    }
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
  
  .calendar .body .cell:hover .bg,
  .calendar .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
  }
  .calendar .body .cell.today .bg {
    // color: #ff1a79;
    opacity: 0.05;
  }
  
//   .calendar .body .col {
//     flex-grow: 0;
//     flex-basis: calc(100% / 7);
//     width: calc(100% / 7);
//   }

  .calendar {
    border-radius: 8px;
    box-shadow: 1px, 0px rgba(0, 0, 0, 0.12);

    .MuiCircularProgress-root {
        transform: rotate(90deg);
    }
  }