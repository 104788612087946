.feedback--comment {
    display: flex;
    padding: 0 35px;
    font-family: $mainFont;
    margin-bottom: 10px;

    &--time {
        background: #00819D;
        border-radius: 3px;
        height: 20px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        margin-right: 8px;
        margin-top: 16px;
    }

    &--content {
        background: #fff;
        border-radius: 8px;
        padding: 24px 60px 24px 15px;
        position: relative;
        width: 100%;
    }

    &--more {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            fill: #AAAAAA;
        }
    }

    p {
        margin: 0;
    }

    &--text {
        font-weight: 400;
        font-size: 13px;
        color: #222;
    }

    &--uploaded {
        font-size: 12px;
        color: #666666;
        margin-top: 8px !important;
    }
}

.student--feedback--comments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;;
    height: 100%;

    @media screen and (max-width: 900px) {
        padding-bottom: 60px;
    }

    &--form {
        .feedback--rubric--button {
            margin-top: 24px !important;
            padding: 0 !important;
        }
    }
}