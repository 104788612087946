.community--private {
    display: flex;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
    }

    &--feed {
        width: 60vw;
        height: 100%;
        padding: 0 64px 24px;

        @media screen and (max-width: 900px) {
            width: 100%;
            padding: 0;
        }

        &--recent {
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            margin-top: 16px;
            // padding: 12px 64px 12px 24px;

            @media screen and (max-width: 900px) {
                border: none;
            }

            &--join {
                background: #EEEEEE;
                border-radius: 5px;
                padding: 24px 64px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    margin: 0;
                    font-family: 'Cabin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                }

                p {
                    font-family: 'Cabin';
                    font-weight: 400;
                    font-size: 15px;
                    width: 80%;

                    @media screen and (max-width: 900px) {
                        width: 100%;
                    }
                }

                button {
                    width: 134.22px;
                    height: 40.18px;
                    background: #00819D;
                    border: 1px solid #00819D;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-family: 'Cabin';
                    font-weight: 600;
                    font-size: 15px;
                    img {
                        margin-right: 8px;
                    }
                }
            }

            &--content {
                padding: 12px 64px 12px 24px;

                @media screen and (max-width: 900px) {
                    padding: 0 16px 16px;
                }

                &--top {
                    display: flex;


        @media screen and (max-width: 900px) {
            padding: 16px;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
        }

                    .name {
                        font-family: 'Cabin';
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .date {
                        font-family: 'Cabin';
                        font-weight: 400;
                        font-size: 12px;
                        color: #888888;
                    }

                    .community--comment--container {
                        margin-left: 24px;
                        width: 100%;

                        span {
                            display: flex;
                            align-items: center;

                            p {
                                margin-right: 36px;
                            }
                        }
                    }

                    .community--comment {
                        font-family: 'Cabin';
                        font-weight: 400;
                        font-size: 15px;
                        margin: 0;
                        background: #eee;
                        border-radius: 5px;
                        width: 100%;
                        padding: 16px;
                    }

                    .no-profile-image {
                        background: $mainColor;
                        border-radius: 50%;
                        width: 35px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        text-transform: uppercase;
                        font-family: 'Cabin';
                        font-weight: 700;
                        font-size: 14px;
                        color: #EEEEEE;
                        margin-top: 12px;
                    }


                    .profile-image {
                        width: 45px;
                        height: 45px;
                        border-radius: 5px;
                        // overflow: hidden;
                        background: $mainColor;
                        position: relative;
                        img {
                            width: 45px;
                            height: 45px;
                            border-radius: 5px;
                        }
                        span {
                            position: absolute;
                            top: 24px;
                            right: -6px;
                            width: 30px;
                            height: 30px;
                            background: $mainColor;
                            border-radius: 50%;
                            @include flexbox();
                            color: #fff;
                            font-family: 'Cabin';
                            font-weight: 700;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        &--container {
            max-width: 800px;
            width: 100%;
            float: right;
        }

        .recent--activity {
            margin: 0;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;

            @media screen and (max-width: 900px) {
                margin-top: 16px;
                margin-left: 16px;
            }
        }
    }

    &--content {
        width: 40vw;
        background: #EEEEEE;
        min-height: calc(100vh - 127px);
        flex-grow: 1;
        height: 100%;

        @media screen and (max-width: 900px) {
            width: 100%;
            height: auto;
            min-height: unset;

            &--buttons {
                display: flex;

                button {
                    margin: 24px 0 !important;
                    height: 40px;
                    width: 110px !important;
                }

                .see-members {flex-shrink: 0;
                    margin-left: 8px !important;
                    background: transparent !important;
                    border: 1px solid $mainColor;
                    color: $mainColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin-left: 8px;
                        margin-right: 0 !important;
                    }
                }
            }
        }

        .members--list {
            @media screen and (max-width: 900px) {
                display: none;
            }

            &--member-count {
                color: #888888;
                font-size: 18px;
                font-weight: 600;
                font-family: 'Cabin';
            }

            .see-all {
                width: 100%;
                max-width: 347px;
                background: $mainColor;
                color: #fff;
              }

            &--users {
                display: flex;
                align-items: center;
                margin: 16px 0;

                &--avatar {
                    width: 48px;
                    height: 48px;
                }

                &--name {
                    font-family: 'Cabin';
                    font-style: normal;
                    font-weight: 500;
                    color: $mainColor;
                    margin: 0 32px; 
                    width: 120px;
                    font-size: 16px;
                }

                &--joined {
                    font-family: 'Cabin';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 10px;
                    color: #666666;
                }

                span {
                    height: 48px;
                    width: 48px;
                    background: $mainColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: 'Cabin';
                    font-weight: 700;
                    font-size: 24px;
                    text-transform: uppercase;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }

        &--image--container {
            overflow: hidden;
            height: 350px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            @media screen and (max-width: 900px) {
                height: auto;
                padding: 24px;

                h1 {
                    font-size: 20px !important;
                    margin: 0;
                }

                .group--privacy {
                    display: flex;
                    flex-direction: column;

                    &--happy {
                        height: 18px; 
                    }

                    span {
                        display: flex;
                        height: 15px;
                        width: auto;
                        align-items: center;

                        img {
                            margin-left: 16px;
                        }
                    }
                }
            }

            .community--private--content--image {
                width: 110%;
                height: auto;
            }

            @media screen and (max-width: 900px) {
                overflow: unset;
                // justify-content: unset;
                align-items: center;

                &--left {
                    width: 80px;
                    height: 80px;
                    // flex: 1;
                    border-radius: 5px;
                    overflow: hidden;
                    margin-right: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-position:center center;
                    -webkit-background-size:cover;
                    -moz-background-size:cover;
                    -o-background-size:cover;
                    background-size:cover;
                    background-repeat: no-repeat;
                    flex-shrink: 0;

                    // .community--private--content--image {
                    //     width: auto;
                    //     height: 100%;
                    // }
                }

                &--right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: 80px;

                    p {
                        margin: 0;
                    }

                    .private--container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            font-family: 'Cabin';
                            font-weight: 400;
                            font-size: 13px;
                            color: #888888;
                        }
                    }
                }
            }
        }

        &--container {
            padding: 32px;

            @media screen and (max-width: 900px) {
                padding: 0 24px 0 24px;
            }

            h1 {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                margin: 0;
                margin-bottom: 24px;

            }

            .group--information {
                color: $mainColor;
                display: flex;
                align-items: center;
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;

                img {
                    margin-right: 6px;
                }
            }

            .group--description {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;

                @media screen and (max-width: 900px) {
                   display: none;
                }
            }

            .group--privacy {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 32px;
                p {
                    font-family: 'Cabin';
                    font-weight: 400;
                    font-size: 18px;
                    color: #888888;
                    margin: 0;
                }

                &--happy {
                    height: 18px !important; 
                }

                span {
                    display: flex;
                    height: 15px;
                    width: auto;
                    align-items: center;

                    img {
                        margin-left: 16px;
                    }
                }
            }

            

            button {
                margin: 36px 0 56px 0;
                width: 134.22px;
                height: 40.18px;
                background: #00819D;
                border: 1px solid #00819D;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 15px;
                img {
                    margin-right: 8px;
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }
}

.mobile {
    display: none;
}