.search-users {

	.search-content {
		position: relative;
		top: 12px;
		margin: 0;
		margin-left: 12px;
	}

	.group-avatar {
		width: 22px;
		height: 22px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #61C3D1;
	}

	.video-image {
		width: 38px;
		height: 38px;
		margin-right: 24px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #61C3D1;
	}

	.add-padding {
		padding-bottom: 40px;
	}
	.filter-left {
		position: relative;
	}
	.custom-select {
		margin-left: 14px;
		margin-top: 6px;
		margin-bottom: 6px;

		select {
			-webkit-appearance: menulist-button;
			color: #1CA6BA;
			border: none;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 12px;
		  }
		  
		  select:invalid {
			color: #1CA6BA;
		  }
	}
	.message {
		padding: 32px 16px;
	}
	::placeholder {
		font-size: 18px;
		color: #707070;
	}
	input {
		outline: none;
	}
}