.add-new-content {
  width: 100%;
  left: 0;
  position: relative;
  bottom: 0;
  text-align: center;
  height: 75vh;
  padding: 0 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    bottom: 100px;
  }

  .add-new-content-image {
    width: 75px;
    margin-bottom: 20px;
  }
  .add-new-content-title {
    color: #000;
    font-family: $mainFont;
  }
  p {
    font-family: $mainFont;
    color: #000;
  }
}

.learning-default {
  .add-new-content {
    height: auto !important;
  }
}