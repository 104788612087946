.btn-seeMore-container {
  text-align: right;
  padding: 0 0 1rem 0;
  font-weight: 600;

  .achievementBtn-container {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 968px) {
      padding: 0 12px;
      margin-top: 8px;
  }

    .achievement-white {
      background-color: #ffffff;
      font-weight: 600;
      width: 100%;
    }
  }
}

.btn-transcript-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;



  .page-title.achievements {
    padding: 1.5rem 2rem 0 0.7rem;
    order: 2;
  }

  .achievementBtn-container {
    order: 1;
    width: 100%;
    opacity: 0.9;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    padding-bottom: 1rem;
    padding-top: 0.7rem;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 900px) {
        padding: 16px 24px 8px;
    }

    .achievement-white {
      background-color: #ffffff;
      font-family: 'Cabin';
      font-weight: 600;
      width: 100%;
      letter-spacing: 0.01em;
      color: #00819D;
      font-size: 15px;
      height: 40px;
    }
  }
}

.btn-transcript {
  width: 100%;
  height: 40px;
  background: #00819d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Cabin';
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.01em;
  border: none;
  margin-bottom: 48px;

  @media screen and (max-width: 968px) {
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

@media screen and (min-width: 1020px) {
  .btn-seeMore-container {
    text-align: right;
    padding: 0 4rem 4rem 0;
    font-weight: 600;

    .achievementBtn-container {
      width: 200px;
      margin: 0;
      margin-left: auto;
    }
  }

  .achievement-white {
    width: 100%;
  }
  .btn-transcript-container {
    flex-wrap: nowrap;

    @media screen and (min-width: 900px) {
      justify-content: flex-end;
    }

    .page-title.achievements {
      padding: 0 2rem 0 0.7rem;
      order: 1;
    }
    .achievementBtn-container {
      order: 2;
      width: 200px;
      opacity: 0.9;
      box-shadow: none;
      padding-bottom: 0;
      padding-top: 0;
      display: flex;
      justify-content: center;

    }
  }
}
