@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

$mainFont: 'Cabin', sans-serif;
$headerFont: 'Roboto', sans-serif;
$openSans: 'Open Sans', sans-serif;
$font-size-base: 16px;
$font-size-xsmall: 8px;
$font-size-xsmall-plus: 11px;
$font-size-small: 12px;
$font-size-small-plus: 13px;
$font-size-mobile: 14px;
$font-size-medium-plus: 18px;
$font-size-large: 20px;
$font-size-xlarge: 32px;
$font-size-section-heading: 60px;
$font-size-h1: 36px;
$font-size-h1-mobile: 30px;
$font-size-h2: 30px;
$font-size-h2-mobile: 26px;
$font-size-h3: 26px;
$font-size-h3-mobile: 22px;
$font-size-h4: 22px;
$font-size-h4-mobile: 18px;
$font-size-h5: 18px;
$font-size-h5-mobile: 16px;
$font-size-h6: 16px;
$font-size-h6-mobile: 14px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
$line-height-xsmall: 1.1;
$line-height-small: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-medium: 1.6;
$line-height-large: 1.8;

//** By default, this inherits from the `<body>`.
$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-boldest: 800;
$headings-font-weight: 500;
$headings-color: inherit;
$headings-line-height: 1.1;
$text-shadow: 0 2px 4px rgba(0,
0,
0,
0.5);

// font weight
$bold: 600;
$normal: 500;

// Headings
// -------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: $headings-line-height;
}

// Label Styles
// -------------------------
label {
  margin-bottom: 5px;
}

// Lists
// -------------------------
// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

// Copied from Bootstrap's type.less
.text-center {
  text-align: center;
}