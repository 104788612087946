.primary--modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(238, 238, 238, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $mainFont;
  animation: fadeIn linear 0.1s;
  -webkit-animation: fadeIn linear 0.1s;
  -moz-animation: fadeIn linear 0.1s;
  -o-animation: fadeIn linear 0.1s;
  -ms-animation: fadeIn linear 0.1s;

  .MuiTabs-scroller {
    button {
      span {
        font-family: $mainFont !important;
        font-size: 15px !important;
        text-transform: none !important;
        letter-spacing: 1px;
      }
    }
  }

  .play--container {
    z-index: 999;
    background: $mainColor;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 6px;
    left: 6px;
    .play {
      width: 7px;
      height: 9px;
      position: relative;

    }
  }

  // .review-modals-buttons {
  //   margin: 0 auto !important;
  // }

  .MuiMenu-paper {
    height: auto !important;
  }
  .MuiList-padding {
    padding: 0 !important;

  }
  .cancel, 
  .confirm {
    width: 48%;
    border-radius: 5px;
    font-weight: 500;
    font-family: $mainFont;
    font-size: 16px;
    height: 36px;
    gap: 6px;
    margin: 0 3px;
  }

  .cancel {
    border: 1px solid $mainColor;
    color: $mainColor;
    background: none;
    font-weight: 500;
  }

  .confirm {
    background: $mainColor;
    color: #fff;
    border: none;
  }

  .MuiTabs-scroller {
    margin: 16px 0;
    padding-bottom: 16px;
  }

  .primary--modal--container {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    padding: 45px;

    h1 {
      font-size: 24px;
      color: black;
      font-weight: 500;
    }

    .modal--btnClose--container {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background: #00819d;
      position: absolute;
      right: -25px;
      top: 15px;
      border: none;
      z-index: 99999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .error {
    border: 1px solid red !important;
  }

  .upload--file--container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 5px;

    span {
      display: flex;
      width: 150px;
      padding: 0 !important;
    }
  }

  .feedback--container {
    flex-direction: column;
  }

  //   .MuiPaper-root {
  //     box-shadow: none !important;
  //     margin-bottom: 10px !important;
  //   }

  //   .modal--formUploadFile--container {
  //     .upload {
  //       top: 10px !important;
  //     }
  //   }
  // }
  .form--upload {
    .modal--form--container {
      // padding: 1rem 3.5rem 0.5rem 3.5rem;

      .MuiGrid-root {
        label {
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          color: #000000;
          position: relative;
          top: -4px;
          margin-bottom: 0 !important;
        }
      }

      .modal--feedback--container {
        .coach--body {
          margin-bottom: 0 !important;

          .MuiTabs-flexContainer {
            gap: 2rem;
          }
        }
      }

      .modal--inputTitle--container {
        padding-top: 0 !important;
        margin-top: 10px;
      }

      .modal--textAreaDes--container {
        textarea {
          height: 100% !important;
        }
      }

      .modal--formUploadFile--container {
        display: flex;
        align-items: center;

        .formUploadTitle--box {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .upload {
          position: relative;
          margin-right: 0 !important;
          width: 100%;

          .MuiTypography-colorError {
            margin-left: 12px;
          }

          .MuiButton-label {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
          }
        }
      }
    }

    .btn--save--upload {
      border-radius: 5px;
      margin-top: 16px;
      button {
        height: 40px;
        background: #00819d;
        color: white;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;

        .MuiButton-label {
          text-transform: capitalize !important;
        }

        .addplus--icon {
          margin-right: 8px;
        }
      }
    }

    .upload span.MuiButton-contained {
      background: #00819d;
      color: #fff;
      font-weight: bold;
      padding: 4px 18px;
      margin-right: 0;
      height: 40px;
      min-width: 150px;
      text-transform: capitalize;
      .paperclip {
        width: 10px;
        margin-right: 8px;
      }
    }

    .contained-button-file {
      width: 100%;
    }

    .upload--file--input {
      padding: 18.5px 14px;
      background: #fff;
      outline: none;
      border: none;
      height: 40px;
      width: 100%;
      color: #bbbbbb;
      font-family: 'Cabin';
      font-weight: 400;
      border-radius: 5px;
    }

    .upload--file--input::placeholder {
      color: #bbbbbb;
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 14px;
    }

    .save-upload {
      // position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        width: 90%;
        background: #25c522;
        font-weight: bold;
        color: #fff;
      }
    }

    .MuiFormControl-marginNormal {
      margin-top: 0 !important;
    }
  }
  
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: none !important;
  }
  
  .MuiInputLabel-formControl {
    position: relative !important;
  }
  
  .PrivateNotchedOutline-legendNotched-40 {
    max-width: 0 !important;
  }
  
  .MuiOutlinedInput-root {
    height: 40px;
  }
  
  .upload--description {
    .MuiOutlinedInput-root {
      height: 100px !important;
    }
  }
  
  .makeStyles-button-42 {
    margin-top: 0.9rem !important;
  }
  .MuiGrid-spacing-xs-4 {
    width: auto !important;
    margin: 0 !important;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 0.5rem 0 !important;
  }
  
  .makeStyles-button-42 {
    margin-right: 0 !important;
  }
}

.request--feedback--image {
  position: relative;
  width: 228px;
  height: 140px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-right: 16px;
  margin-bottom: 37px;
  background: #eee;

  video {
    width: 260px;
    height: auto;
    z-index: 10;
  }
}


@media screen and (max-width: 768px) {
  .primary--modal {
    &--container {
      position: absolute !important;
      bottom: 0;
    }
    .modal--btnClose--container {
      top: -25px !important;
      right: 16px !important;
    }

    .request--feedback--image {
      margin-bottom: 16px !important;
      height: 31vw;
      width: 52vw;

      video {
        height: auto;
        width: 55vw;
      }
    }
    
    .upload span.MuiButton-contained {
      width: 200px !important;
    }
    
    .MuiTabs-flexContainer {
      justify-content: center;
    }

    .request--feedback--content {
      flex-direction: column;
      margin-bottom: 32px;

      .request--feedback--text {
        display: none;
      }
    }
  }
}

.resources--tab::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.resources--tab {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    left: 0;
    margin-top: 8px;

    li {
      padding: 12px;
      margin: 0;
      background: $mainColor;
      margin-bottom: 12px;
      border-radius: 5px;
      
      button {
        background: none;
        border: none;
        font-size: 18px;
        font-weight: 600;
        font-family: $mainFont;
        color: #fff;
        width: 100%;
        height: 100%;
        text-align: left;
      }
    }
}

@media screen and (max-width: 400px) {
  .primary--modal {
    &--container {
      form {
        // height: 50vh;
        overflow-x: hidden;
        left: 0;
        overflow-y: auto;
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.confirm--modal {
  font-family: $mainFont;

  &--text {
    color: #000;
    font-size: 15px;
    font-weight: normal;
  }

  &--delete {
    background: $mainColor !important;
    margin-top: 16px !important;
    span {
      color: #fff !important;
      font-weight: 600;
      font-size: 15px;
      text-transform: none !important;
    }
  }
}

.members--list--modal {
  border-top: 1px solid #000;
  .members--list--modal--container {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
  }

  .members--list--modal--container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  
  .members--list--modal--container::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .members--list--users {
      display: flex;
      align-items: center;
      margin: 16px 0;

      &--name {
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 500;
          color: $mainColor;
          margin: 0 32px; 
          width: 120px;
      }

      &--joined {
          font-family: 'Cabin';
          font-style: italic;
          font-weight: 400;
          font-size: 10px;
          color: #666666;
      }

      span {
          height: 48px;
          width: 48px;
          background: $mainColor;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 24px;
          text-transform: uppercase;
          border-radius: 50%;

          img {
            width: 48px;
            height: 48px;
          }

          @media screen and (max-width: 900px) {
            overflow: hidden;
          }
    
      }
  }
}

.green-button {
  
  // button {
  //   background: #008A17 !important;
  // }
}

@media screen and (min-width: 600px) {
  .green-button {
    display: flex;
    justify-content: flex-end;

    img {
      display: none;
    }

    // button {
    //   width: 150px !important;
    //   background: #008A17 !important;
    // }
  }
}

.collections-list {
    margin: 0;
    padding: 0;
    height: 43vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-top: 24px !important;

    li {
      margin: 0;
      padding: 16px 24px 20px !important;
      list-style: none;
      display: flex;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 16px !important;
      
      .collection-image-container {
        width: 96px;
        margin-right: 16px;
      }

      .one-image-container {
        top: 7px;
      }

      .three-image-container {
        .collection-image-2 {
          top: 6px !important;
        }
      }

      span {
        position: relative;

        p {
          margin: 0;
        }

        .collection-modal-title {
          font-family: 'Cabin';
          font-weight: 500;
          font-size: 15px;
          color: #000000;
          margin-top: 2px;
        }

        .collection-modal-length {
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          margin: 6px 0 4px !important;
        }

        .collection-modal-updated {
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 14px;
          color: #BBBBBB;
        }

        .collection-image-top {
          position: absolute;
          z-index: 10;
          background-position:center center !important;
          -webkit-background-size:cover !important;
          -moz-background-size:cover !important;
          -o-background-size:cover !important;
          background-size:cover !important;
          background-repeat: no-repeat !important; 
        }

        .collection-image-0 {
          top: 12px;
          z-index: 1;
        }
        .collection-image-1 {
          z-index: 8;
          top: 3px;
        }

        .collection-image-0,
        .collection-image-2,
        .collection-image-3,
        .collection-image-4,
        .collection-image-5 {
          position: absolute;
        }

        .collection-image-2 {
          top: 4px;
          z-index: 7;
        }

        .collection-image-3 {
          top: 8px;
          z-index: 6;
        }

        .collection-image-4 {
          top: 12px;
          z-index: 5;
        }

        .collection-image-5 {
          top: 15px;
          z-index: 4;
        }
      }

      .resource-image-container {
        overflow: hidden;
        width: 94px;
        height: 65px;
        border: 1px solid #BBBBBB;
        border-radius: 5px;
        background: white;

        img {
          width: 120px;
          height: auto;
        }
      }
    }
  }

  .collection-btns {
    display: flex;
    justify-content: space-between;

    // .collection-btn,
    // .collection-done-btn {
    //   width: 49% !important;
    //   border-radius: 5px;
    // }
    .collection-done-btn {
      width: 45% !important;
      border-radius: 5px;
      margin-right: 6px;
    }
    
    .collection-btn {
      width: 55% !important;
      border-radius: 5px;
    }

    .collection-btn {
      background: transparent !important;
      color: $mainColor !important;
      border: 1px solid $mainColor;
    }

    @media screen and (min-width: 968px) {
      .collection-done-btn {
        width: 150px !important;
      }

      .collection-btn {
        width: 200px;
        background: transparent !important;
        color: $mainColor !important;
        border: 1px solid $mainColor;
        border-radius: 5px;
      }
    }
  }

  .primary-modal-mobile-x,
  .primary-modal-close-text {
    display: none;
  }