.library {
    overflow-x: hidden;

    .coach--tabs {
        @media screen and (max-width: 900px) {
            margin-left: 0;

            .MuiTabs-flexContainer {
                justify-content: center;
            }
        }
      }

    &--share-modal {
        .primary--modal--container {
            height: 50vh !important;
            display: block;

            .library--share-modal-container {
                overflow: unset;
                overflow-y: hidden;
                overflow-y: auto;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none; 
                margin-bottom: 0;
                height: 87%; 
                padding-bottom: 32px;
            }

            .library--share-modal-container::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
                background-color: transparent;
                }
                
                .library--share-modal-container::-webkit-scrollbar {
                    width: 0px;
                    background-color: transparent;
                }

            @media screen and (max-width: 768px) {
                height: 60vh !important;
            }
            padding-bottom: 0;
            display: flex;
            flex-direction: column;

            .img-left {
                margin-top: 3px;
                margin-right: 16px;
            }

            .shared-user-list {
                padding: 8px 0px;
            }

            .invited-users li {
                padding: 2px 8px 2px 0;
                border: 1px solid #707070;
                margin-left: 4px;
                margin-bottom: 4px;
            }
            .rah-input-wrapper {
                width: auto;
                height: auto;
            }

            .MuiOutlinedInput-root {
                height: unset !important;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            .MuiInputBase-inputMultiline {
                height: unset;
            }
            input {
                margin-top: 4px;
                margin-left: 5px !important;
            }

           .user-list {
             overflow: unset;
            }

            .shared--buttons {
                display: flex;
                margin-top: 32px;

                .back-button {
                    background: transparent;
                    border: 1px solid $mainColor;
                    border-radius: 5px;
                    margin-right: 8px;

                    svg {
                        fill: $mainColor;
                    }
                }
            }

            .dropdown-el {
                margin-left: 0;
            }
        }
    }

    .shared--resource {
        .invite-groups {
            position: relative;
        }
    }

    @media screen and (max-width: 900px) {
        .library--opacity {
            opacity: 0;
        }
    }

    .react-pdf__Document {
        canvas {
            // position: relative;
            // left: 20px;
            width: 100% !important;
            height: auto !important;
        }
    }

    &--modal {
        z-index: 9999 !important;

        .react-pdf__Document {
            canvas {
                width: auto !important;
                height: 450px !important;
    
                @media screen and (max-width: 900px) {
                    height: 305px !important;
                }
            }
        }

        &--image--icon {
            height: 200px !important;
            width: auto;

            @media screen and (max-width: 900px) {
                height: 100px !important;
            }
        }

        .audio--icon {
            position: absolute;
        }

        &--image--container {
            height: auto;
            overflow: hidden;
            // min-height: 450px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            background-position:center top;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-size:cover;
            background-repeat: no-repeat;    

            @media screen and (min-width: 900px) {
                height: 400px;
            }

            img {
                width: auto;
                height: 450px;
                display: flex;

                @media screen and (max-width: 900px) {
                        height: 200px;
                }
            }
        }

        .secondary--modal {
            display: flex; 
            flex-direction: column;
            padding-bottom: 0 !important;
            width: 100%;

            h1 {
                margin-left: 0;
                margin: 12px 0;
            }

            video, 
            .video-react {
                height: 400px !important;

                @media screen and (max-width: 900px) {
                    height: 200px !important;
                }
            }

            .feedback--audio {
                video, 
                .video-react {
                    background: #D9F1F6;
                }
            }

            .feedback--image {
                display: flex !important;  
                background: #000;

                video, 
                .video-react {
                    // background: transparent;
                }
            }

            .feedback--video {
                margin-bottom: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .video-react .video-react-slider {
                    background: rgba(115, 133, 159, 0) !important;
                }

                .video-react-load-progress {
                    height: 6px !important;
                    margin-top: 1px;
                    width: calc(100% - 2px) !important;
                    margin-left: 1px;
                }

                .video-react-play-progress {
                    margin-top: 1px;
                    max-width: calc(100% - 2px) !important;
                    margin-left: 1px;
                    height: 6px !important;
                }
                
                .video-react .video-react-load-progress div {
                    height: 6px !important;
                }

                .video-react-load-progress,
                .video-react .video-react-progress-holder {
                    height: 10px;
                }

                .video-react .video-react-progress-holder {
                    border-radius: 2px;
                }

                .video-react .video-react-progress-control {
                    padding: 0 8px;
                    // border-radius: 5px;
                }

                @media screen and (min-width: 900px) {
                    .video-react-button {
                        bottom: 33px !important;
                    }
                }

            }

            .library--details {
                &--share {
                    display: flex;
                    align-items: center;
                    font-family: 'Cabin';
                    font-weight: 500;
                    font-size: 14px;
                    margin-top: 16px;

                    img {
                        margin-right: 6px;
                    }

                    &-ul {
                        margin: 12px 0 24px;
                    }

                    &-list {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background: #EEEEEE;
                        border-radius: 5px;
                        width: 100%;
                        height: 40px;
                        padding: 12px 16px;
                        margin-bottom: 6px;

                        span {
                            display: flex;
                            align-items: center;
                            font-family: 'Cabin';
                            font-weight: 400;
                            font-size: 14px;
                            color: #00819D;

                            img {
                                margin-right: 6px;
                            }
                        }
                    }

                    &-button {
                        margin-top: 6px;
                        width: 100%;
                        height: 40px;
                        background: #00819D;
                        border-radius: 5px;
                        color: #fff;
                        border: none;
                        font-family: 'Cabin';
                        font-weight: 500;
                        font-size: 14px;

                        img {
                            margin-right: 6px;
                        }
                    }
                }

                &--bottom {
                    margin-top: 16px;
                    font-family: 'Cabin';
                    font-weight: 500;
                    font-size: 14px;
                    span {
                        align-items: center;
                        display: flex;
                    }
                    img {
                        margin-right: 6px;
                    }

                    &-chips {
                        display: flex;
                        margin-top: 12px;

                        span {
                            width: 109px;
                            height: 30px;
                            background: #EEEEEE;
                            border-radius: 55px;
                            margin-right: 6px;
                            font-family: 'Cabin';
                            font-weight: 400;
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #666666;
                        }
                    }
                }
            }

            .library--details--container {
                overflow-x: hidden;
                overflow-y: auto;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none; 
                padding-bottom: 60px;
            }

            .library--details--container::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
                background-color: transparent;
              }
              
              .library--details--container::-webkit-scrollbar {
                width: 0px;
                background-color: transparent;
              }

            .library--details--top {
                display: flex;
                justify-content: space-between;
                
                .video {
                    margin: 0;
                    font-family: 'Cabin';
                    font-weight: 400;
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }

                &-left {
                    display: flex;
                    align-items: center;
                    img {
                        height: 10px;
                        margin-right: 6px;
                    }
                }

                &-right {
                    display: flex;
                    color: $mainColor;
                    font-weight: 600;
                    font-size: 14px;
                    font-family: 'Cabin';

                    &-desktop {
                        @media screen and (max-width: 900px) {
                            display: none;
                        }
                    }

                    &-mobile {
                        display: none;

                        @media screen and (max-width: 900px) {
                            display: flex;
                            margin-bottom: 16px;
                            
                            .handle-click {
                                padding-right: 16px;
                                cursor: pointer;
                            }
                            img {   
                                margin-left: 0 !important;
                            }
                        }
                    }
                    
                    .handle-click {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img {
                            height: 14px;
                            margin-right: 6px;
                            margin-left: 16px;
                        } 
                    }
                }
            }
        }
    }
    &--resources {
        max-width: 1236px;
        margin: 0 auto;
        padding: 16px 0 0;

        @media screen and (max-width: 968px) {
            padding: 16px 0 40px;
        }
    }

    .library--sub--nav {
        position: absolute;
        right: 16px;
        
        .resources--btn {
            font-family: $mainFont;
            background: #eeeeee;
            border-radius: 110px;
            color: #00819d !important;
            font-weight: 600;
            border: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 12px;
            font-size: 15px;
            letter-spacing: 0.02em;
        }

        @media screen and (max-width: 600px) {
            .resources--btn {
                display: none;
            }

            .resources--add--btn {
                width: 100%;
            }
        }
    }

    .library--pinned {
        overflow: hidden;
        background: #EEEEEE;
        padding: 24px 24px 32px;

        &--section--title {
            margin: 0;
            padding: 0;
            font-family: $mainFont;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.01em;
            margin-bottom: 12px;
            color: #000;
        }

        &--card--container {
            margin: 0 auto;
            max-width: 1236px;
        }

        &--card--list {
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            width: 100%;
        }
    }

    .library--list {
        width: 100%;

        @media screen and (max-width: 768px) {
            padding: 0 24px 28px;
        }
    }

    .library--grid {
        margin: 24px 0;
        max-width: 1236px;
        
        @media screen and (max-width: 1240px) {
            padding: 0 24px;
        }
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -24px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 24px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    // background: grey;
    margin-bottom: 24px;
  }

// .show--modal {
//     background: transparent !important;
// }

@media screen and (max-width: 968px) {
    .library--sub--nav {
        position: fixed !important;
        left: 0;
        padding: 8px 24px;
        width: 100%;
        bottom: 0;
        z-index: 999;
        background: #fff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

        button {
            width: 100%;
        }
    }      
}