.grid--card {
    // width: 25%;
    // height: auto;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    margin-bottom: 24px;
    position: relative;
    cursor: pointer;

    &--image--container {
        overflow: hidden;
        display: flex; 
        justify-content: center;
        align-items: center;
        background: #D9F1F6;

        video {
            height: 200% !important;
        }

        .no--image {
            background: #D9F1F6;
        }
    }

    &--pinned--container {
        position: absolute; 
        top: 8px;
        right: 8px;
        border-radius: 50%;
        background: #eee;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    &--content {
        padding: 12px 12px 4px;
    }

    &--pinned--container--pinned {
        background: #DF187D;
    }

    p {
        margin: 0;
        margin-bottom: 4px;
        font-family: 'Cabin';
        font-size: 15px;
        font-size: 10px;
        color: #666666;
    }

    &--type {
        text-transform: uppercase;
        letter-spacing: 0.6px;
    }

    &--title {
        margin: 0;
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 15px !important;
        color: #00819D !important;
    }
}

.react-pdf__Document {
    height: 160px;
    // canvas {
    //     height: 160px;
    //     width: 50%;
    // }
}