.coach--view {
  .coach--tabs--container {
    // max-width: 1300px !important;
    margin: 0 auto;
    font-family: $mainFont;
    margin: 0 16px;

    // .coach--tabs {
    //   @media screen and (max-width: 900px) {
    //     display: none;
    //   }
    // }
  }

  .coach--user {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .username {
    color: #284374;
    font-weight: 400;
    font-size: 15px;
    font-family: $mainFont;
  }

  .image {
    margin-right: 16px;
    height: 40px !important;
    width: 40px !important;

    p {
      font-weight: 700;
      font-family: $mainFont;
      font-size: 16px;
    }
  }

  .coach--view--text {
    color: #00819d;
    font-weight: 600;
    font-family: $mainFont;
    max-width: 300px;
  }

  .coach--bar {
    margin-bottom: 0 !important;
  }
  
  .coach--view--text--date {
    color: #666666 !important;
    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .coach--status--container {
    .coach--status--button--container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    span {
      display: flex;
      align-items: center;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .coach--status {
      margin-top: 0 !important;
      height: 45px !important;
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #E2E6EC;
      border: none;
      flex-wrap: wrap;
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 14px;
    }
  }

  tbody td {
    padding: 12px 0 !important;
    vertical-align: middle;
  }
}

.coach--body {
  // margin-bottom: 32px;

  .coach--bar {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
    position: relative !important;
  }

  // .coach--bar {
  //   height: 0;
  // }

  .coach--tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: -12px;

    // @media screen and (max-width: 600px) {
    //   // display: none;
    // }

    @media screen and (max-width: 900px) {
      // display: none;
      .MuiTabs-flexContainer {
        margin-left: 16px;
      }
    }

    .MuiTabs-root {
      display: flex;
      align-items: center;
    }

    .MuiTab-root {
      color: #000000 !important;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0.01em;
      text-transform: capitalize !important;
    }
  }

  .coach--tabs--container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 32px;

    @media screen and (max-width: 600px) {
      padding: 0;
    }
  }

  .coach--tab {
    padding: 4px 14px !important;
    margin-right: 16px;
  }

  .coach--content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 32px;

    @media screen and (max-width: 1024px) {
      padding: 16px 24px 16px 0;
    }
  }

  .coach--title {
    font-family: $mainFont;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 8px;
  }

  .coach--description {
    font-family: $mainFont;
    font-size: 15px;
    margin: 0;
    padding-bottom: 16px;
  }

  .coach--play {
    background: #00819d;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 8px;
    bottom: 8px;

    img {
      display: flex;
      height: 11px;
      width: 9px;
    }
  }

  .coach--buttons {
    color: #00819d;
    font-size: 14px;
    font-family: $mainFont;
    font-weight: 600;
    cursor: pointer;

    @media screen and (min-width: 900px) {
      display: flex;
      align-items: center;
    }

    &--flex {
      display: flex;

      .coach--info {
        @media screen and (min-width: 900px) {
          display: none !important; 
        }
      }
  }

    @media screen and (max-width: 1024px) {
      // margin-top: 16px;
    }

    .handle-click {
      cursor: pointer;
    }

    .coach--info,
    .handle-click {
      display: flex;
      align-items: center;
      margin-right: 16px;

      img {
        height: 14px;
        width: auto;
        margin-right: 6px;
      }
    }

    .coach--view {
      border: 1px solid #00819d;
      color: #00819d;
      border-radius: 5px;
      width: 150px;
      height: 36px;
      font-size: 15px;
      font-weight: 600;
      font-family: $mainFont;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }

  .coach--tab.Mui-selected {
    background: #eeeeee;
    border-radius: 110px;
    color: #00819d !important;
    font-weight: 600;
  }

  .MuiTabs-root {
    border-bottom: none;
  }

  .coach--resource {
    display: flex;
    
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .react--table th {
    border-left: none;
    border-right: none;
  }

  tbody {
    tr {
      vertical-align: top;
      border-bottom: 1px solid #cccccc;
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      padding: 24px 0 40px;
    }
  }

  .coach--image {
    position: relative;
    min-width: 350px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    // img {
    //   width: 100%;
    // }

    video {
      display: flex;
    }
  }

  .coach--text {
    color: #666666;
    font-size: 12px;
    font-family: $mainFont;
    margin-top: 36px;
  }

  .clock {
    background: #00819d;
    padding: 8px;
  }

  .dot {
    background: #edc902;
  }

  .check {
    background: #008a17;
    img {
      width: 14px;
      height: 10px;
    }
  }

  .coach--status--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin-right: 8px;

    img {
      display: flex;
    }
  }

  .coach--status {
    background: #eeeeee;
    border-radius: 5px;
    height: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $mainFont;
    color: #000;
    font-size: 15px;
    margin-top: 16px;
  }

  .MuiTabs-indicator {
    background-color: transparent !important;
  }

  .request--feedback {
    .select {
      .MuiInputBase-root {
        font-family: $mainFont !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
      .MuiSelect-icon {
        color: #000 !important;
      }
    }
    
    .MuiOutlinedInput-input {
      padding: 0 24px;
      color: #000;
    }
    .select,
    .MuiSelect-select:focus,
    .Mui-focused {
      background: none !important;
    }
    .image {
      width: 25px !important;
      height: 25px !important;
      margin-right: 8px !important;
    }

    h3 {
      margin: 0;
    }
    
    .request--feedback--content {
      display: flex;
    }

    .select {
      max-width: 401px;
      margin: 0 auto;
    }

    a {
      margin: 0 auto;
      width: fit-content;
      margin-top: 37px;
      color: #fff;
      font-size: 15px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      span {
        background: #00819D;
        height: 40px;
        width: 401px;
        font-weight: 500;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;


        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}

// .coach--select--option {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
//   height: auto !important;
// }

.coach--text--container {
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.coach--select--option {
  font-family: $mainFont !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  // .coach--tabs {
  //   display: none !important;
  // }
  .coach--top--btns {
    justify-content: center;
    width: 100%;
    padding: 10px 24px;

    button {
      // width: auto !important;
      min-width: 160px;
      letter-spacing: 0.2px;
    }
  }
}

@media screen and (min-width: 768px) {
  .coach--body {
    .coach--bar {
      width: 100%;
      height: 62px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
  }
}

.react--table {
  margin-top: 16px !important;
}

@media screen and (max-width: 1300px) {
  .react--table {
     width: auto;
     margin-top: 0 !important;
  }
}

