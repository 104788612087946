.card-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    top: 0;
    background-position: center !important;
    /* Center the image */
    background-repeat: no-repeat !important;
    /* Do not repeat the image */
	background-size: cover !important;

	ul {
		margin: 0;
		padding: 0;

		li {
		  list-style: none;
		}
	  }
	  .MuiBox-root {
		padding: 0;
	  }
	  .MuiTabs-root {
		border-bottom: 1px solid #707070;
		.Mui-selected {
		  color: #616161;
		}
	  }
	  // .PrivateTabIndicator-root,
	  // .MuiTabs-indicator {
		// background-color: #1CA6BA !important;
		// padding: 0 4px;
		// height: 3px;
	  // }

    .card-modal-wrapper {
      overflow: auto;
      height: 100%;
      padding-bottom: 80px;
    }
    .card-modal-content {
      margin-top: 300px;
      width: 100%;
      left: 0;
      .title {
        text-align: center;
        padding: 0 12px;
        h2 {
          color: #D3006B;
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 8px;
          font-family: $openSans !important;
          font-weight: 800;
        }
      }
      .subtitle-container {
        position: relative;
        justify-content: center;
        margin-bottom: 16px;

        .subtitle {
          text-align: center;
          font-size: 14px;
          line-height: 2.2;
          font-family: $openSans !important;
          font-weight: 700;
          overflow: hidden;
          // p:hover {
          //   -webkit-line-clamp: 6;
          // }
          p {
            display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                margin: 0 0 2px;
                padding: 0 8px;
                position: relative;

            span {
              background: #fff;
                  border: 3px solid white;
                  box-shadow: 2px 2px #17A6BA;
                  -webkit-box-decoration-break: clone;
                  box-decoration-break: clone;
                  border-radius: 5px;
                  padding: 0 8px;
            }
            .two-lines:after {
                  content: "...";
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 15px;
                  height: 5px;
                }
          }
        }
      }
    }
    .card-modal-container {
      width: 130px;
      margin: 0 auto;
      font-weight: bold;
      font-family: $openSans;
      margin-bottom: 16px;
      .progress-container,
      .modules-container {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
        color: #737373;
        margin-top: 12px;
        p {
          margin: 0;
          margin-left: 12px;
          font-size: 13px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
      }
      div {
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        padding: 8px 24px;
        font-size: 16px;
        color: #fff;
      }
      .play {
        background: #FF7A00;
        border: 1px solid #FFE3C9;
      }

      .continue {
        background: transparent;
        border: none;
      }

      .add {
        background: #fff;
        color: #616161;
        margin-left: 24px;
      }
      .play,
      .continue,
      .add {
        .play-icon {
          width: 10px;
          margin-right: 8px;
        }
        .heart-icon {
          margin-right: 8px;
          width: 16px;
        }
      }
    }
    .learning-tabs {
      margin-top: 32px;
      padding: 0 8px;
      color: #616161;
      background: rgba(255, 255, 255, 0.6);

      .MuiTabs-scroller {
        padding: 0 64px;
      }
      button {
        width: 50%;
      }
      p {
        padding: 4px;
      }
      .outline {
        margin-top: 16px;
        width: 100%;
        .anything {
		  padding: 24px 0;
		  
		  a {
			text-decoration: none;
			color: $black;
			width: 100%;
		  }
          
          img {
            margin-right: 8px;
          }

          .outline-content {
            width: 100%;
            span {
              display: flex;
              flex-wrap: wrap;
            }
            p {
              font-size: 14px;
              font-weight: 600;
              padding: 0;
              margin: 0 0 8px;
              font-family: $openSans;
              white-space: normal;
              margin-right: 0.2em;
              word-break: break-all
            }
          }
        }
      }
    }
}