.landing {
  height: 100vh;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../images/landing.png') no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media screen and (min-width: 720px) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../images/landing-desktop.jpg') no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  @media screen and (min-width: 900px) {
    background: none;
  }
  .landing-brand {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top-logo {
      width: 70px;
      margin-bottom: 16px;
    }
  }
  .landing-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 40px 16px;
    a {
      padding: 0;
      margin: 0;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      background: white;
      font-size: 18px;
      font-weight: bold;
      padding: 8px 16px;
      text-align: center;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 24px;
      box-shadow: 1px 1px #17A6BA;
    }
  }
}

@media screen and (min-width: 480px) {
  .landing {
    margin: 0 auto;
    .landing-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      bottom: 64px;
      a {
        width: 300px;
        margin: 0 8px;
      }
    }
  }
}

.landing-desktop {
  display: none;
}

@media screen and (min-width: 900px) {
  .landing {
    display: none;
  }

  .landing-desktop {
    display: block;

    video {
      position: fixed;
      left: 0;
      top: 0;
      min-width: 100%;
      min-height: 100%;
      opacity: 0.5;
    }
  }
}