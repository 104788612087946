.navigation--community {
  @media screen and (min-width: 900px) {
    z-index: 999999 !important;
  }
}

.navigation {
  position: static;
  width: 100%;
  z-index: 99;

  .show-menu ul .active,
  .show-menu ul .active li  {
    font-weight: 600;
  }
  

  .avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      background: #00677E;
    }
    p {
      margin: 0;
      padding: 0;
      line-height: 0;
      font-family: $mainFont;
    }
  }

  .mobile-navigation {
    display: block;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 12px 20px;
  }
  .desktop-navigation {
    display: none;
  }

  a {
    display: flex;
  }

  // .MuiAppBar-positionFixed,
  // header.MuiAppBar-positionStatic {
  //   height: 110px;
  // }
}

.show-dot {
  .nav-dot {
    display: block !important;
  }
}

.hide-dot {
  .nav-dot {
    display: none !important;
  }
}
.nav-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #FF7A00;
  border-radius: 50%;
  left: 8px;
  top: 8px;
}

.MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly {
  opacity: 1 !important;
}

.MuiBottomNavigation-root {
  box-shadow: 0px -1px 4px #00000029;
  .bottom-nav {
    padding-top: 6px !important;
    span {
      margin-top: 2px;
      font-size: 10px;
    }
  }
  .bottom-nav-icon {
    height: 23px;
  }
  .home-page.Mui-selected:after {
    position: absolute;
    content: ".";
    border-top: 3px solid #1CA6BA;
    width: 70%;
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .home-page.Mui-selected {
    .Mui-selected {
      color: #1CA6BA;
    }
  }
  .dashboard-page.Mui-selected {
    .Mui-selected {
      color: #DF187D;
    }
  }
  .dashboard-page.Mui-selected:after {
    position: absolute;
    content: ".";
    border-top: 3px solid #DF187D;
    width: 70%;
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .coaching-page.Mui-selected {
    .Mui-selected {
      color: #2950C5;
    }
  }
  .coaching-page.Mui-selected:after {
    position: absolute;
    content: ".";
    border-top: 3px solid #2950C5;
    width: 70%;
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .community-page {
    img {
      width: 34px;
    }
  }
  .community-page.Mui-selected:after {
    position: absolute;
    content: ".";
    border-top: 3px solid #25C522;
    width: 70%;
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .community-page.Mui-selected {
    .Mui-selected {
      color: #25C522;
    }
  }
  .resources-page.Mui-selected:after {
    position: absolute;
    content: ".";
    border-top: 3px solid #FD7A00;
    width: 70%;
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .resources-page.Mui-selected {
    .Mui-selected {
      color: #FD7A00;
    }
  }
}

.MuiToolbar-root {
  .avatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    box-shadow: 0px 9px 15px #8690a538;
    margin: 0;
  }
  .avatar-initials {
    // background: #1BB9EB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid #fff;

    p {
      color: #fff;
      // font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Roboto";
      margin: 0;
    }
  }
  .active {
    // background: #fff;
    border-radius: 6px;
    color: #144CC7;
    // img {
    //   filter: invert(0.3);
    // }
  }
  ul {
    a {
      // margin: 0 8px;
      text-decoration: none;
    }
  }
}

.bottom-navigation {
  z-index: 999;
}

.MuiBottomNavigationAction-root {
  min-width: 40px !important;
}

.navigation {
  .search-line {
    width: 24px;
    color: white;
  }
  .MuiToolbar-gutters {
    padding: 0;
  }
  .MuiPaper-root {
    z-index: 9999;
    padding: 0px;
  }
  .my-videos-nav {
    ul a {
      margin-left: 16px !important;
    }
  }
  .tool-bar {
    background: #00819D;
    z-index: 10;
  }
  .sub-nav-open-menu {
    top: 424px !important;
  }
  .sub-nav {
    position: absolute;
    top: 60px;
    font-weight: 600;
    z-index: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.90);
    display: flex;
    align-items: center; 
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    .sub-link {
      display: flex;
      align-items: center;
    }
    ul {
      display: flex;
      list-style: none;
      display: flex;
      margin: 0 8px;
      padding: 0;
      justify-content: center;
      .sub-link {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
      a {
        margin: 0 16px;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        text-transform: uppercase;
        font-size: 12px;
        padding: 16px 16px 12px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        border-bottom: 4px solid transparent;
        display: flex;
        justify-content: center;
        min-width: 124px;
        li {
          letter-spacing: 1px;
        }
      }
      .active {
        color: #00819D;
        font-weight: 600;
        border-bottom: 4px solid #00819D;
      }
    }
  }
}

.list-item-icon {
  padding: 8px 0;
  
  img {
    width: 40px;
  }
}

.browse-filter {
  .filter-container {
    width: 65vw;
    background: #00677E;
    border-radius: 5px;
    padding: 0 8px;
    .filter-left {
      display: flex;
      align-items: center;
      .input-field {
        background-color: #00677E;
        border: none;
        outline: none;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0;
        color: #fff !important;
        font-family: $mainFont !important;
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      input,
      input::placeholder {
        color: #fff !important;
        font-size: 14px;
        // line-height: 50px;
        // letter-spacing: 1%;
      }
    }
  }
}

.hamburger .line{
  width: 20px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}

.show-menu {
  position: relative;
  background-color: #00819D;
  height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-family: $mainFont;
      font-size: 15px;
      line-height: 0;
      letter-spacing: 1px;
      margin: 0;
      padding: 0;
      padding-top: 1px;
    }

    hr {
      opacity: 0.5;
    }

    .icon {
      width: 26px;
      img {
        display: flex;
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
      margin: 20px 0;
    }
  }
}

.menu-opened {
  padding: 0 20px 20px;
  min-height: 100%;
  height: auto;
}

// desktop
@media screen and (min-width: 1024px) {
  .navigation {
    .mobile-navigation {
      display: none;
    }
    .desktop-navigation {
      display: block;
      list-style: none;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0;
      justify-content: space-between;

      .logo {
        padding: 0 12px 0 32px;
        z-index: 9999;
      }

      .icon {
        height: 14px;
        width: auto;
        display: flex;
        margin-right: 8px;
      }

      a {
        font-size: 15px;
        font-weight: 400;
        font-family: $mainFont;
      }

      .middle-nav a {
        text-decoration: none;
        color: #fff;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 20px;
      }
      
      .active {
        background: #00677E;
        height: 100%;
        height: 64px;
        border-radius: 0;
        font-weight: 600;
      }
  
      li {
        margin: 0;
        padding: 0;
        line-height: 0;
      }

      .avatar-wrapper {
        display: flex;
        align-items: center;
        font-family: $mainFont;
 
        img {
          display: flex;
        }
      }

      // img {
      //   width: auto;
      //   height: 36px;
      // }

      .right-nav {
        padding-right: 32px;
        a {
          display: flex;
          text-decoration: none;
          color: #fff;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0 4px 0 20px;
          font-family: $mainFont;
          p {
            font-size: 15px;
            font-weight: 400;
          }

          .menu--user--name {
            @media screen and (max-width: 1100px) {
              display: none;
            }
          }
        }
        .icon {
          margin-right: 0;
        
            img {
              height: 18px;
              width: auto;
            }
        }
        // a {
        //   padding: 0 0 0 10px;
        // }
        // .support {
        //   padding: 0;
        // }
      }

      span {
        display: flex;
        align-items: center;
      }
    }
    .filter-container {
      width: 180px;

      @media screen and (max-width: 1100px) {
        width: 160px;

      }
    }

    .sub-nav {
      top: 64px;
      ul {
        a {
          margin: 0 20px;
          display: flex;
          justify-content: center;
          li {
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}