@mixin fontFamilyCabin {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
}

@mixin displayFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-modal {
  .primary--modal--container {
    max-height: 620px;
    max-width: 770px;
  }
}

@media screen and (max-width: 768px) {
.session-modal {
  height: 100%;
  z-index: 99999;
  top: 0;

  .primary--modal--container {
    top: 0;
    max-height: 100%;
  }

  .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  .session--bar {
    box-shadow: none !important;
    border-bottom: 1px solid #CCCCCC;
    width: 100%;
    margin-top: 24px;
  }

  .session {
    height: 100%;
    padding: 24px;

    .modal--btnClose--container {
      position: relative;
      width: 100%;
      border-radius: 5px;
      background: #eee;
      height: 40px;
      color: $mainColor;
      top: 0 !important;
      right: 0 !important;
    }
    
    @media screen and (max-width: 768px) {
      .btnClose--icon {
        display: none;
      }

      .close-text,
      .mobile-x {
        display: block;
      }

      .mobile-x {
        margin-right: 8px;
      }

      .close-text {
        font-family: 'Cabin';
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.01em;
      }
    }
  }
}

}

.primary--modal__session-dates {
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
  
  &__box-first__date {
    @include displayFlexCenter;
    justify-content: flex-start !important;
    &__inner-first-box {
      background: linear-gradient(270deg, #00819d 0%, #00677e 100%);
      border-radius: 5px;
      width: 60px;
      height: 60px;
      @include displayFlexCenter;
      flex-direction: column;

      p {
        margin: 0;
      }

      &__date-day {
        @include fontFamilyCabin;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.9375px;
        color: #ffffff;
      }

      &__date-month {
        @include fontFamilyCabin;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 2px;

        color: #ffffff;
      }
    }

    &__inner-second-box {
      margin-left: 1rem;

      &__date-week-day,
      &__date-full-date {
        @include fontFamilyCabin;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.5px;
        color: #000000;
        margin: 0;
      }

      &__date-week-day {
        margin-bottom: 0.2rem;
      }
    }
  }

  &__session-notes {
    @include fontFamilyCabin;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 2.5rem;
  }

  &__session-title {
    @include fontFamilyCabin;
    font-weight: 500;
    font-size: 32px;
    line-height: 52px;
    letter-spacing: -0.01em;
    color: #000000;
    margin: 0;

    @media screen and (max-width: 768px) {
      line-height: 1;
    }
  }
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorTransparent.coach--bar.session--bar.MuiPaper-elevation4 {
  box-shadow: none !important;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 36px;
}