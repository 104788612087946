.community {
  overflow: hidden;

  .hide {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

    .coach--tabs--container {

      .btn-blue-container {
        @media screen and (max-width: 900px) {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          width: 100%;
          box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
          display: flex;
          justify-content: center;
          padding: 10px 16px;
          z-index: 99;
          background-color: white;
        }
      }

      @media screen and (max-width: 900px) {
        .coach--tabs {
          margin-left: 0 !important;
          height: 60px !important;
        }
    
        header {
          display: block !important;
          height: 60px !important;
        }
    
        .MuiTabs-flexContainer {
          justify-content: space-between;
          align-items: center;
          padding: 0 8px;
        }
      }
    }

    .library--filter--search {
      max-width: 300px;
      width: 100%;
    }

    @media screen and (min-width: 900px) {
      .secondary--modal--overlay .secondary--modal .close-card {
        top: 100px;
      }
    }

  .community-content {
    width: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    padding: 0 32px;
    .community-logo {
      width: 80px;
      margin-bottom: 50px;
    }
    .community-title {
      color: $gray;
    }
  }
}

@media screen and (max-width: 768px) {
  .community .coach--tabs--container,
  .community--tabs--container {
    padding: 0 24px !important;

    .btn-blue {
      width: 100% !important;
    }
  }
}

.groups {
  .invited {
    display: flex;
    align-items: center;

    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #FF7A00;
      margin-right: 4px;
    }
  }
  .list-desktop {
    width: 100%;
    li {
      width: 100%;
    }
    .groups-container {
      position: relative;
    }
    .groups-edit-buttons {
      display: flex;
      align-items: center;
      top: 0;
      right: 16px;
      height: 100%;
      position: absolute;
      pointer-events: auto;
      button {
        background: transparent;
        border: none;
        img {
          margin: 0 8px;
        }
      }
    }
  }
}
// .invited-users:first-child li {
//     margin-left: 22px !important;
// }

.user-list {
  list-style: none;
  padding: 0;
  overflow: scroll;
  li {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid rgb(226, 226, 226);
    .avatar {
      width: 38px;
      height: 38px;
      margin-right: 24px;
      border-radius: 50%;
    }
    .group-avatar {
      .group {
        margin-right: 24px;
        background: #61C3D1;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
    p {
      font-weight: bold;
      font-size: 16px;
      font-family: 'Open Sans'
    }
  }
}

.invited-users {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 22px;
  
  li {
    border: 1px solid #707070;
    color: #707070;
    border-radius: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    width: max-content;
    padding: 2px 8px 2px 2px;
    margin: 0 8px 8px 0;
    height: 25px; 
    .avatar {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }

    p {
      display: flex;
      font-size: 14px;
    }
  }
}

.filter-left {
  display: flex;
  .invite-user-image {
    padding: 0 16px;

    .user-container {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .img-left {
    height: 100%;
    margin-left: 16px;
    
    img {
      cursor: pointer;
    }

    @media screen and (min-width: 600px) {
      margin-left: 24px;
    }
  }
}

.community--groups-full {
  background: #fff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.community--groups {
  background: #EEEEEE;
  // padding-bottom: 32px;
  padding: 0 24px 32px;

  &-suggested {
    padding: 64px 24px;
    max-width: 1290px;
    margin: 0 auto;

    @media screen and (max-width: 900px) {
      margin-bottom: 80px;
    }

    &-container {
      
      @media screen and (min-width: 900px) {
        margin-top: 24px;
      }
    }

    &--see-more {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  
    a {
      text-decoration: none;
      color: $mainColor;
  
      button {
        background: none;
         border: 1px solid #00819D;
         border-radius: 5px;
         width: 200px;
         height: 40px;
         color: $mainColor;
         font-family: 'Cabin';
         font-weight: 600;
         font-size: 15px;
      }
    }

    @media screen and (max-width: 900px) {
      padding: 24px;
    }

    &-title {
      margin: 0;
      font-family: 'Cabin';
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 16px;
      color: #000000;

      @media screen and (max-width: 900px) {
        font-size: 15px;

      }
  
    }

    &--text {
      font-size: 16px;

      @media screen and (max-width: 900px) {
        margin: 0;
      }
    }
  }

  &--see-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;


    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  a {
    text-decoration: none;
    color: $mainColor;

    button {
      background: none;
       border: 1px solid #00819D;
       border-radius: 5px;
       width: 200px;
       height: 40px;
       color: $mainColor;
       font-family: 'Cabin';
       font-weight: 600;
       font-size: 15px;
    }
  }

  &--container {
      max-width: 1236px;
      margin: 0 auto;
      padding: 24px 0;

      @media screen and (max-width: 900px) {
        padding: 16px 0;
      }
  }

  &--title {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    margin: 0;

    @media screen and (max-width: 900px) {
      font-size: 15px;
    }
  }

  &--cards {
    max-width: 1236px;
    margin: 0 auto;
    // display: flex;
    // flex-wrap: wrap;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 24px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column .group--card { /* change div to reference your elements you put in <Masonry> */
    background: white;
    margin-bottom: 24px;
  }

}

.community--tabs--panel {
  // max-width: 1300px;
  // margin: 0 auto;
  // padding: 24px;
}

.community--messages { 
  .community--messages-all {
    background: #EEEEEE;
    padding: 24px 16px;
    min-height: calc(100vh - 120px);
    
    @media screen and (min-width: 900px) {
      width: 700px;
      padding: 32px 24px;
    }

    .community--messages-all-list {
      li {
        cursor: pointer;
      }
    }

    .messages-title {
      color: #000;
      font-family: 'Cabin';
      font-weight: 500;
      font-size: 15px;
      margin: 0;

      @media screen and (min-width: 900px) {
        font-size: 24px;
        margin-left: 12px;
      }
    }

    .community--search-messages {
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      width: 300px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;
      margin: 24px 8px 16px 0;

      @media screen and (min-width: 900px) {
        margin: 32px 0 8px 10px;
      }
      
      input {
        border: none;
        outline: none;
        width: 100%;
        padding: 0 8px;
      }
    
      ::placeholder {
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #888888;
      }

      @media screen and (max-width: 900px) {
        ::placeholder {
          font-size: 12px !important;
        }
      }
    }

    .search-container {
      position: relative;
      .search-list {
        position: absolute;
        top: 44px;
        background: #fff;
        border-radius: 5px;
        width: 298px;
        margin-left: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
        z-index: 999;
        height: 400px;
        overflow-x: hidden;
        overflow-y: auto;

        @media screen and (max-width: 900px) {
          margin-left: 0;
        }

        p.search-content {
          font-weight: 600;
          padding: 8px 16px;
          margin: 0;
          border-top: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          
        }

        li {
          padding: 8px 16px;
          border: none;
        }
      }
    }

    ul {
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #CCCCCC;
        padding: 14px 0;

        .active-message {
          width: 3px;
          height: 51px;
          margin-right: 12px;
        }

        .active-line {
          background: #00677E;
        }

        p {
          margin: 0;
          font-family: 'Cabin';
        }

        .community--messages-avatars {
          position: relative;

          span {
            border-radius: 5px;
            border: 1px solid #FFFFFF;
            margin-bottom: 8px;
          }

          &-two {
            position: absolute;
            left: 24px;
            top: 10px;
          }
        }

        .community--messages-avatar {
          position: relative;
          margin-right: 36px;
      

          .no-image {
            width: 50px;
            height: 50px;
            background: #bdbdbd;
          }

          span {
            border-radius: 5px;
            display: flex;
          }

          .first-initial {
            background: #00819D;
            border-radius: 50%;
            width: 31px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: 'Cabin';
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.02em;
            position: absolute;
            bottom: -7px;
            right: -10px;
          }
        }

        .community--messages-content {
          width: 100%;

          &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;

            span {
              display: flex;
              // width: 80%;

              // @media screen and (max-width: 768px) {
              //   width: 75%;
              // }
            }

            @media screen and (max-width: 768px) {
              .message-group-name {
                width: 70%;
              }
            }
            .message-name {
              font-weight: 500;
              font-size: 16px; 
              color: #00819D;
              // width: 50%;
              margin-right: 52px;

              @media screen and (max-width: 768px) {
                margin-right: 8px;
              }
            }

            .message-type {
              font-weight: 500;
              font-size: 10px;
              letter-spacing: 0.1em;
              color: #00819D;
              text-transform: uppercase;
              margin-top: 6px;
            }

            .message-last-updated {
              font-weight: 400;
              font-size: 10px;
              letter-spacing: -0.25px;
              color: #888888;
            }
          }

          .message-message {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: -0.234375px;
            color: #666666;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    display: flex;
  }

  .community--messages-single {
    position: relative;
    padding: 0 0 16px 0;

    @media screen and (min-width: 900px) {
      width: 100%;
      padding: 16px 0;
    }

    &-top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding: 0 16px 16px;

      .community--messages-avatars {
        position: relative;

        @media screen and (max-width: 900px) {
          margin-right: 16px;
        }

        .community--messages-avatars-one {
          margin-right: 16px;
        }

        .community--messages-avatars-two {
          position: absolute;
          left: 24px;
          top: 10px;
          border: 1px solid #fff;
          border-radius: 6px;
        }  
      }

      @media screen and (min-width: 900px) {
        padding: 0 32px 16px 40px;
      }

      p {
        margin: 0;
      }

      .message-name {
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin-right: 32px;
      }

      .message-type {
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 10px;
        color: #00819D;
        text-transform: uppercase;
        margin-top: 3px;
        letter-spacing: 0.1em;
      }

      &-avatar {
        position: relative;
        margin-right: 24px;
        .community--messages-avatar {
          width: 45px;
          height: 45px;
          display: flex;
          overflow: hidden;
          border-radius: 6px;
          .no-image {
            width: 50px;
            height: 50px;
            background: #bdbdbd;
          }
        }

        .first-initial {
          background: #00819D;
          border-radius: 50%;
          width: 31px;
          height: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.02em;
          position: absolute;
          bottom: -7px;
          right: -10px;
        }
      }
    }

    .message-messages {
      padding: 16px;
      margin-bottom: 80px;

      @media screen and (min-width: 900px) {
        padding: 16px 80px 16px 40px;
        padding-bottom: 250px;
        height: 70vh;
        overflow-y: auto;
        margin-bottom: 0;
      }

      .messages-list:nth-child(even) {
        flex-direction: row-reverse;

        .first-initial {
          margin-right: 0;
          margin-left: 8px;
        }

        .messages-message-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;      
        }

        .messages-message {
          background: $mainColor;
          color: #fff;
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 12px;
          margin-left: 48px;
          margin-right: 0;
          width: fit-content;

          @media screen and (max-width: 900px) {
            margin-right: 0;
            margin-left: 20px;
          }
        }

        .messages-last-updated {
          margin-left: 48px;
          text-align: right;
        }
      }

      .messages-list {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        
        @media screen and (min-width: 900px) {
          margin-bottom: 24px;
        }

        .first-initial {
          width: 40px;
          height: 40px;
          background: #00819D;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.02em;
          border-radius: 50%;
          text-transform: uppercase;
          margin-right: 8px;
          margin-bottom: 22px;
          flex-shrink: 0;
        }
        
        p {
          margin: 0;
        }

        span {
          width: 100%;
        }

        .messages-message {
          padding: 16px 24px;
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 16px;
          letter-spacing: -0.25px;
          color: #363636;
          border: 1px solid #CCCCCC;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          margin-right: 50px;
          width: fit-content;

          @media screen and (max-width: 900px) {
            margin-right: 20px;
            margin-left: 0;
          }
        }

        .messages-last-updated {
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.01em;
          color: #666666;
          margin-top: 6px;
        }
      }
    }

    .community--messages-post {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      border-top: 1px solid #CCCCCC;
      padding: 32px 16px;
      background: #fff;

      @media screen and (max-width: 900px) {
        position: fixed;
        padding: 16px;
      }

      .community--messages-post-form {
        width: 100%;

        @media screen and (min-width: 900px) {
          padding: 0 112px 0 32px;
        }

        textarea {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #BBBBBB;
          border-radius: 5px;
          height: 40px;
          padding: 8px 16px;
          font-family: $mainFont;
          outline: none;
          margin-bottom: 16px;

          @media screen and (min-width: 900px) {
            height: 100px;
            padding: 16px;
          }
        }

        .community--messages-buttons {
          display: flex;
          justify-content: space-between;

          @media screen and (min-width: 900px) {
            justify-content: flex-end;
          }

          button {
            border: none;
            letter-spacing: 0.01em;
            font-size: 15px;
            font-family: 'Cabin';
            font-weight: 600;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 49%;

            @media screen and (min-width: 900px) {
              width: 164px;
            }
          }

          .attach {
            background: #FFFFFF;
            border: 1px solid #00819D;
            color: #00819D;

            @media screen and (min-width: 900px) {
              margin-right: 8px;
            }

            img {
              margin-right: 12px
            }
          }

          .send {
            background: #00819D;
            color: #FFFFFF;
          }
        }
      }
    }

    .messages-button {
      padding: 0 16px;
    }

    .my-messages {
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid $mainColor;
      border-radius: 5px;
      color: $mainColor;
      font-family: 'Cabin';
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.01em;
      color: #00819D;
      margin-bottom: 16px;
      margin-top: 16px;

      img {
        margin-right: 16px;
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  }
}

.view-create-group-container {
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      width: 40px;
      height: auto;
      margin-bottom: 24px;

      @media screen and (max-width: 900px) {
        width: 37px;
        height: auto;
      }
    }

    h1 {
      font-family: 'Cabin';
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0.1em;
      color: #000000;
      text-transform: uppercase;

      @media screen and (max-width: 900px) {
        font-size: 20px;
      }
    }

    p {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      margin: 8px 0;

      @media screen and (max-width: 900px) {
        font-size: 20px;
      }
    }
  }

  .view-create-group {
    width: 100%;
    height: 40px;
    background: #00819D;
    border-radius: 5px;
    border: none;
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-top: 60px;

    @media screen and (max-width: 900px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

video {
  border: none;
  background: transparent;
  outline: none;
}

.video {
  position: relative;
  .video-react,
  .video-react-playing {
    height: 250px !important;
    padding-top: 0 !important;
  }
  .video-react .video-react-big-play-button {
    border: none;
    background: transparent;
  }
  .video-react-icon-play-arrow:before,
  .video-react .video-react-bezel .video-react-bezel-icon-play:before,
  .video-react .video-react-play-control:before,
  .video-react .video-react-big-play-button:before {
    // text-shadow: 0 0 1px rgb(105, 105, 105);
    font-size: 60px;
  }
  .video-react-control-bar {
    opacity: 0 !important;
  }
  .video-react:hover .video-react-control-bar {
    opacity: 1 !important;
  }
  progress {
    z-index: 9999;
    width: 100%;
    height: 3px;
    background: rgba(255, 255, 255, 0.5);
  }
  progress[value] {
    appearance: none;
    /* Needed for Safari */
    border: none;
    /* Needed for Firefox */
    color: #1CA6BA;
    /* Fallback to a solid color */
    position: absolute;
    bottom: 0;
  }
  /* WebKit styles */
  progress[value]::-webkit-progress-value {
    background-image: linear-gradient( to right, #1CA6BA, #1CA6BA);
    transition: width 1s linear;
  }
  /* Firefox styles */
  progress[value]::-moz-progress-bar {
    background-image: -moz-linear-gradient( right, #1CA6BA, #1CA6BA);
  }
}

.activity-video video,
.activity-video .video-react {
  height: 287px !important;
}