.create-groups-modal {
  padding: 32px;
  margin: 0 auto;
}

.create-groups {
  max-width: 600px;
  
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .add-photo {
    margin: 0;
    font-weight: bold;
    color: rgba(0, 0, 0, .7);
    margin-left: 8px;
    font-size: 13px;
  }
  .upload {
    margin-right: 0;
  }
  .create-group-done {
    position: absolute;
    bottom: 8px;
    width: 100%;
    button {
      font-weight: bold;
      font-size: 18px;
      background: #46AB47;
    }
  }
  .MuiTypography-caption {
    display: none;
  }
  legend,
  .PrivateNotchedOutline-legendLabelled-28,
  .PrivateNotchedOutline-legendLabelled-35 {
    font-size: 0.9em !important;
  }
  .MuiInputLabel-formControl {
    font-size: 16px !important;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .7);
  }
  .PrivateNotchedOutline-root-26 {
    border-width: 2px !important;
  }
  .MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding-bottom: 0;
  }
}

.invite-groups {
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  .has-users-button {
    background: $green;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 0 16px;
    height: 32px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Open Sans';
  }
  .upload-modal-nav {
    background: transparent !important;
    .close-icon {
      svg {
        height: 32px;
        width: 32px;
        border: 1px solid $lightBlue;
        background: transparent;
        fill: rgba(0, 0, 0, 0.8);
        padding: 3px;
      }
    }
    .title {
      position: relative;
      text-align: left;
      color: $black;
      text-transform: none;
      margin-left: 8px;
      font-weight: normal;
    }
  }
  .filter-left {
    // display: flex;
    // align-items: center;
    border-bottom: 1px solid $gray;
    padding-bottom: 8px; // flex-wrap: wrap;
    .user-container {
      display: flex;
      flex-direction: column;
    }
    .img-left img {
      margin-bottom: 8px;
      margin-left: 4px;
      cursor: pointer;
    }
    .input-field {
      background-color: #fff;
      border: none;
      outline: none; // width: 100%;
      height: 20px;
      font-size: 18px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.4;
      padding: 0;
      margin-left: 24px; // margin-bottom: 8px;
    }
  }
}

.group {
  .group-nav {
    position: relative;
    background: rgba(28, 165, 186, 0.767) !important;
    .group-nav-header {
      justify-content: space-between;
      align-items: center;
    }
    img {
      width: 25px;
    }
    svg {
      background: #fff;
      border-radius: 5px;
      fill: rgba(0, 0, 0, 0.8);
      padding: 3px;
    }
  }
  .group-image {
    position: relative;
    height: 160px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
    .group-edit {
      position: absolute;
      display: flex;
      flex-direction: row;
      border: 1px solid rgba(255, 255, 255, 0.637);
      box-shadow: none;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      width: auto;
      border-radius: 3px;
      padding: 4px 8px 4px 4px;
      text-transform: uppercase;
      font-weight: bold;
      right: 16px;
      bottom: 8px;
      img {
        margin-right: 6px;
      }
    }
  }
  h3,
  p {
    margin: 0;
    padding: 0;
    span {
      font-weight: bold;
    }
  }
  h3 {
    margin-bottom: 8px;
  }
  .group-info {
    padding: 16px 16px 8px;
    border-bottom: 1px solid $gray;
    p {
      cursor: pointer;
    }
    .group-info-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group-invite.join {
        padding: 3px 14px;
      }

      .group-invite {
        border: 1px solid $green;
        border-radius: 3px;
        background: $green;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding: 3px;
        img {
          width: 16px;
          margin-right: 2px;
        }
      }
    }
  }
  .group-discussion.blur {
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
  }
  .group-discussion {
    width: 100%;
    position: absolute;
    text-align: center;
    margin-top: 120px;
    h3 {
      color: $gray;
    }
  }
}

.groups-list {
  .rc-swipeout {
    margin-bottom: 8px;
  }
  .rc-swipeout-btn-text button {
    background: transparent;
    border: none;
    outline: none;
  }
  li {
    padding: 0;
  }
  a {
    text-decoration: none;
    p {
      font-weight: bold;
    }
    span,
    time {
      color: black;
      font-size: 14px;
    }
    .thumbnail {
      width: 80px;
      height: 50px;
      margin: 4px 8px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .MuiAvatar-square {
        height: 70px;
        width: auto;
      }
      img {
        width: 80px;
        height: auto;
      }
    }
  }
}

::placeholder {
  /* Most modern browsers support this now. */
  color: $black;
  opacity: 1;
  font-size: 14px;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $black;
  opacity: 1;
  font-size: 14px;
}

.blur {
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
}