.group-members {
    position: fixed;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    z-index: 99999;
    font-family: 'Open Sans';

    .group-members-container {
        position: absolute;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        width: 100%;
        background: white;
        height: 75vh;
        overflow: hidden; 
        bottom: 0;
        .line {
          background: #E4E4E4;
          height: 5px;
          width: 50px;
          margin: 24px auto 0;   
        }

        h5 {
            font-size: 13px;
            font-weight: bold;
            margin: 0;
            margin-left: 8px;
            margin-top: 12px;
            font-weight: boldest;
            font-family: 'Open Sans' !important;
            width: 100%;
            border-bottom: 1px solid rgb(226, 226, 226);
            padding-bottom: 8px;
        }

        hr {
            opacity: 0.2;
        }

        .contributors {
            position: absolute;
            bottom: 16px;
            margin-left: 12px;
            display: flex;
            align-items: center;
            .community-logo {
                width: 32px;
                height: 18px;
                margin-right: 8px;
            }
        }

        table {
            padding: 8px;
            overflow-y:scroll;
            display:block;

            .table-header {
                // height: 40px;
                width: 100%;
            }

            .header-name {
                display: flex;
                margin-left: 8px;
            }
           
            .user-name {
                display: flex;
                align-items: center;
                width: 250px;

                .avatar {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    border: 2px solid #FF7A00;
                    margin-right: 12px;
                }
            }
            td {
                margin-bottom: 4px;
            }
        }
    }
}