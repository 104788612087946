.rubric {
	height: 100%;
	overflow: auto;
	display: flex;
    flex-direction: column;
	justify-content: space-between;

	p.option-text {
		margin: 0;
		margin-bottom: 8px;
	}
	.rubric-container {
		margin-top: 80px;
		
		.sub-header {
			text-align: center;
			font-family: 'Open Sans';
			padding: 0 16px 8px;
		}
		.accordian-container {
			padding: 0 8px;
	
			.accordian {
				border-radius: 0;
				border-bottom-right-radius: 5px;
				box-shadow: 2px 2px #17A6BA;
				margin-bottom: 16px;

				.MuiCollapse-container {
					padding: 0 16px 8px;
				}

				.MuiTypography-body1 {
					font-size: 18px;
				}

				.MuiIconButton-root {
					margin-right: 0px;
				}

				.MuiIconButton-label {
					img {
						width: 0.9em;
						height: 0.9em;
						padding: 2px;
					}
				}

				.Mui-expanded {
					.MuiIconButton-label > img {
						background-color: #8DDCE8;
						border-radius: 50%;

						img {
							z-index: 2;
						}
					}
				}

				.description {
					display: flex;
					flex-direction: column;
					background: #E5F6F8;
					padding: 0;
					border-radius: 5px;
				}

				.truncated-text {
					padding: 8px 8px 0;

					a {
						text-decoration: none;
						color: #026978;
						font-weight: 600;
						text-transform: uppercase;
						font-size: 12px;
					}
				}
			
				.MuiAccordion-root:before {
					background-color: transparent !important;
				}
		
				.MuiAccordion-root.Mui-expanded {
					margin-bottom: 8px;
				}
	
				.MuiAccordionSummary-content {
					margin: 6px 0;
				}
	
				.MuiIconButton-root {
					padding: 0;
				}
	
				.MuiAccordionSummary-root {
					min-height: auto;
				}

				.inner-accordian {
					padding: 8px;
					background-color: transparent;
					box-shadow: none;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);

					label {
						margin-bottom: 0 !important;
					}
					.MuiTypography-body1 {
						font-size: 15px;
					}

					.MuiAccordionSummary-root {
						padding: 0 8px;
					}

					.Mui-expanded {
						.MuiIconButton-label > img {
							background-color: transparent;
						}
					}

					.MuiCollapse-container {
						padding: 0 8px;
					}

					// .MuiCollapse-container.MuiCollapse-entered {
					// 	padding-bottom: 16px !important;
					// }
				}
			}
		}
	}
	.rubric-comment-button {
		padding: 16px;
		button {
			color: #fff;
			padding: 8px 0;
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase !important;
			display: flex;
			justify-content: center;
			align-items: center;

			@media screen and (min-width: 600px) {
				width: 50%;
				margin: 0 auto;
			}
		}
	}
}