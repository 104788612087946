.shared-users {
	.shared {
		background-color: #61C3D1;
	}
	.close-icon svg {
		background-color: #fff !important;
	}
	.filter-left {
		border: 1px solid #ADADAD;
		background: #fff;
		filter: drop-shadow(0px 2px 2px #00000029);
	}
	.user-container {
		display: flex;
		// justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 16px;
		img {
			margin-top: 8px;
			width: 23px;
			height: 21px;
			filter: invert(1);
			opacity: 0.5;
			position: absolute;
			right: 16px;
		}
		.input-field {
			margin-left: 0;
			margin-bottom: 0;
			padding: 20px 0 8px;
			width: 80vw;
		}
	}

	ul {
		margin: 0;
		padding: 0;
	}

	.shared-user-list {
		display: flex;
		justify-content: space-between;
		padding-right: 16px;
		position: relative;

		.dropdown-el {
			right: 0;
			top: 22px;
		}

	}

	.left-user {
		position: relative;
		display: flex !important;
		align-items: center !important;

		.MuiAvatar-root {
			width: 30px;
			height: 30px;
		}

		p {
			margin: 0;
			font-weight: normal;
		}
	}

	select {
		-webkit-appearance: menulist-button;
		color: #1CA6BA;
		border: none;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 12px;
	  }
	  
	  select:invalid {
		color: #1CA6BA;
	  }
}