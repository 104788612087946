.group {
	font-family: "Open Sans";
	
	a {
			display: flex;
		}
	.comments-container {
	  background: #fff;
	  border-bottom: 2px solid #e4e4e4;
		padding-bottom: 8px;
		
		
  
	  .comment {
		border: none;
	  }
  
	  .reply {
		margin: 0;
		text-transform: uppercase;
		color: #17a6ba;
		font-weight: bold;
		cursor: pointer;
	  }
	}
  }