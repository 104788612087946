.library--filter {
    // padding-bottom: 24px;
    // border-bottom: 1px solid #CCCCCC;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    color: #666666;
    max-width: 1236px;

    @media screen and (max-width: 1260px) {
        padding: 0 24px;
    }

    .search {
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.01em;

        @media screen and (max-width: 1000px) {
            font-size: 16px;   
        }
    }

    .search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #888888;
        @media screen and (max-width: 1000px) {
            font-size: 16px;   
        }
      }
      
      .search:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #888888;
        @media screen and (max-width: 1000px) {
            font-size: 16px;   
        }
      }
      
      .search::-ms-input-placeholder { /* Microsoft Edge */
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #888888;
        @media screen and (max-width: 1000px) {
            font-size: 16px;   
        }
      }

    .select--wrapper {
        // width: 226px;
        position: relative;

        img {
            position: absolute;
            left: 8px;

            @media screen and (max-width: 1000px) {
                top: 18px;
            }
        }

        &-resource {
            @media screen and (max-width: 1000px) {
                margin-bottom: 16px;
            }

            .MuiSelect-select {
                padding: 16px 24px !important;
            }
        }

        &-topic {
            .MuiSelect-select {
                padding: 16px 30px !important;
            }
        }

        &-rating {
            .MuiSelect-select {
                padding: 16px 28px !important;
            }
        }

        &-happy {
            .MuiSelect-select {
                padding: 16px 32px !important;
            }
        }

        &-tag {
            .MuiSelect-select {
                padding: 16px 28px !important;
            }
        }
    }

    .MuiSelect-icon {
        fill: $mainColor !important;
    }

    .MuiSelect-select {
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        padding: 16px 28px !important;

    }

    &--container {
        display: flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 16px;
        border-bottom: 1px solid #CCCCCC;

        @media screen and (max-width: 1000px) {
            border-bottom: none;
        }
    }

    &--search {
        width: 200px;
        height: 40px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        position: relative;

        .rah-input-wrapper {
            width: 100%;
        }

        &--container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            
        }

        @media screen and (max-width: 1000px) {
            height: 51px;
            width: 100%;
        }

        input {
            border: none;
            width: 100%;
            height: 100%;
            outline: none;
        }
    }

    &--categories {
        display: flex;
        align-items: center;

        .select--wrapper {
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            height: 40px;
            margin: 0 6px;
            // padding: 0 0 0 12px;
            display: flex;
            align-items: center;
        }

        .more--img {
            margin-left: 4px;
        }

        .more {
            color: #00819D;
            font-family: 'Cabin';
            font-weight: 500;
            font-size: 16px;
            margin-left: 4px;
        }

        .MuiSelect-select.MuiSelect-select,
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
            background: none !important;
        }
        .MuiOutlinedInput-root {
            width: 200px;
            height: 40px;
            border: none !important;
            background: none !important;
        }

        p {
            color: #666666;
            margin: 0;
            margin-right: 6px;
            font-size: 11px;
        }
    }

    &--view {
        display: flex;
        align-items: center;
        p {
            margin: 0;
        }

        span {
            display: flex;

            img {
                margin-left: 16px;
                cursor: pointer;
            }
        }
    }

    &--mobile {
        padding: 0;
        border-bottom: 1px solid #CCCCCC;

        .library--filter--search {
            max-width: unset;
            margin-bottom: 16px;

            .rah-text-filler {
                margin-right: 0;
            }
        }

        &--container {
            display: flex;
            justify-content: space-between;
            padding: 0 0 16px 0;
        }
        
        span {
            display: flex;
            margin-right: 16px;

            img {
                margin-right: 4px;
            }
            p {
                margin: 0;
                color: $mainColor;
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 14px;
            }
        }

        .library--filter--left {
            display: flex;
            // span {
            //     display: flex;

            //     img {
            //         margin-right: 4px;
            //     }
            //     p {
            //         color: $mainColor;
            //         font-family: 'Cabin';
            //         font-weight: 500;
            //         font-size: 14px;
            //     }
            // }
        }

        .library--filter--view {
            p {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 11px;
                text-transform: uppercase;
                color: #666666;
                margin: 0;
                margin-right: 16px;
            }

            .grid {
                margin-right: 16px;
            }
        }
    }
}

.community--filter--mobile {
    padding: 0 !important;

    .library--filter--mobile--container {
        padding: 0 0 24px;
    }
}

.community--filter--container {
    border-bottom: none !important;
}

// .filter--menu {
//     @media screen and (max-width: 1000px) {
//         margin-top: 0;
//     }
// }

// .filter--select--option {
//     width: 226px !important;
// }