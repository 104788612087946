.pop-up {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  padding: 0 16px;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  .pop-up-modal {
    background: $white;
    padding: 24px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    position: relative;
    text-align: center;
    h1 {
      margin: 0;
      font-size: 24px;
    }
    p {
      padding: 0;
      margin: 16px 0 0;
      font-size: 14px;
    }
    .description {
      font-size: 18px;
      line-height: 1;
    }
    .MuiFormControl-root {
      padding: 8px 8px 0;
    }
    .MuiInputBase-root {
      margin-bottom: 48px;
    }
    .MuiOutlinedInput-input {
      padding: 8px;
    }
    label {
      line-height: 0.5;
    }
    .pop-up-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      button,
      a {
        border: none;
        font-size: 18px;
        // text-transform: uppercase;
        font-weight: 600;
        width: calc(50% - 12px);
        border-radius: 5px;
        padding: 12px 0;
        text-decoration: none;
        font-family: $mainFont;
      }
      .pop-up-button {
        background: transparent;
        border: 2px solid $mainColor;
        color: $mainColor;
      }
      .pop-up-button-two,
      a {
        background: $mainColor;
        color: $white;
      }
    }
  }
}
