.progress--bar {
    width: 100%;
  
    .MuiBox-root {
      width: 100%;
      text-align: center;
      
      .MuiLinearProgress-barColorPrimary {
        background-color: $mainColor;
        border-radius: 10px;
      }
      
      .MuiLinearProgress-colorPrimary {
        background-color: #eee;
        height: 10px;
        border-radius: 10px;
      }
  
      p {
        font-family: $mainFont;
        font-size: 14px;
        font-weight: bold;
      }
    }
}