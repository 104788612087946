.my-plan {
    &_btn-container {
        @media screen and (max-width: 768px) {
            padding: 24px 16px 0;
        }
    }

    .pinned--card {
        margin-bottom: 16px;
    }

    &-ul {
        padding: 0 8px;

        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }

    .objective-li {
        flex-direction: column;
        align-items: flex-start;
    }

    &-li:last-child {
        margin-bottom: 0;
    }
    
    &-li {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 18px;
        color: #000000;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 26px;
        }

        .bullet-point {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: #F87E0D;
            margin-right: 16px;
            display: flex;

            @media screen and (max-width: 768px) {
                height: 15px;
                width: 15px;
                display: flex;
            }
        }

        .question {
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 24px;
            letter-spacing: -0.25px;
            color: #000000;
            margin: 0;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 8px;
            }
        }

        .answer {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 18px;
            letter-spacing: -0.25px;
            color: #000000;
            margin: 0;

            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 26px;
            }
        }
    }

    &-li:nth-child(1) {
        .bullet-point {
            background: #FFAE68;
        }
    }

    .upcoming-li {
        .bullet-point {
            background: #0EBED8;
        }
    }

    .upcoming-li:nth-child(2) {
        .bullet-point {
            background: #00819D;
        }
    }

    .upcoming-li:nth-child(3) {
        .bullet-point {
            background: #00677E;
        }
    }

    // &-li:nth-child(2) {
    //     .bullet-point {
    //         background: #0EBED8;
    //     }
    // }

    input {
        border: none;
        width: 100%;
        height: 100%;
        outline: none;
    }

    .select--wrapper {
        position: relative;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        height: 40px;
        margin: 0 6px;
        // padding: 0 0 0 12px;
        display: flex;
        align-items: center;
        width: 200px !important;
        background: #fff;
        padding-left: 8px;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .select--wrapper-mobile {
        display: flex !important;
        width: 100% !important;
        margin: 0;

        @media screen and (min-width: 768px) {
            display: none !important;
        }
    }
    
    .MuiSelect-icon {
        fill: $mainColor !important;
    }
    
    .MuiSelect-select {
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 14px;
        color: #000;
        background: #fff;
        padding: 16px !important;
        border: none !important;
        outline: none !important;
    }

    .MuiSelect-select.MuiSelect-select,
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
        background: none !important;
    }
    .MuiOutlinedInput-root {
        height: 40px;
        border: none !important;
        background: none !important;
    }

    .notify-coach {
        border-radius: 5px;
        border: 1px solid #CCCCCC;
        padding: 32px;
        display: flex;

        @media screen and (max-width: 768px) {
           padding: 24px 16px 16px;
        }

        &-content {
            margin-left: 48px;
            width: 100%;

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }

        .stars {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        &-title {
            display: flex;
            align-items: center;

            img {
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
        }

        .how {
            margin: 0;
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 24px;
            color: #000000;

            @media screen and (max-width: 768px) {
                margin-left: 4px;
            }
        }

        .how-description {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
        }

        ::placeholder {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            letter-spacing: -0.15px;
            color: #999999;
        }

        textarea {
            background: #FFFFFF;
            border: 1px solid #BBBBBB;
            border-radius: 5px;
            padding: 16px;
            height: 81px;
            width: 100%;

            @media screen and (max-width: 768px) {
                height: 110px;
            }
        }

        .notify-coach-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            margin-left: auto;
            width: 200.24px;
            height: 36px;
            border: 1px solid #00819D;
            border-radius: 5px;
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.01em;
            color: #00819D;
            background: #fff;

            @media screen and (max-width: 768px) {
                margin: 8px auto 0;
                width: 100%;
            }
        }
    }

    .see-more {
        border: 1px solid #00819D;
        border-radius: 5px;
        background: transparent;
        font-family: 'Cabin';
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.01em;
        color: #00819D;
        width: 199px;
        height: 40px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}

.my-plans-session-list {
    margin-bottom: 32px;
    max-width: 400px;

    @media screen and (max-width: 768px) {
        margin-bottom: 24px;
        width: 100%;
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        padding: 24px !important;

        .session--event--box.box-second__session-type__inner-second-box__title {
            padding-bottom: 0 !important;
        }

        .session--event--box.box-second__session-type img {
            margin-top: 6px !important;
        }

        .session--event--box.box-third__date-right__inner-first-box-img {
            margin-top: 2px;
        }

        .session--event--box.box-first__date-left {
            @media screen and (max-width: 768px) {
                margin-bottom: 24px !important;
            }
        }

        // .session--event--box.box-second__session-type__inner-second-box__info-text {
        //     line-height: 0.5 !important;
        // }

        .session--event--box.box-third__date-right,
        .session--event--box.box-second__session-type {
            margin-top: 24px;

            @media screen and (max-width: 768px) {
                margin-top: 0 !important;
            }
        }

        .session--event--box.box-fourth__buttons {
            width: 100%;
        }
        button {
            width: 100% !important;
            margin-top: 24px;

            @media screen and (max-width: 768px) {
                margin-top: 0;
            }
        }
    }
}

.session-title {
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
}