.go-back {
  border-radius: 50%;
  background: #2929297B;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  .arrow {
    font-size: 24px,
  }
}

.modal-overview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh; // height: calc(100vh - 72px);
  z-index: 9999;
  overflow: auto;
  background: #fff;
  .header {
    flex-direction: column;
    h2 {
      width: 100%;
    }
    h3 {
      width: 100%;
    }
  }
  .modal-overview-wrapper {
    height: 100%;
    position: relative;
    .modal-overview-inner {
      padding-bottom: 30px;
    }
  }
}

.close-overview {
  z-index: 999999;
  color: black;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 8px;
  top: 16px;
  right: 16px;
}

.modal-overview-image-wrapper {
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-overview-image {
    width: 100%;
    height: auto;
  }
}

.play-section {
  display: flex;
  padding: 16px;
  align-items: center;
  .play-section-image-wrapper {
    width: 120px;
    height: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 2px 0px #0A1E41;
    position: relative;
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 48px;
      opacity: 0.7;
    }
    .play-section-image {
      width: 200px;
      height: auto;
    }
  }
  .series {
    margin-left: 16px;
    width: 50%;
    h4,
    p {
      margin: 0;
    }
    p {
      color: $green;
    }
    h4 {
      font-size: 14px;
    }
  }
}

.modal-overview-content {
  padding-bottom: 46px;
  .header,
  .description {
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 8px;
    p {
      font-size: 14px;
    }
  }
  .header {
    h3 {
      margin: 0 0 16px;
    }
    margin-right: 32px;
  }
}

.modal-buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background: #fff;
  box-shadow: 0px -1px 4px #00000029;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  .overview-button,
  .launch-button {
    width: 48%;
    font-weight: bold;
    background: $green;
  }
  .overview-button {
    background: #fff;
    border: 1px solid #D3D3D3;
  }
}

.enroll {
  .enroll-button {
    width: 100%;
    font-weight: bold;
    background: $green;
  }
}