.timeline {
  display: flex;
  position: relative;

  //progres bar
  .MuiBox-root {
    height: 100%;

    .MuiLinearProgress-barColorPrimary {
      background-color: #df187d;
      border-radius: 10px;
    }

    .MuiLinearProgress-colorPrimary {
      background-color: #c4c4c4;
      height: 10px;
      border-radius: 10px;
    }

    p {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 10px;
      color: #222222;
      margin-top: 8px;
    }
  }

  .not-today {
    top: 25px;
  }

  &--date {
    position: relative;
    top: 36px;
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    display: none;
    width: 80px;
  }

  &--line-container {
    position: relative;
    margin-left: 32px;
    margin-right: 32px;
    display: none;
  }

  &--line {
    height: 100%;
    width: 1px;
    background: #cccccc;
  }

  &--first-line-container:after {
    content: '';
    width: 12px;
    height: 1px;
    background: #ccc;
    position: absolute;
    left: -5.5px;
    top: 0;
    z-index: 1;
  }

  &--last-line-container:after {
    content: '';
    width: 12px;
    height: 1px;
    background: #ccc;
    position: absolute;
    left: -5.5px;
    bottom: 0;
    z-index: 1;
  }

  &--completed-circle {
    width: 5px;
    height: 5px;
    background: $mainColor;
    border-radius: 50%;
    position: absolute;
    top: 52px;
    left: -2px;
  }

  &--circle {
    position: absolute;
    top: 48px;
    left: -6.5px;
    width: 14px;
    height: 14px;
    background: rgba(0, 129, 157, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--small-circle {
    width: 4px;
    height: 4px;
    background: #ffffff;
    border: 1px solid #00677e;
    border-radius: 50%;
  }

  &--information {
    width: 100%;
    // padding: 16px;
    margin-bottom: 24px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    @media screen and (max-width: 968px) {
      margin-bottom: 12px;
    }

    &--title-completed {
      background: #e5f3e8 !important;

      p {
        font-size: 14px !important;
        color: #008a17 !important;
      }

      .completed {
        width: 126px;
        height: 43px;
        border-radius: 5px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Cabin';
        font-weight: 600;
        color: #008a17;
        margin-left: 0.8rem;

        @media screen and (max-width: 968px) {
          width: auto;

          .complete-text {
            display: none;
          }
        }

        .status-check {
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #008a17;
          width: 28px;
          height: 28px;
          margin-right: 12px;

          @media screen and (max-width: 968px) {
            margin-right: 0;
            width: 20px;
            height: 20px;
          }
          
          img {
            height: 11px;
            width: auto;

            @media screen and (max-width: 968px) { 
              height: 8px;
            }
          }
        }

        .check-completed {
          margin-right: 0;
        }
      }
    }

    &--title {
      background: #f6f8fb;
      border-radius: 5px 5px 0px 0px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px 0 24px;

      p {
        font-family: 'Cabin';
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  &--subject {
    padding: 12px 24px 4px;

    .outter-accordian {
      width: fit-content;
      margin-left: auto;
    }

    .MuiAccordionSummary-root {
      min-height: 10px !important;
    }

    p {
      margin: 0;
    }

    &-title {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
      color: #000000;
      margin: 0;
      letter-spacing: 0.1em;

      @media screen and (max-width: 968px) {
        margin-bottom: 8px;
      }
    }

    &-lesson {
      font-family: 'Cabin';
      font-weight: 600;
      font-size: 16px;
      color: #222222;
      margin: 6px 0 !important;


    }
  }

  .MuiAccordionSummary-content,
  .Mui-expanded {
    margin-top: 0 !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }

  &--accordian {
    box-shadow: none !important;

    .MuiIconButton-root {
      padding: 0 12px !important;
      position: absolute;
      top: -9px;
      right: 0;
    }

    .lesson--status {
      width: 50px;

      @media screen and (max-width: 968px) {
        display: none;
      }
    }

    .lessons--timeline {
      position: relative;

      &-line {
        height: calc(100% - 70px);
        width: 2px;
        background: #d8d8d8;
        position: absolute;
        top: 50px;
        left: 10px;

        @media screen and (max-width: 968px) {
          display: none;
        }
      }
    }

    .lesson--circle-completed {
      width: 22px;
      height: 22px;
      background: #ffffff;
      border: 2px solid #008a17;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      // margin-top: 8px;
      z-index: 1;
      position: absolute;

      .dot {
        width: 4px;
        height: 4px;
        background: #008a17;
        border-radius: 50%;
      }
    }

    .lesson--circle {
      width: 22px;
      height: 22px;
      background: #ffffff;
      border: 2px solid #888888;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      // margin-top: 8px;
      z-index: 1;
      position: absolute;

      .dot {
        width: 4px;
        height: 4px;
        background: #888888;
        border-radius: 50%;
      }
    }

    .timeline--lesson-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .timeline--lesson {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 60px;
      background: #eeeeee;
      border-radius: 5px;
      margin-top: 12px;
      padding: 8px 16px;

      &-left {
        display: flex;
      }

      .lesson--content {
        display: flex;
        align-items: center;

        @media screen and (max-width: 968px) {
          &-title {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.01em;
            color: #00819D;
            margin-bottom: 2px;
          }
        }

        .lesson--img {
          display: flex;
        }

        &-title {
          color: $mainColor;
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.01em;

          color: #00819d;
        }

        &-subtitle {
          font-family: 'Cabin';
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.01em;
          color: #666666;
        }

        img {
          margin-right: 16px;
        }
      }
    }
  }

  &--accordian:before {
    background-color: transparent !important;
  }

  .MuiAccordionSummary-root {
    padding: 0 !important;
  }

  .MuiAccordionDetails-root {
    padding: 0 0 8px !important;
    display: flex;
    flex-direction: column;
  }

  .resume--button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &--resume {
    margin-top: 16px;
    width: 100px;
    height: 40px;
    background: #00819d;
    border: 1px solid #00819d;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.01em;
  }
}

@media screen and (min-width: 1024px) {
  .timeline {
    &--date {
      display: flex;
    }

    &--line-container {
      display: block;
    }

    &--information {
      .completed {
        width: 126px;
        background: rgba(0, 138, 23, 0.2);
        font-size: 16px;

        .check-completed {
          margin-right: 12px;
        }
      }

      &--title {
        p {
          font-size: 18px !important;
        }
      }
    }
    &--accordian {
      .MuiIconButton-root {
        top: -3px;
        right: 5px;
      }
    }

    &--subject {
      &--title {
        font-size: 14px;

      }
      &-lesson {
        font-size: 20px;
      }
    }
  }
}
