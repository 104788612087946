.pinned--card {
    &--btn-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &--view--more {
        height: 40px;
        width: 100%;
        color: $mainColor;
        border: 1px solid $mainColor;
        border-radius: 5px;
        font-family: 'Cabin';
        font-weight: 600;
        font-size: 15px;
        background: transparent;

        @media screen and (min-width: 968px) {
            width: 200px;
        }
}

    &--circle {
        position: absolute;
        top: 8px;
        right: 8px;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        background: #DF187D;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        cursor: pointer;
    }

    // @media screen and (max-width: 600px) {
    //     width: 100%;
    //     margin-right: 0;
    // }

    &--image--container {
        position: relative;
        background: #D9F1F6;
        // max-width: 285px;
        height: 160px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position:center center;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        background-repeat: no-repeat;

        @media screen and (max-width: 600px) {
            max-width: 100%;
        }

        .icon {
            width: 62px;
            height: auto;
        }
    }

    &--image {
        width: 300px;
        flex-grow: 1;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &--content {
        display: flex;
        // align-items: center;
        width: 285px;
        margin-top: 12px;
        margin-bottom: 12px;

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &--image {
            margin-right: 8px;
            margin-top: 4px;
            height: auto;
            display: flex;
            align-items: flex-start;

            img {
                width: 15px;
                height: 15px;
            }
        }

        &--title {
            margin: 0;
            color: $mainColor;
            font-family: $mainFont;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;
      }
      .my-masonry-grid_column {
        padding-left: 24px; /* gutter size */
        background-clip: padding-box;
      }
      
      /* Style your items */
      .my-masonry-grid_column .group--card { /* change div to reference your elements you put in <Masonry> */
        background: white;
        margin-bottom: 24px;
      }
}