.secondary-card {
  height: 100vh;
  overflow: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin: 0;
    color: #1CA6BA;
    font-weight: bold;
    margin-top: 56px;
    padding: 0 16px;
    text-shadow: 1px 0px $burgundy;
    font-size: 36px;
    width: 100%;
    max-width: 600px;
    text-align: left;
  }
  .secondary-card-container {
    height: 75vh;
    border: 1px solid #1CA6BA;
    border-right: 3px solid #1CA6BA;
    border-bottom: 3px solid #1CA6BA;
    background: #fff;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 56px 24px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    justify-content: space-between;
    img {
      position: absolute;
      width: 80px;
      top: -35px;
    }
    .hr {
      border: 0.1px solid #1CA6BA;
      width: 100%;
      height: 0.5px;
    }
    .secondary-card-content {
      margin-top: 56px;
      p {
        font-weight: bold;
        font-size: 16px;
      }
    }
       .back-button {
      background: none;
      border: none;
      border-radius: 5px;
      border: 1px solid #1CA6BA;
      height: auto;
      margin-left: 0;
      height: 40px;
      width: 40px;
      margin-right: 8px;
      svg {
        color: #1CA6BA;
        fill: #1CA6BA;
      }
    }
    .secondary-card-bottom {
      button {
        margin-top: 16px;
        background: #1CA6BA;
        width: 100%;
        height: 40px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        letter-spacing: 0.5px;
      }
    }
  }
}