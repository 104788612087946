.onboarding-upload {
  .file {
    .circle {
      border: 1px dashed #fff !important;
    }
  }
  .zoom {
    margin: 0;
    margin-top: 16px;
  }
  .MuiSlider-root {
    padding: 0;
  }
  .MuiSlider-root,
  .MuiSlider-thumb {
    color: #17A6BA;
  }
  .upload {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #F3FDFF;
    border-right: 1px solid #17A6BA;
    border-bottom: 1px solid #17A6BA;
    border-radius: 3px;
    width: 100%;
    @media screen and (min-width: 600px) {
      width: 50%;
      margin: auto;
    }
    canvas {
      width: 100% !important;
      height: auto !important;
      position: relative;
    }
    .circle {
      position: absolute;
      top: 3%;
      bottom: 0;
      border: 1px dashed #17A6BA;
      border-radius: 50%;
      width: 94%;
      height: 94%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .file-upload {
      position: absolute;
      top: 3%;
      bottom: 0;
      width: 94%;
      height: 94%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      p {
        text-transform: initial !important;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.1;
      }
      span {
        background: none !important;
        border: none !important;
        font-weight: bold;
        font-size: 13px;
        box-shadow: none;
        color: #17A6BA;
        display: flex;
        flex-direction: column;
        text-transform: initial !important;
      }
    }
  }
  .skip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
      color: #17A6BA;
      text-transform: uppercase;
      background: none;
      border: none;
      font-weight: bold;
      font-size: 13px;
      padding: 16px;
      display: flex;
      justify-content: center;
    }
    p {
      text-align: center;
      font-size: 18px;
      font-style: italic;
      font-weight: normal;
      margin: 0;
    }
  }
  .onboarding-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  // .form {
  //   overflow: auto;
  // }
  .continue-light {
    background-color: #8DDCE8 !important;
  }
  .continue-dark {
    background-color: #1CA6BA !important;
  }
  .buttons {
    display: flex;
    margin-top: 16px;
    width: 100%;
    button {
      background: none;
      border: none;
      border-radius: 5px;
      border: 1px solid #1CA6BA;
      height: auto;
      margin-left: 0;
      height: 40px;
      width: 40px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #1CA6BA;
        fill: #1CA6BA;
      }
    }
    .continue {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: 100%;
      align-items: center;
      padding: 18px 0;
      text-transform: none;
      font-weight: bolder;
      border: none;
      box-shadow: none;
      height: 40px;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      margin-right: 0;
    }
  }
}