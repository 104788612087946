$color: $mainColor;
$timing:.3s;

.dropdown-el { 
  margin-top: 4px;   
  width: 8.3em;
  position: relative;
  display: inline-block;
  margin-right: 1em;
  min-height: 2em;
  max-height:2em;
  padding-top: 4px;
  overflow:hidden;
  // top: .5em;  
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  color: #444;
  margin-left: 10px;
  outline: none;
  border: .06em solid transparent;
  border-radius: 1em;
  position: absolute;
  z-index: 10;
  // top: 55%;
  color: $mainColor;
//   background-color: mix($color,#fff,25%);
  
//   transition: $timing all ease-in-out;
//   input:focus + label {
//     background: #def;
//   }
  input {
    width: 1px;
    height: 1px;
    display: inline-block;
    position: absolute;
	opacity: 0.01;
  color: $mainColor;
  }
  label {
    // border-top: .06em solid #d9d9d9;
    
    // line-height: 2.5em;
	padding-left: 0.6em;
    cursor: pointer;
    position: relative;
	// transition: $timing color ease-in-out;  
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	font-family: 'Open Sans';
    // &:nth-child(2) {
    //   border-top: .06em solid #d9d9d9;
	// }
  display: flex;
  align-items: center;
	.check {
		width: 20px;
		display: flex;
    align-items: center;
    margin-top: 0px;
		svg {
			width: 16px;
			height: 14px;
			margin-top: 3px;
		}
	}
  }
  input:checked + label {
    display:block;
    border-top: none;
    position: absolute;
    top: 0;
    width: 100%;

    &:nth-child(2) {
      margin-top: 0;
      position: relative;
    }
  }
  
  &::after {
    content:"▼";
    position: absolute;
    right: 0.8em;
    top: 0.15em;
    // border: .4em solid $color;
    //border-color: $color transparent transparent transparent;
	// transition: .4s all ease-in-out;
  }
  &.expanded {
    border: .06em solid $color;
    background: #f5f5f5;
    border-radius: .25em;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
  max-height:15em;
  padding-top: 4px;
  z-index: 11;
    
    label {
    //   border-top: .06em solid #d9d9d9;
      &:hover {
        color:$color;
      }
    }
    input:checked + label {
      color:$color;
    }
    
    &::after {
      transform: rotate(-180deg);
      top:0.15em;
    }
  }
}