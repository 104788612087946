.video-modal {
  z-index: 99999;
}

.upload-modal-nav {
  position: relative !important;
  background: rgba(28, 165, 186, 0.767) !important;
  textarea {
    height: 300px !important;
  }
  .title {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  .close-icon {
    z-index: 10;
  }
  .close-icon svg {
    background: #fff;
    border-radius: 5px;
    padding: 3px;
  }
  .close-icon svg.MuiSvgIcon-root {
    color: black !important;
  }
}

.upload span.MuiButton-contained {
  background: #00819d !important;
  color: #fff;
  font-weight: bold;
  padding: 4px 0;
  margin-right: 0;
  height: 38px;
  min-width: 150px;
  text-transform: capitalize;
  font-size: 15px;

  @media screen and (max-width: 768px) {
    min-width: 130px;
  }
  .paperclip {
    width: 10px;
    margin-right: 8px;
  }
}

.save-upload {
  // position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 90%;
    background: #25c522;
    font-weight: bold;
    color: #fff;
  }
}

#outlined-full-width-label {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

::placeholder {
  font-size: 14px;
}
