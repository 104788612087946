.add-button {
    background: $mainColor !important;
    border-radius: 5px;
    color: #fff !important;
    font-weight: 600 !important;
    font-family: $mainFont !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    font-size: 15px;
    text-transform: none !important;
    letter-spacing: 0.02em;

    span {
        font-size: 15px;
    }

    .add {
        width: 10px;
        height: 10px;
        margin-right: 8px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}