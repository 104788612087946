.preferred-language {
  p {
    font-size: 18px;
  }
  .radio {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      margin: 0; 
    }
  }
  .radio input[type=radio],
  .radio input[type=radio]:after {
    width: 28px;
    height: 28px;
    margin: 0px 8px 0 0;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
  }
  // .radio input[type=radio]:checked {
  //   border: 10px solid #fff;
  // }
  // .radio {
  //   margin-bottom: 16px;
  //   display: flex;
	// 	align-items: center;
	// 	padding-left: 3px;
  //   label {
  //     margin-bottom: 0;
  //     margin-left: 8px;
	// 		margin-top: 8px;
  //   }
  //   input[type='radio'] {
  //     width: 28px;
	// 		height: 28px;
  //   }
  //   input[type='radio']:after {
  //     width: 28px;
  //     height: 28px;
  //     border-width: 2px;
	// 		border: 2px solid #61C3D1;
  //   }
  // }
}