.profile-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.error {
		color: red;
		margin: 0;
		padding: 0;
	}

	.profile-content,
	.save-button {
		padding: 16px;
	}

	label p {
		font-size: 14px;
		font-family: $headerFont;
		margin: 24px 0 8px !important;
	}

	.MuiOutlinedInput-root {
		// border: 1px solid #707070;
		height: 40px;
		font-family: $headerFont;
		font-size: 12px;
	}

	.MuiSelect-select:focus {
		background: none;
	}

	fieldset{
		border: none !important;
		outline: none !important;
		border: 0.25px solid #70707075 !important;
	}

	.MuiList-padding {
		padding: 0 !important;
	}
}

.MuiMenu-paper {
	// height: 240px !important;
	margin-top: -2px;
	border: 0.25px solid #70707075 !important;
	// box-shadow: none !important;
	// border-top-left-radius: 0 !important;
	// border-top-right-radius: 0 !important;
	// border-top: 0 solid transparent !important;
	border-radius: 5px;
}