.horizontal-line {
  display: none;
}

@media screen and (min-width: 1020px) {
  .horizontal-line {
    border: 0.5px solid #cccccca6;
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: block;
  }
}
