.MuiPaper-elevation4 {
  box-shadow: !important;
}

.MuiTab-root {
  padding: 6px 16px !important;
  min-width: 0 !important;
  min-height: 0 !important;
}

header.MuiPaper-root {
  box-shadow: none !important;
}

.resourcelist-entry {
  padding: 0 !important;
  // margin: 0.5rem !important;
  border-radius: 6px 2px 6px 6px !important;
  border-color: #17a6ba;
  border-bottom: 2px solid #17a6ba !important;
  border-right: 2px solid #17a6ba !important;
}

.resourcelist-container {
  padding: 0 !important;

  li {
    border-bottom: 0 !important; 
  }

}

.resourcelist-item-avatar {
  height: 7rem;
}

.resourcelist-img {
  height: 100% !important;
  width: 5.5rem !important;
  border-radius: 4px !important;
  border-color: #61c6d4;
  border-width: 2px;
  border-style: solid;
}

.resourcelist-block {
  display: block !important;
  padding: 8px 0;
  width: 100%;
  height: 100%;
}

.resourcelist-item-title {
  padding: 5px !important;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  display: block;
  width: 100%;
  height: 50%;
}

.resourcelist-icon {
  display: block;
  width: 100%;
}

.resourcelist-icon a {
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  float: right;
  margin: 0.5rem 0.25rem 0rem 0.25rem;
  border-radius: 4px !important;
  border-color: #c5facf;
  border-width: 1px;
  border-style: solid;
  position: relative;
}

.resourcelist-icon img{
  width: 55%;
  height: 55%;
  fill: gray;
  margin: auto;
  margin-top: 0.5rem;
  display: block;
}

.resourcelist-icon .view-button {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  background-color: #1CA6BA;
  border-radius: 4px !important;
  border-color: #c5facf;
  border-width: 1px;
  border-style: solid;
  display: block !important;
  height: 2.5rem !important;
  width: 5.5rem !important;
  float: left !important;
  padding: 0.5rem !important;
  margin: 0.5rem 0.25rem 0rem 0.25rem !important;
  text-align: center;
}

.resourcelist-icon div button {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  float: right;
  margin: 0.5rem 0.25rem 0rem 0.25rem;
  border-radius: 4px !important;
  border-color: #c5facf;
  border-width: 1px;
  border-style: solid;
  position: relative;
  background-color: white !important;
  padding: 0;
}

