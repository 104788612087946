.enroll {
	margin-top: 70px;

	@media screen and (max-width: 900px) {
		.info-card .info-card-scroll-container {
			h2 {
				text-align: left !important;
			}

			.subtitle p {
				padding: 0 !important;
			}
		}
	}

	.course {
		@media screen and (max-width: 900px) {
			li {
				height: 390px !important;

				.box-info {
					h2 {
						text-align: center;
					}

					.subtitle p span {
						font-size: 24px !important;
					}
				}
			}
		}
	}

	.buttons {
		margin-bottom: 10px;
	}
	.buttons button {
		border: none;
		background: $mainColor;
		color: #fff;
		height: 40px !important;

		@media screen and (min-width: 900px) {
			width: 150px !important;
		}
	}

	.enrollButton {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.enroll-module-outline {
	.buttons {
		button {
			background: #1CA6BA;
			border: 1px solid #8DDCE8;
			width: 176px;
			margin-right: 16px;
			color: #fff;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
			padding: 8px 24px;
			font-size: 16px;
			border-radius: 6px;
		}
	}
	.outline {
		svg {
			margin-right: 8px;
		}
	}
}