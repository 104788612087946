.resources {
  // margin-top: 8px;
  // padding-bottom: 60px;
  // h1 {
  //   margin-left: 16px;
  // }
  .MuiBox-root {
    padding: 0;
  }
  .MuiTabs-root {
    border-bottom: 1px solid #707070;
    position: relative;

    .Mui-selected {
      color: #fd7a00;
      font-weight: bold;
    }
  }
  // .PrivateTabIndicator-root,
  // .MuiTabs-indicator {
  //   background-color: #fd7a00 !important;
  // }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      padding: 8px 16px;
      border-bottom: 1px solid rgb(226, 226, 226);
      .header {
        display: flex;
        justify-content: space-between;
        .header-title,
        .days {
          font-size: 12px;
        }
        .header-title {
          color: #fd7a00;
        }
      }
    }
  }
  .add-items {
    position: fixed;
    background: #fd7a00;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
    bottom: 72px;
    box-shadow: 0px 3px 6px #00000029;
    svg {
      color: #fff;
      font-size: 40px;
    }
  }
  .videos {
    margin-bottom: 30px;
    .list {
      background: white;
      z-index: 999;
      .list-wrapper {
        .list-wrapper-container {
          display: flex;
          padding: 8px;
          .invited {
            display: flex;
            align-items: center;
            margin-top: 4px;
            p {
              color: black;
              text-transform: none !important;
            }
            .avatar {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 2px solid #ff7a00;
              margin-right: 4px;
            }
          }

          .thumbnail {
            width: 90px;
            height: 60px;
            margin-right: 8px;
            overflow: hidden;
            background: rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            .react-thumbnail-generator {
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 70px;
              }
            }
          }
          .MuiListItem-root {
            align-items: flex-start !important;
            padding: 0;
          }
          .list-item-text {
            margin-top: 0;
            line-height: 1;
            padding-bottom: 6px;
          }
        }
      }
    }
  }
  .MuiListItemAvatar-root {
    background: white;
    height: 70px;
  }
  .resourcelist-block {
    background: white;
    height: 70px;
    position: relative;
    display: flex !important;
    align-items: space-between;
    p {
      margin: 0;
      padding: 0 !important;
      font-size: 13px;
    }
    .submitted-for-evaluation {
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: normal;
      font-size: 14px;
      padding: 0;
      text-decoration: none;
      color: $black;
      position: absolute;
      bottom: 8px;
      span {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background: $orange;
        margin-right: 4px;
      }
    }
  }
  a {
    text-decoration: none;
  }
}

.MuiListItemText-multiline {
  border-color: none;
}

.list-desktop {
  width: 100%;
  li {
    width: 100%;
  }
  .resource-container {
    position: relative;
  }
  .resource-edit-buttons {
    display: flex;
    align-items: center;
    top: 0;
    right: 16px;
    height: 100%;
    position: absolute;
    pointer-events: auto;
    button {
      background: transparent;
      border: none;
      img {
        margin: 0 8px;
      }
    }
  }
  .resourcelist-entry {
    position: relative;
  }
}

.list-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .list-mobile {
    display: block;
  }
  .list-desktop {
    display: none;
  }
}

.rc-swipeout {
  margin-bottom: 8px;

  .rc-swipeout-btn-text {
    padding: 0;
  }

  button {
    background: none;
    border: none;
    width: 50px;

    img {
      opacity: 0.8;
    }
  }
}
.rc-swipeout-btn-text button {
  background: transparent;
  border: none;
  outline: none;
}
