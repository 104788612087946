.community--public {
    display: flex;
  
    .mobile {
      display: none;
    }

    .group--name--mobile {
      display: none !important;

      @media screen and (max-width: 900px) {
        display: flex !important;
      }
    }

    .group--name {
      background: #EEEEEE;
      border-radius: 5px;
      font-family: $mainFont;
      font-weight: 400;
      font-size: 10px;
      color: #777777;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      width: fit-content;
      letter-spacing: 0.5px;

      img {
        height: 10px;
        width: auto;
        margin-right: 8px;
      }
    }
  
    @media screen and (max-width: 900px) {
      .mobile {
        display: block;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        .community--public--content--image--container {
          overflow: unset;
          align-items: unset;
          width: auto !important;
          padding: 0;
          justify-content: flex-start;
          height: auto !important;
          background: #D9F1F6;

          @media screen and (max-width: 900px) {
              background: transparent;
          }
          h1 {
            font-size: 20px;
            margin: 0;
          }

          &--left {
            overflow: hidden;
            width: 80px;
            height: 80px;
            margin-right: 1rem;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position:center center;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-size:cover;
            background-repeat: no-repeat;
            flex-shrink: 0;

            // img {
            //     width: auto;
            //     height: 100%;
            // }
        }
  
          .group--privacy {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 3;
            width: 100%;

            &--happy {
              height: 18px !important; 
            }

            img {
              height: 15px;
              width: auto;
            }
  
            .private--container {
              display: flex;
              justify-content: space-between;
              align-items: end;
  
              p {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 13px;
                color: #888888;
                margin: 0;
              }

              span {
                display: flex;
                align-items: center;
                img {
                  margin-right: 1rem;
                }
              }
            }
          }
        }
  
        button {
          font-size: 12px;
          margin: 26px 0 0 0;
          margin-bottom: 0 !important;
          width: 110px;
          // margin-right: 5px;
        }
  
        .arrow-down {
          margin-left: 8px;
          margin-right: 0;
        }
  
        .add {
          margin-left: 6px;
          margin-right: 6px;
        }
  
        .community--public--content--container {
          padding: 0;
          margin-top: 2rem;
        }
      }
  
      .desktop {
        display: none;
      }
    }
  
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }
  
    &--feed {
      width: 60vw;
      height: 100%;
      padding: 0 64px 24px;

      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 16px 24px;
      }
  
      // .container {
      //     width: 100%;
      // }
  
      &--buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          display: flex;

          @media screen and (max-width: 480px) {
            width: 100%;
            justify-content: space-between;

            button {
              width: 49% !important;
              margin: 0 !important;
            }
          }
        }

        @media screen and (max-width: 480px) {
          justify-content: space-between;
          flex-direction: column-reverse;
          width: 100%;
        }

        .selected-file {
          color: #000;
          font-size: 14px;
          font-family: $mainFont;
          font-weight: 400;
          margin: 0 8px 0;

          @media screen and (max-width: 480px) {
            margin-top: 8px;
          }
        }
  
        .attach {
          width: 164px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #00819d;
          border-radius: 5px;
          color: $mainColor;
          font-family: 'Cabin';
          font-weight: 600;
          font-size: 15px;
          margin-right: 8px;
          
        }
  
        .post {
          width: 166px;
          height: 40px;
          background: #00819d;
          border: 1px solid #00819d;
          border-radius: 5px;
          color: #fff;
          font-family: 'Cabin';
          font-weight: 600;
          font-size: 15px;
        }
      }
  
      &--add--comment {
        display: flex;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 24px 64px 24px 24px;
        margin-bottom: 16px;

        @media screen and (max-width: 900px) {
          padding: 16px 10px;
        }
  
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
        }

        .MuiOutlinedInput-multiline {
          padding: 0;
        }
  
        textarea {
          background: #eeeeee;
          border-radius: 10px;
          border: none;
          margin-bottom: 24px;
          padding: 12px;
          height: 50px !important;

          @media screen and (max-width: 900px) {
            height: 118px !important;
            padding: 12px 14px;
          }
        }

        textarea::-webkit-input-placeholder {
          color:#666666 !important;
          opacity: 1;
          font-size: 15px;
        }
        
        textarea:-moz-placeholder { /* Firefox 18- */
          color:#666666 !important; 
          opacity: 1;
          font-size: 15px;
        }
        
        textarea::-moz-placeholder {  /* Firefox 19+ */
          color:#666666 !important;
          opacity: 1;
          font-size: 15px;
        }
        
        textarea:-ms-input-placeholder {
          color:#666666 !important;
          opacity: 1;
          font-size: 15px;
        }
        
        textarea::placeholder {
          color:#666666 !important;
          opacity: 1;
          font-size: 15px;
        }
  
        .name {
          font-family: $mainFont;
          font-weight: 600;
          font-size: 15px;
          margin-top: 10px;
          margin-left: 16px;
          display: flex;
          justify-content: space-between;

          .group--name {
            margin-bottom: 0;
          }
        }
  
        &--avatar {
          background: $mainColor;
          border-radius: 50%;
          height: 38px;
          width: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-transform: uppercase;
          font-family: 'Cabin';
          font-weight: 700;
          font-size: 20px;
          color: #eeeeee;
          flex-shrink: 0;

          &--mobile {
            @media screen and (max-width: 900px) {
                display: flex !important;
                padding: 0 !important;
                align-items: center;
                margin-bottom: 12px;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 15px;

                .community--public--feed--add--comment--avatar {
                    margin-right: 8px;
                }  
            }
        }
          
        }
      }
  
      &--recent {
        border: 1px solid #cccccc;
        border-radius: 5px;
        margin-top: 16px;
        // padding: 12px 64px 12px 24px;
  
        &--join {
          background: #eeeeee;
          border-radius: 5px;
          padding: 24px 64px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          h1 {
            margin: 0;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
          }
  
          p {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 15px;
            width: 80%;
          }
  
          button {
            width: 134.22px;
            height: 40.18px;
            background: #00819d;
            border: 1px solid #00819d;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 15px;

            img {
              margin-right: 8px;
            }
          }
        }
  
        &--content {
          padding: 12px 64px 12px 24px;
          &--top {
            display: flex;
            flex-direction: column;
            padding: 24px;
            width: 100%;
  
            &--container {
              margin-bottom: 24px;
              display: flex;
              width: 100%;
            }
  
            .name {
              font-family: 'Cabin';
              font-weight: 500;
              font-size: 16px;
              margin-left: 16px;
            }
  
            .date {
              font-family: 'Cabin';
              font-weight: 400;
              font-size: 12px;
              color: #888888;
            }
  
            .community--comment--container {
              margin-left: 6px;
              width: 100%;
  
              span {
                display: flex;
                align-items: center;
  
                p {
                  margin-right: 36px;
                }
              }
            }
  
            .community--comment {
              font-family: 'Cabin';
              font-weight: 400;
              font-size: 15px;
              margin: 0;
              background: #eee;
              border-radius: 5px;
              width: 100%;
              padding: 16px;
            }
  
            .no-profile-image {
              width: 48px;
              height: 48px;
              position: relative;
              border-radius: 5px;

              span {
                position: absolute;
                top: 12px;
                right: -6px;
                width: 30px;
                height: 30px;
                background: $mainColor;
                border-radius: 50%;
                @include flexbox();
                color: #fff;
                font-family: 'Cabin';
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
  
            .profile-image {
              width: 45px;
              height: 45px;
              border-radius: 5px;
              // overflow: hidden;
              background: $mainColor;
              position: relative;
              img {
                width: 45px;
                height: 45px;
                border-radius: 5px;
              }
              span {
                position: absolute;
                top: 24px;
                right: -6px;
                width: 30px;
                height: 30px;
                background: $mainColor;
                border-radius: 50%;
                @include flexbox();
                color: #fff;
                font-family: 'Cabin';
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
  
      &--container {
        max-width: 800px;
        width: 100%;
        float: right;
      }
  
      .recent--activity {
        margin: 0;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
      }
    }
  
    &--content {
      width: 40vw;
      background: #eeeeee;
      min-height: calc(100vh);
      flex-grow: 1;
  
      &--buttons {
        @media screen and (max-width: 480px) {
          justify-content: space-between;

          button {
            width: 32% !important;
          }
        }

        .arrow-down {
          margin-left: 8px;
        }
      }
  
      @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        min-height: unset;
        display: flex;
      }
  
      &--buttons {
        display: flex;
        margin-bottom: 0;
  
        button {
          margin-bottom: 24px !important;
        }
  
        .people {
          margin-right: 8px;
        }
      }
  
      .members--list {
        @media screen and (max-width: 900px) {
          display: none;
        }

        &--member-count {
          color: #888888;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Cabin';
        }
  
        .see-all {
          width: 100%;
          max-width: 347px;
          background: $mainColor;
          color: #fff;
        }
  
        &--users {
          display: flex;
          align-items: center;
          margin: 16px 0;

          &--avatar {
              width: 48px;
              height: 48px;
          }
  
          &--name {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            color: $mainColor;
            margin: 0 32px;
            width: 120px;
            font-size: 16px;
          }
  
          &--joined {
            font-family: 'Cabin';
            font-style: italic;
            font-weight: 400;
            font-size: 10px;
            color: #666666;
          }
  
          span {
            height: 48px;
            width: 48px;
            background: $mainColor;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: 'Cabin';
            font-weight: 700;
            font-size: 24px;
            text-transform: uppercase;
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }
  
      &--image--container {
        overflow: hidden;
        height: 350px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D9F1F6;
        background-position:center center;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        background-repeat: no-repeat;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
  
        @media screen and (max-width: 900px) {
          padding: 24px 24px 0 24px;
          width: 150px !important;
          height: 150px !important;
          background: transparent;
        }
  
        img {
          width: 110%;
        //   height: 110%;
        }

        .icon {
            height: 200px;
        }
      }
  
      &--container {
        padding: 32px;
  
        h1 {
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          margin: 0;
          margin-bottom: 24px;
        }
  
        .group--information {
          color: $mainColor;
          display: flex;
          align-items: center;
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
  
          img {
            margin-right: 6px;
          }
        }
  
        .group--description {
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
  
        .group--privacy {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;

          &--happy {
            height: 18px !important; 
          }

          p {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 18px;
            color: #888888;
            margin: 0;
          }
  
          span {
            display: flex;
            height: 15px;
            width: auto;
  
            img {
              margin-left: 16px;
            }
          }
        }
  
        button {
          margin: 36px 0 56px 0;
          width: 134.22px;
          height: 40.18px;
          background: transparent;
          border: 1px solid #00819d;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $mainColor;
          font-family: 'Cabin';
          font-weight: 600;
          font-size: 15px;
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }