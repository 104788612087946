.header {
  display: flex;
  justify-content: space-between;
  height: 150px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgb(29, 169, 189));
  position: fixed;
  width: 100%;
  z-index: 99999;
  padding: 16px;
  top: 0;
  .close,
  .download {
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #C4F1F7;
  }
  .download {
    img {
      width: 18px;
    }
  }
}

.completed-outline {
  .MuiLinearProgress-bar {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.lesson {
  .rc-swipeout {
    display: none;
    overflow: unset;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
  .lesson-buttons {
    display: flex;
    align-items: center;
    top: 12px;
    right: 16px;
    height: 100%;
    position: absolute;
    pointer-events: auto;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  font-family: 'Open Sans';
  .go-back {
    margin-right: 8px;
    width: 37px;
    .arrow {
      width: 0.9em;
      height: 0.9em;
    }
  }
  .MuiTabs-root {
    width: 100%;
  }
  .Mui-selected {
    font-weight: bold;
  }
  .MuiTabs-indicator {
    background-color: white !important;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTab-wrapper {
    text-transform: none;
  }
  .tab-panel {
    width: 100%;
    margin-top: 48px;
    .tab-content {
      height: 100%; // padding-top: 57px;
    }
  }
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }
  .discussions,
  .reflections {
    .create-groups {
      button {
        bottom: 16px;
      }
    }
    .comment-scroll {
      overflow: scroll;
      position: fixed;
      height: 100%;
      padding-bottom: 120px;
      width: 100%;
      background: #F4FAFF;
    }
    .title {
      padding: 8px 16px;
      background: #F4FAFF;
      border-bottom: 2px solid #E4E4E4;
      h3 {
        margin: 0;
        margin-bottom: 8px;
        line-height: 1.3;
      }
      p {
        margin: 0;
      }
    }
  }
  .comment-container {
    background: #fff;
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 8px;
    .reply {
      margin: 0;
      text-transform: uppercase;
      color: #17A6BA;
      font-weight: bold;
      cursor: pointer;
    }
    .reply-buttons {
      padding: 0 8px;
      display: flex;
    }
  }
  .comment {
    border: none;
  }
  .comment .comment-content .reply {
    margin: 0;
  }
}

.submit-button,
.close {
  cursor: pointer;
}

.reply-form .comment {
  background: #F4FAFF !important;
}

.reflections {
  .reflection-comment {
    width: 100%;
    padding: 16px;
    background: #fff;
    box-shadow: 0 4px 2px -2px #EDF0F7;
    margin-bottom: 6px;
    .time {
      font-size: 11px;
      color: #585858;
      text-align: right;
      margin-bottom: 8px;
      margin-right: 16px;
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}

textarea {
  position: relative;
  resize: none;
  border: none;
  outline: none;
  font-family: 'open sans';
  font-size: 14px;
  width: 100%;
  height: auto;
  padding: 0 16px;
}

::placeholder {
  color: #707070 !important;
}

.reflection-delete {
  button {
    background: none;
    border: none;
    width: 60px;

    img {
      opacity: 0.8;
    }
  }
}

// .learning-tabs {
//   .MuiTabs-flexContainer {
//     justify-content: center;
//   }
// }