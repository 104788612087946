.collections--filter--view {
    .library--filter--categories {
        margin-right: 32px;
    }
}

.collections-grid {
    margin-top: 24px;
    max-width: 1236px;
    margin: 0 auto;
    padding: 24px 0 0;

    .edited-card {
        margin-bottom: 32px;
    }

    .edited-text {
        display: none;
    }
}

@media screen and (max-width: 1260px) {
    .collections-grid {
        padding: 24px;
    }
}

.save-to-collections {

    @media screen and (max-width: 768px) {
        .btn--save--upload {
            position: absolute;
            width: 100%;
            padding: 16px;
            left: 0;
            bottom: 0;
            background: #fff;
            margin-top: 0 !important;
            z-index: 999;
        }

        ul li {
            padding: 10px 16px 13px !important;
        }

        .primary-modal-mobile-x,
        .primary-modal-close-text {
            display: block;
        }


        .primary--modal--container {
            top: 0;
            padding: 16px;

            .collections-list {
                margin-top: 0 !important;
                height: 73vh;
                padding-bottom: 60px;
            }

            .modal--btnClose--container {
                position: relative;
                border-radius: 0;
                width: 100%;
                left: 0;
                background: #EEEEEE;
                border-radius: 5px;
                height: 40px;
                top: 0 !important;

                .btnClose--icon {
                    display: none;
                }

                .mobile-x {
                    margin-right: 8px;
                }

                .close-text {
                    font-family: 'Cabin';
                    font-weight: 600;
                    font-size: 15px;
                    letter-spacing: 0.01em;
                    color: #00819D;
                }
            }

            h1 {
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 0.01em;
                color: #000000;
                margin: 24px 0;
            }
        }
    }
}