.student {
  // .coach--tabs {
  //   @media screen and (max-width: 900px) {
  //     display: none;
  //   }
  // }
}

.student--bar {
    padding: 1rem 0;
    opacity: 0.9;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%) !important;
  }
  .react--table.student {
    width: 90% !important;
    th {
      border-bottom: none;
      height: auto;
      .table--colHeaderBox {
        justify-content: unset;
        gap: 5px;
        padding: 22px 0 0 0;
        p {
          margin: 0;
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 400;
          color: #000000;
        }
      }
    }
    tbody {
      tr {
        border-bottom: none;
        td {
          padding-bottom: 30px !important;
        }
      }
      .student--hr {
        width: 93%;
        border: 0.5px solid #cccccc;
      }
      .student--hr:last-child {
        width: 0;
      }
      tr:first-child {
        td {
          padding: 16px 0 30px;
        }
      }
      .student--resource {
        .student--image {
          position: relative;
          width: auto;
          height: 200px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eeeeee;

          video {
            min-width: 360x;
            width: 100%;
          }

          .student--play {
            z-index: 99;
            background: #00819d;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 8px;
            bottom: 8px;
            img {
              display: flex;
              height: 11px;
              width: 9px;
            }
          }
        }
        .student--content {
          .student--buttons {
            display: flex;
            flex-direction: column;

            .student--view {
              border: 1px solid #00819d;
              color: #00819d;
              border-radius: 5px;
              width: 150px;
              height: 36px;
              font-size: 15px;
              font-weight: 600;
              font-family: $mainFont;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 16px;
              margin-bottom: 20px;
            }
            .student--btns.mobile {
              display: flex !important;

              .student--info {
                display: flex;
                align-items: center;
                margin-right: 1.3rem;
              }
              .handle-click {
                display: flex;
                align-items: center;
                margin-right: 1.3rem;
                cursor: pointer;
              }

              .btn--save--upload {
                span {
                  color: #fff;
                }
              }

              span {
                font-weight: 600;
                font-size: 14px;
                color: $mainColor;
              }

              img {
                height: 14px;
                margin-right: 5px;
              }

              .btnClose--icon {
                @media screen and (max-width: 768px) {
                  margin-right: 0 !important;
                }
              }
            }
          }
          span {
            .student--title--box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .student--title {
                margin: 24px 0 16px;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                // text-transform: capitalize;
              }
              .student--status {
                display: flex;
                justify-content: center;
                height: 25px;
                width: 25px;

                &--icon {
                  justify-content: center;
                  align-items: center;
                  display: flex;
                }
  
                span.student--status--icon.check {
                  top: 3px;
                  img {
                    width: 15px;
                    height: 10px;
                    color: green;
                  }
                }
  
                span.student--status--icon.dot {
                  img {
                    left: 6px !important;
                    top: 0px !important;
                  }
                }
                span {
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
  
                  img {
                    position: relative;
                    left: 4px;
                    top: 3px;
                  }
                }
              }
            }
  
            .student--description {
              font-family: 'Cabin';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              // text-transform: uppercase;
              letter-spacing: 0.1em;
              color: #000000;
              margin: 0;
            }
            .student--text {
              font-family: 'Cabin';
              font-style: normal;
              margin-top: 16px;
              margin: 24px 0;
              font-weight: 400;
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  

  @media screen and (max-width: 768px) {
    .student {
      &--title--box {
        align-items: unset !important;
      }
      &--status {
        position: relative;
        top: 26px;
      }
      .student--status--icon {
        width: 25px !important;
        height: 25px !important;
      }

      &--status span img {
        position: unset !important;
      }

      .clock {
        padding: 0;
        display: flex; 
        justify-content: center;  
        align-items: center;
      }
    }
  }
  