.group--card {
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    overflow: hidden;

    &--image--container {
        // overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 220px;
        background: #D9F1F6;
        background-position:center center;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        background-repeat: no-repeat;
        overflow: hidden;

        .icon {
            width: 62px;
        }
    }

    &--image {
        width: 110%;
        // height: 110%;
        //object-fit:cover;
    }

    &--content--container {
        padding: 16px 16px 12px;
    }

    &--title {
        font-family: 'Cabin';
        font-weight: 500;
        font-size: 16px;
        color: #00819D;
        margin: 0;
        margin-bottom: 8px;
    }

    &--bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--left {
            display: flex;
            flex-direction: column;

            span {
                display: flex;
                
                img {
                    margin-right: 4px;
                }
            }

            .private {
                text-transform: capitalize;
            }

            p {
                margin: 0;
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                
            }
        }

        &--pending {
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 12px;
            color: #00819D;
            margin: 0;
            margin-top: -10px;
        }

        &--right {
            display: flex;
            align-items: center;

            img {
                height: 15px;
                margin-left: 16px;
            }

            .happy--icon {
                height: 17px;
            }
        }
    }
}