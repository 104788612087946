.browse {
  margin-bottom: 64px;
  h2 {
    margin-left: 16px;
  }
  a {
    font-family: $headerFont !important;
  }
  .top-categories {
    margin-bottom: 32px;
    .top-categories-link {
      background-color: #144CC7;
      margin: 4px 16px;
      height: 44px;
      width: 100%;
      box-shadow: 0px 2px 0px #0A1E41;
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      a {
        color: #fff;
        text-decoration: none;
        font: normal normal bold 13px/16px Roboto;
        letter-spacing: 0.03px;
      }
    }
  }
  .browse-filter {
    height: 56px;
    padding: 4px 16px;
    margin-top: 130px;
    .filter-container {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #29292938;
      border-radius: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      .filter-left {
        display: flex;
        align-items: center;
        .input-field {
          background-color: #fff;
          border: none;
          outline: none;
          width: 100%;
          height: 36px;
          font-size: 18px;
          letter-spacing: 0px;
          color: #000000;
          opacity: 0.4;
          font-family: $headerFont !important;
        }
      }
    }
  }
  .cards {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    column-gap: 16px;
    margin: 0 auto;
    padding: 0 16px 16px;
    .card {
      box-shadow: 0px 9px 15px #8690A538;
      border: 1px solid #EDF0F7;
      padding: 0;
      margin: 0px 0 16px;
      border-radius: 10px;
      overflow: hidden;
      background: #fff;
      .description {
        margin: 16px 8px;
        p {
          color: #144CC7;
          font-weight: bold;
          font-size: 12px;
          margin: 0;
        }
        h6 {
          margin: 0;
          font-weight: normal;
          font: normal normal normal 14px/21px Roboto;
        }
      }
    }
  }
}