.creds-summary {
  display: flex;
  align-items: center;
  padding: 0;

  .cred-summary-title {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
}

@media screen and (min-width: 1020px) {
  .creds-summary {
    padding: 0 4rem 1rem 0.5rem;
  }
}
