    .react-video {
        background:#D9F1F6;
        display: flex;
        justify-content: center;

        video {
            height: 287px !important;
            padding: 0 !important;

            @media screen and (max-width: 900px) {
                height: 200px !important;
            }
        }

        .video-react .video-react-control:before, .video-react .video-react-big-play-button:before {
            position: relative;
            
        }

        .video-react-progress-control {
            background: rgba(23, 35, 34, 0.75);
            border-radius: 4px;
            height: 32px !important;
            display: flex;
            align-items: center;
            position: relative;
            top: -25px;
            min-width: unset !important;
            width: 100% !important;
            margin-right: 16px !important;
        }

        .video-react-control-bar {
            background: transparent !important;
            padding: 8px !important;
        }

        .video-react .video-react-play-progress {
            background-color: $mainColor !important;
            height: 6px !important;
        }

        .video-react .video-react-control:before {
            font-size: 3em;
        }

        .video-react-big-play-button:before {
            top: -1px !important;
        }

        .video-react-button:before {
            top: -4px !important;
        }

        .video-react-button {
            top: unset !important;
                left: 8px !important;
                bottom: 34px;
                background: #00819D !important;
                border-radius: 4px !important;
                opacity: 1 !important;
                border: none !important;
                width: 65px !important;
                height: 40px !important;
                padding: 0;
                display: flex;
                justify-self: center;
                align-items: center;
                margin-right: 16px !important;


        }

        .video-react-progress-holder {
            border: 1px solid #898F8F;
            padding: 2px;
        }

        .video-react .video-react-progress-holder {
            padding: 2px;
        }

        .video-react .video-react-progress-holder .video-react-play-progress, .video-react .video-react-progress-holder .video-react-load-progress, .video-react .video-react-progress-holder .video-react-tooltip-progress-bar, .video-react .video-react-progress-holder .video-react-load-progress {
            height: 6px;
        }
        
        .video-react-fullscreen-control,
        .video-react-volume-menu-button,
        .video-react-duration,
        .video-react-current-time,
        .video-react-duration-display,
        .video-react-time-divider,
        .video-react-play-progress:before {
            display: none;
            width: 0 !important;
        }

        .video-react {
            margin: 0;
            padding: 0 !important;
            height: 287px !important;

            @media screen and (max-width: 900px) {
                height: 200px !important;
            }

            .video-react-big-play-button {
                top: unset !important;
                left: 16px !important;
                bottom: 16px;
                background: #00819D !important;
                border-radius: 4px !important;
                opacity: 1 !important;
                border: none !important;
                width: 65px !important;
                height: 40px !important;
                padding: 0;
                display: flex;
                justify-self: center;
                align-items: center;

                .video-react-control-text {
                    display: none;
                }
            }
        }
    }

@media screen and (max-width: 600px) {
    .react-video .video-react .video-react-big-play-button,
    .video-react-button,
    .video-react .video-react-play-control {
        border-radius: 50% !important;
        width: 33px !important;
        height: 33px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-video .video-react-big-play-button:before {
        top: -6px !important;
        height: 100% !important;
        font-size: 23px;
    }

    .react-video .video-react .video-react-button:before {
        font-size: 23px;
        top: -2px !important;
    }

    .video-react .video-react-play-control {
        margin-top: 8px !important;
    }

    .video-react .video-react-play-control:before {
        font-size: 22px !important;
    }
}

.react-video {
    .video-react .video-react-slider {
        background: rgba(115, 133, 159, 0) !important;
    }

    .video-react-load-progress {
        height: 6px !important;
        margin-top: 1px;
        width: calc(100% - 2px) !important;
        margin-left: 1px;
    }

    .video-react-play-progress {
        margin-top: 1px;
        max-width: calc(100% - 2px) !important;
        margin-left: 1px;
        height: 6px !important;
    }
    
    .video-react .video-react-load-progress div {
        height: 6px !important;
    }

    .video-react-play-progress,
    .video-react-load-progress {
        margin-top: 1px !important;
        margin-left: 1px !important;
    }

    .video-react-load-progress,
    .video-react .video-react-progress-holder {
        height: 10px;
    }

    .video-react .video-react-progress-holder {
        border-radius: 2px;
    }

    .video-react .video-react-progress-control {
        padding: 0 8px;
        // border-radius: 5px;
    }

    @media screen and (min-width: 900px) {
        // .video-react-button {
        //     bottom: 32px !important;
        // }
    }

}

.bubles {
    height: 6px;
    width: 1px;
    background: #fff;
    position: absolute;
    top: 1px;
    margin-left: -1px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .drop {
    position: absolute;
    top: -17px;
    margin-left: -5px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .video-react .video-react-time-tooltip, .video-react .video-react-mouse-display:after, .video-react .video-react-play-progress:after {
      background-color: #fff !important;
      color: $mainColor !important;
      font-family: $mainFont !important;
      font-size: 12px !important;
      height: 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      width: 40px;
      top: -2.5em !important;
  }

  .video-react-play-progress:before {
    content: "";
    position: absolute !important;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    z-index: 99;
  }