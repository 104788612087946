.dashboard--overview {
    height: 100%;

    // notifications

    .dashboard-notifications {
        margin-top: 32px;

        ul {
            li {
                background: #FFFFFF;
                box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.12);
                border-radius: 8px;
                padding: 16px 48px 16px 16px;
                margin-bottom: 16px;
                display: flex;
                position: relative;

                p {
                    font-family: 'Cabin';
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    margin: 0;
                    line-height: 1.7;
                }

                .pink-dot {
                    position: absolute;
                    right: 12px;
                    top: -4px;
                    width: 8px;
                    height: 8px;                 
                    background: #DF187D;
                    border-radius: 50%;
                }

                img {
                    position: absolute;
                    right: 8px;
                    bottom: 8px;

                    @media screen and (max-width: 968px) {
                        height: 37px;
                        width: auto;
                        right: 8px;
                        bottom: 8px;
                    }
                }
            }
        }
    }

    // weekly
    .weekly {
        margin-top: 32px;
            .MuiLinearProgress-barColorPrimary {
                background-color: #008A17;;
                border-radius: 10px;
              }
              
              .MuiLinearProgress-colorPrimary {
                background-color: rgba(0, 138, 23, 0.1);
                height: 10px;
                border-radius: 10px;
              }
        span {
            display: flex;
            justify-content: space-between;

            p {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 12px;
                color: #000000;

                @media screen and (max-width: 968px) {
                    font-size: 12px;
                }
            }
        }
    }

    // upcoming 
    .amazing {
        background: #FFFFFF;
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        padding: 16px 32px 16px 16px;
        margin-top: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            width: 80%;
            color: #000;
        }
        
        &-top {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
        }

        &-bottom {
            font-family: 'Cabin';
            font-size: 15px;
        }

        .plane-background {
            border-radius: 50%;
            background: rgba(223, 24, 125, 0.1);
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // upcoming milestones {
        .upcoming-milestones {
            margin-top: 32px;

            ul {
                background: #FFFFFF;
                box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.12);
                border-radius: 8px;
                padding: 8px 40px;

                @media screen and (max-width: 968px) {
                    padding: 8px 24px;
                }

                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 16px 0;

                    .date {
                        font-family: 'Cabin';
                        font-weight: 600;
                        font-size: 14px;
                        color: #888888;
                        width: auto;
                    }

                    .markers {
                        width: 36px;
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 8px;
                    }

                    .marker {
                        width: 4px;
                        height: 33px;
                        margin: 0 2px;
                    }

                    .marker-one {
                        background: #DF187D;
                    }

                    .marker-two {
                        background: #FF891B;
                    }

                    .marker-three {
                        background: #00819D;
                        
                    }

                    .milestone-name {
                        width: 70%;

                        @media screen and (max-width: 968px) {
                            width: 70%;
                        }
                    }

                    .lesson-title {
                        font-family: 'Cabin';
                        font-weight: 400;
                        font-size: 11px;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: #888888;
                    }

                    .lesson-number {
                        font-family: 'Cabin';
                        font-weight: 600;
                        font-size: 12px;
                        color: #222222;
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            button {
                margin-top: 32px;
                width: 100%;
                height: 36px;
                border: 1px solid #00819D;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 0.01em;
                color: #00819D;
                background: transparent;

                @media screen and (max-width: 968px) {
                    margin-top: 24px;

                }
            }
        }

    .weekly-title-mobile {
        @media screen and (max-width: 968px) {
            letter-spacing: 0 !important;
            font-weight: 700 !important;
            
        }
    }

    .weekly-title {
        font-family: $mainFont;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-top: 0;
        color: #000;

        @media screen and (max-width: 968px) {
            text-transform: capitalize;

        }
    }
    .enroll {
        margin-top: 48px;
        background: #F1F4F8;
        border-radius: 5px;

        ul {
            padding: 0 8px;
        }
    }

    &-recommended.course {
        padding: 0 !important;

        h1 {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            color: #000000;
            margin-left: 32px;
            margin-top: 20px;
            margin-bottom: 28px;
        }

        li {
            height: 255px !important;
            padding: 0 8px !important;

            .subtitle {
                span {
                    font-size: 15px !important;
                }
            }

            .card-full-content {
                padding-bottom: 0 !important;

                .details {
                    margin-top: 24px;
                }
            }

            .box-info {
                // justify-content: center !important;
               button {
                   margin-bottom: 34px;
                   height: 30px !important;
                   width: 113px !important;
               }
            }
        }

        .card-full-content .subtitle-container {
            margin-right: 0 !important;
        }
    }

    &--time {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #000000;

        @media screen and (max-width: 968px) {
            margin-bottom: 5px !important;
        }
    }

    &--name {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }

    &-goals {
        display: flex;
        margin-bottom: 32px;
        width: 100%;

        .goal-sliders {
            display: flex;
            flex-direction: column;
            flex: 1;

            &--label {
                margin: 0;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 14px;
                color: #888888;
            }

            .MuiSlider-mark {
                height: 5px;
                width: 5px;
                border-radius: 50%;
                margin-left: -2px;
                background: #00677E;
                opacity: 1;
            }

            .MuiSlider-track,
            .MuiSlider-rail {
                height: 5px;
            }

            h1 {
                margin: 0;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 16px;
                color: #000;
            }

            .MuiSlider-thumb {
                height: 14px;
                width: 14px;
            }

            .MuiSlider-mark,
            .MuiSlider-thumb {
                background: #00677E !important;
            }
            
            .MuiSlider-root {
                color: rgba(0, 129, 157, 0.5);
                margin-bottom: 32px;
            }

            // slider label
            .MuiSlider-markLabel {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 12px;
                color: #94A3B8;
            }
        }
    }

    &--learning-card {
        width: 186px;
        height: 186px;
        background: #FCFDFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 32px;

        span {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(223, 24, 125, 0.1);
            width: 44px;
            height: 44px;

            @media screen and (max-width: 968px) {
                height: 58px;
                width: 58px;

                img {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        p {
            margin: 0; 

            @media screen and (min-width: 968px) {
                text-align: center;
            }
        }
    }

    &-header {
        display: flex;
        flex-direction: column; 

        .username {
            text-transform: capitalize;
        }

        h1 {
            font-family: 'Cabin';
            font-weight: 700;
            font-size: 30px;
            color: #000000;
            margin: 0 0 8px;

            @media screen and (min-width: 968px) {
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;
            }
        }

        p {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            margin: 0;
            width: 80%;
        }
    }

    &-heading {
        display: flex;
        margin-bottom: 32px;
    }

    &-left {
        padding: 32px 48px 32px 70px;
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        margin-left: auto;

        @media screen and (max-width: 968px) {
            margin: 0 auto;
            width: 100%;
        }

        @media screen and (min-width: 968px) and (max-width: 1200px) {
            padding: 32px 32px 48px;
        }
    }

    &-right {
        padding: 32px;
        height: 110%;
    }

    .dashboard--summary {
        display: flex;

        &-card {
            width: 157px;
            height: 154px;
            background: #FCFDFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin-left: 6px;

            &-icon {
                height: 32px;

                @media screen and (max-width: 968px) {
                    .education {
                        width: 20px;
                        margin-top: 4px;
                    }

                    .stars {
                        width: 22px;
                    }

                    .rainbow {
                        width: 26px;
                        margin-top: 4px;
                    }
                }
            }

            p {
                margin: 0;
                font-family: 'Cabin';
                color: #000000;
            }

            &-days {
                font-weight: 600;
                font-size: 16px;
                margin: 4px 0 12px !important;
            }

            &-name {
                font-size: 10px;
            }
        }
    }

    // table {
    //     width: 100%;
    //     border-collapse: collapse;
    //     border-radius: 5px;
    // }

    // table, td, th {
    //     border: 1px solid #CCCCCC !important;
    // }

    table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
      }

      table tr th,
      table tr td {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        position: relative;
      }
      
      table tr th:first-child,
      table tr td:first-child {
        border-left: 1px solid #ccc;
      }
      table tr th:first-child,
      table tr td:first-child {
        border-left: 1px solid #ccc;
      }
      table tr th {
        background: #eee;
        text-align: left;
        border-top: solid 1px #ccc;
      }
      
      /* top-left border-radius */
      table tr:first-child th:first-child {
        border-top-left-radius: 5px;
      }
      
      /* top-right border-radius */
      table tr:first-child th:last-child {
        border-top-right-radius: 5px;
      }
      
      /* bottom-left border-radius */
      table tr:last-child td:first-child {
        border-bottom-left-radius: 5px;
      }
      
      /* bottom-right border-radius */
      table tr:last-child td:last-child {
        border-bottom-right-radius: 5px;
      }

      table td {
        padding: 0 12px !important;
    }

    table tr:nth-child(odd) {
      background-color: #F1F4F8;
    }

    table td {
        height: 95px !important;
    }

    table .milestone {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #00819D; 

        p {
            margin-left: 24px;
        }

        .color-code {
            width: 4px;
            height: 33px;
            display: block;
            position: absolute;
            margin-top: 12px;
        }

        .color-pink {
            background: #DF187D;
            // border-left: 4px solid #DF187D;
        }

        .color-orange {
            // border-left: 4px solid orange;
            background: orange;
        }

        .color-main {
            // border-left: 4px solid $mainColor;
            background: $mainColor;
        }
    }

    table {
        .deadline {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.01em;
            color: #888888;
            text-align: center;
        }

        .progress {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #000000;
            text-align: center;

            .MuiBox-root {
                margin-bottom: 4px;
            }
        }

        .progress.yellow {
            .MuiLinearProgress-barColorPrimary {
                background-color: #EDC902;
                border-radius: 10px;
              }
              
              .MuiLinearProgress-colorPrimary {
                background-color: rgba(237, 201, 2, 0.3);;
                height: 8px;
                border-radius: 10px;
              }
        }

        .progress.green {
            .MuiLinearProgress-barColorPrimary {
                background-color: #008A17;;
                border-radius: 10px;
              }
              
              .MuiLinearProgress-colorPrimary {
                background-color: rgba(0, 138, 23, 0.1);
                height: 8px;
                border-radius: 10px;
              }
        }

        .status {
            display: flex;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            justify-content: space-between;
            align-items: center;

            .status-icon {
                display: flex;
                
                span {
                    margin-right: 12px;
                }
            }

            .status-edit {
                width: 14px;
                height: 14px;
            }

            .status-dot {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #EDC902;
                width: 26.56px;
                height: 26.56px;
            }

            .status-check {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #008A17;
                width: 26.56px;
                height: 26.56px;
            }
        }
    }

    // milestone table
    .milestone--table {
        &-header {
            background: #E2E6EC;
            height: 65px;
            
            th {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 20px;
                color: #000000;
                border: 1px solid #cccccc;
                width: 25%;
                text-align: center;
                padding: 12px 0;
            }
        }
    }
}


// .yellow {
//     background: #EDC902;
// }

// .green {
//     background: #008A17;
// }

// .pink {
//     background: #DF187D;
// }

// .orange {
//     background: orange;
// }