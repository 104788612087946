.resource-edit-modal-nav {
    position: relative !important;
    background: rgba(28, 165, 186, 0.767) !important;
    textarea {
      height: 300px !important;
    }
    .title {
      position: absolute;
      left: 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
    }
    .close-icon {
      z-index: 10;
    }
    .close-icon svg {
      background: #fff;
      border-radius: 5px;
      padding: 3px;
    }
    .close-icon svg.MuiSvgIcon-root {
      color: black !important;
    }
  }
  
  .resource-edit span.MuiButton-contained {
    background: #FF891B !important;
    color: #fff;
    font-weight: bold;
    padding: 4px 18px;
    .paperclip {
      width: 10px;
      margin-right: 8px;
    }
  }
  
  .save-resource-edit {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      width: 100%;
      background: #25C522;
      font-weight: bold;
      color: #fff;
    }
  }
  
  #outlined-full-width-label {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  ::placeholder {
    font-size: 14px;
  }