@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

.secondary--modal--overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    animation: fadeIn linear 0.1s;
    -webkit-animation: fadeIn linear 0.1s;
    -moz-animation: fadeIn linear 0.1s;
    -o-animation: fadeIn linear 0.1s;
    -ms-animation: fadeIn linear 0.1s;
    display: flex;
    justify-content: flex-end;
    color: #000;

    .close-card {
        background: #EEEEEE;
        border-radius: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 40px;
        margin-bottom: 12px;

        img {
            height: 10px;
            width: 10px;
            margin-right: 8px;
        }

        .desktop-x {
            display: none;
        }

        .close-text {
            font-family: $mainFont;
            display: flex;
            color: #00819D;
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: none !important;
            margin: 0;
        }
    }

    .secondary--modal--content {
        padding-top: 65px;
        color: #000;

        h1 {
            font-family: 'Cabin';
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 24px;
        }

        hr {
            opacity: 0.5;
        }

        .user {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .avatar {
                height: 48px;
                width: 48px;
                background: #00819D;
                @include flexbox();
                border-radius: 50%;
                color: #fff;
                font-family: 'Cabin';
                font-weight: 700;
                font-size: 24px;
                text-transform: uppercase;
                overflow: hidden;

                img {
                    width: 48px;
                    height: auto;
                }
            }

            p {
                margin: 0;
                margin-left: 24px;
            }

            &--name {
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 16px;
                color: #00819D;
            }
    
            &--type {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 12px;
                color: #666666;
            }
        }

        .create--groups {
            padding: 32px 0;
            label {
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                color: #000000;
            }

            canvas {
                display: none;
            }

            .title--upload {
                margin-top: 0 !important;
            }
            
            .upload--file--container {
                display: flex;
                width: 100%;
            }

            .upload--file--input {
                width: 100%;
            }

            .tags {
                margin-right: 6px;
            }

            .tags--text {
                font-size: 14px;
            }

            .privacy {
                padding: 16px 16px 0;
            }

            .privacy--container {
                margin-top: 12px;
                display: flex;

               p {
                margin: 0;
                margin-left: 8px;
                margin-right: 32px;
                }

               .radio-button { 
                   input { 
                       margin-left: 0;
                   }
               }
            }
        }

        .invite--members {
            width: 100%;
            padding: 16px 16px 0;

            &--user-list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 12px;
       
                li {
                    display: flex;
                    align-items: center;
                    // border: 1px solid rgba(118, 118, 118, 0.5);
                    background: #EEEEEE;
                    border-radius: 55px;
                    margin-right: 6px;
                    padding: 0 12px 0 0;
                    margin-bottom: 6px;
                    p {
                        margin: 0;
                        font-family: $mainFont;
                        color: #666666;
                        font-size: 12px;
                    }

                    .avatar {
                        background: $mainColor;
                        width: 30px;
                        height: 30px;
                        margin-right: 8px;
                    }
                }
            }

            &--type {
                text-transform: uppercase;
                color: #00819D;
                margin-left: 24px !important;
                font-size: 10px !important;
            }

            &--input {
                height: 50px;
                padding: 0 12px;
                margin-bottom: 12px;
                font-size: 16px;
                width: 100%;
                border: 1px solid #CCCCCC;
                border-radius: 5px;
            }

            &--container {
                background: #EEEEEE;
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding: 12px;
                height: 55px;
                margin-bottom: 12px;

                span {
                    background: #00819D;
                    height: 40px;
                    width: 40px;
                    @include flexbox();
                    color: #fff;
                    font-family: 'Cabin';
                    font-weight: 700;
                    font-size: 20px;
                    text-transform: uppercase;
                    border-radius: 50%;
                    margin-right: 24px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-position:center;
                    -webkit-background-size:cover;
                    -moz-background-size:cover;
                    -o-background-size:cover;
                    background-size:cover;
                    background-repeat: no-repeat; 
                    
                    img {
                        height: 60px;
                        width: auto;
                    }
                }

                p {
                    margin: 0;
                    font-family: 'Cabin';
                    font-weight: 500;
                    font-size: 16px;
                    color: #00819D;
                }
            }
        }
    }

    .secondary--modal--button--section {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: #EEEEEE;
        //box-shadow: 10px 0px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 50px;

        button {
            border: 1px solid $mainColor;
            border-radius: 5px;
            height: 100%;
            width: 250px;
            font-weight: 600;
            font-size: 15px;
            font-family: $mainFont;
            letter-spacing: 1px;
            color: #fff;
            background: $mainColor;
            text-transform: none !important;
            border: none;
        }

        .progress--bar {
            margin-right: 16px;
            margin-top: 6px;

            .MuiBox-root {
                text-align: right;
            }

            .MuiLinearProgress-colorPrimary {
                background-color: #00809d44;
            }
        }
    }

    @media screen and (min-width: 900px) {
        .secondary--modal {
            top: 0;
            height: 100%;
            width: 50%;
            background: #fff;
            right: 0;
            width: 50vw;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            position: flex;
            padding: 50px 72px;
            position: relative;

            &--content {
                // max-width: 581px;
                height: 92vh;
                overflow-y: auto;
                overflow-x: hidden;
                left: 0;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none; 
                padding-bottom: 72px;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none; 
            }

            &--content::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
                background-color: transparent;
              }
              
              &--content::-webkit-scrollbar {
                width: 0;
                background-color: transparent;
              }

            .close-card {
                background: $mainColor;
                border-radius: 50%;
                height: 50px !important;
                width: 50px !important;
                min-width: unset !important;
                position: absolute;
                left: -25px;
                top: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    margin-right: 0;
                }

                .desktop-x {
                    display: block;
                    width: 14px;
                    height: 14px;
                }

                .mobile-x,
                .close-text {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        z-index: 999;
        padding: 12px 24px;
        background: #fff;
        overflow-y: scroll;
        overflow-x: hidden;
        min-height: 100%;
        height: 100%;
        
        .secondary--modal--content {
            padding-top: 12px !important;
            padding-bottom: 56px;

            .MuiGrid-spacing-xs-4 > .MuiGrid-item {
                padding: 8px 16px;
            }

            .upload span.MuiButton-contained {
                width: 184px;
            }
        }

       .close-card {
           background: #EEEEEE;
           width: 100%;

           .MuiButton-label {
            display: flex;
            align-items: center;
            justify-content: center;
        }

           
        .desktop-x {
            display: none;
        }
       }

       .privacy--container {
        margin-top: 8px !important;
        }
    }

    @media screen and (max-width: 900px) {
        .secondary--modal--button--section {
            position: fixed;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0;

            .progress--bar {
                margin-right: 0;
                margin-top: 0;
                position: absolute;
                top: 0;

                p {
                    display: none;
                }
    
                .MuiBox-root {
                    text-align: center;
                }
    
                .MuiLinearProgress-colorPrimary {
                    background-color: #eee;
                    border-radius: 0;
                    height: 4px;
                }
            }
        }
    }
}

.modal--add {
    width: 10px;
    height: 10px;
    margin-right: 8px;
}

.create--group--text {
    font-size: 15px;
}