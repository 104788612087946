.btn {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $mainFont;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
  background: $mainColor;
  border-radius: 5px;

  @media screen and (min-width: 900px) {
    font-size: 15px;
  }
}

.primary-button {
  width: 100%;
  font-size: 16px;

  @media screen and (min-width: 900px) {
    width: 250px;
  }
}

.secondary-button {
  width: 100%;

  @media screen and (min-width: 900px) {
    width: 150px;
  }
}

.info-btn {
  height: 40px !important;
}

.btn-white {
  color: #00819d;

  width: 200px;
  height: 40px;
  border: 1px solid #00819d;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-blue {
  color: white !important;
  width: 200px !important;
  height: 40px !important ;
  background: #00819d !important;
  border-radius: 5px !important;
  border-color: transparent !important;
  font-weight: 600 !important;
  text-transform: none !important;
  font-family: $mainFont !important;
}
