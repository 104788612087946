.resource-delete-modal {
  .MuiTypography-h6 {
      font-weight: bold;
  }

  .MuiTypography-colorTextSecondary {
      color: black !important;
  }

  .cancel-button {
    color: #666666;
    font-size: 1rem;
    font-weight: bold;
    background-color: white;
    border-color: #c5facf;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    height: 2.5rem !important;
    width: 8rem !important;
    margin: 0 auto 1rem auto;
    // float: left !important;
    // margin: 0.5rem 0.25rem 0rem 0.25rem !important;
  }

  .confirm-button {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    background-color: #00961a;
    border-color: #c5facf;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    height: 2.5rem !important;
    width: 8rem !important;
    margin: 0 auto 1rem auto;
    // float: left !important;
    // margin: 0.5rem 0.25rem 0rem 0.25rem !important;
  }

  }
