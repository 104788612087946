.hide--colHeaderName {
  color: transparent;
  cursor: not-allowed;
  pointer-events: none;
  border-left: none !important;
  border-right: none !important;
}
.react--table {
  width: 85%;
  margin: auto;
  border-collapse: collapse;

  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }

  th {
    height: 60px;
    border: 1px solid #cccccc;
    border-top: none !important;
    cursor: pointer;

    .table--colHeaderBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #000;
    }

    span {
      text-align: right;
    }
  }

  th:last-child {
    border-right: none !important;
  }
}
