.dashboard {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &--overview {
    display: flex;
    justify-content: space-between;

    .dashboard--mobile-view {
      display: none;
    }

    &-right {
      width: 30vw;
      min-height: 100vh;
      background: #eeeeee;
    }
  }

  .dashboard-logo {
    img {
      width: 75px;
      height: auto;
    }
  }

  &--timeline {
    margin-top: 0;
    height: 100%;
    margin-bottom: 48px;
    padding: 0 .7rem;
  }

  &--progress {
    &-title {
      display: flex;
      justify-content: flex-end;
      flex-direction: center;
      flex-wrap: wrap;
      padding-top: 0.7rem;

      @media screen and (max-width: 968px) {
        padding: 16px 0;
        justify-content: space-between;
      }
    }

    .select-milestone-text {
      .MuiSelect-select {
        color: #888888 !important;
      }
    }

    &-action-buttons {
      display: flex;

      @media screen and (max-width: 968px) {
        padding: 0 24px 16px;
      }

      button {
        background: #ffffff;
        border: 1px solid #00819d;
        border-radius: 5px;
        color: $mainColor;
        width: 48%;
        height: 40px;
        margin-right: 8px;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #00819D;

        @media screen and (max-width: 968px) {
          margin-right: 0;
        }
      }

      .MuiSelect-select:focus {
        background: transparent !important;
      }

      .MuiSelect-select {
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.01em;
        color: #00819D;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
        background: transparent;
      }

      .MuiInputBase-root {
        width: 50%;
        height: 40px;
        border: 1px solid #00819d;
        border-radius: 5px;

        svg {
          fill: $mainColor;
        }
      }
    }
  }
}


@media screen and (min-width: 968px) {
  .dashboard {
    height: calc(100vh - 64px);

    &--timeline {
      margin-top: 48px;
      padding: 0;
    }

    &--progress {
      padding: 32px 3rem;

      &-action-buttons {
        button {
          width: 200px;
        }

        .MuiInputBase-root {
          width: 230px;
        }
      }
    }
  }
}

.dashboard--menu {
  margin-top: -4px !important;
}

.filter--menu {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
  background: #fff;
}

.MuiMenu-list {
  height: auto !important;

  li {
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
}

@media screen and (max-width: 968px) {
  .page-title {
    order: 2;
    padding: 0 0.7rem 0rem !important;
  }

  .dashboard--progress-action-buttons {
    justify-content: space-between;
    width: 100%;
    opacity: 0.9;
  }
}

@media screen and (max-width: 968px) {
  .dashboard {
    &--tabs {
      .coach--tabs {
        margin-left: 0 !important;
        height: 60px !important;
      }

      header {
        display: block !important;
        height: 60px !important;
      }

      .MuiTabs-flexContainer {
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
      }
    }

    &--summary {
      justify-content: space-between;
      margin-top: 32px;
    }

    &--summary-card {
      width: 32% !important;
      height: auto !important;
      padding: 12px 0;
      margin-left: 0 !important;

      &-days {
        font-size: 14px !important;
      }

      &-name {
        font-size: 8px !important;
      }
    }

    &--overview {
      flex-direction: column;

      &--time {
        font-size: 20px !important;
      }

      &--name {
        font-size: 15px !important;
      }

      .weekly-title {
        font-weight: 700;
        font-size: 16px !important;
        color: #222222;
      }

      .enroll {
        margin-top: 16px !important;
      }

      .gray-background {
        background: #EEEEEE;
      }

      .dashboard--mobile-view {
        display: flex;
        width: 100%;
        // justify-content: space-between;
        align-items: center;
        padding: 16px;
        // height: 50px;
        // position: absolute;

        .summary-button {
          background: #fff;
          height: 36px;
          width: 100%;
          border: 1px solid #00819D;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $mainColor;
          font-family: 'Cabin';
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 0.01em;
        }
      }
      .calendar-icon {
        margin-left: auto;
        margin-right: 3px;
      }

      &-recommended.course h1 {
        margin-left: 8px !important;
        font-size: 16px !important;
      }

      &-recommended.course li .card-full-content .details {
        margin-top: 0 !important;
      }

      &--learning-card {
        width: 100% !important;
        margin-right: 0 !important;
        height: 94px !important;
        flex-direction: row !important;
        // justify-content: flex-start !important;

        span {
          margin-right: 32px;
        }

        div {
          text-align: center;
        }
      }

      &-left {
        padding: 0 16px 16px !important;
      }

      &-header {
        h1 {
          font-size: 24px !important;
        }

        p {
          font-size: 16px !important;
        }
      }

      &-heading {
        flex-direction: column;
        margin-bottom: 16px !important;
      }

      &-goals {
        flex-direction: column;
        margin-bottom: 0 !important;

        .reset-goal {
          margin-top: 16px;
          margin-bottom: 16px;
          width: 100%;
          height: 40px;
          border: 1px solid #00819d;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Cabin';
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.01em;
          color: #00819d;
          background: transparent;
        }

        .goal-sliders h1 {
          display: none;
        }
      }

      .milestone {
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 5px;
        margin-top: 16px;
        padding: 8px 32px 0 24px;
        display: flex;

        .milestone-right {
          width: 100%;

          .due-date {
            margin-bottom: 4px;
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.01em;
            color: #888888;
          }
          .progress-completed {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #000000;
          }

          .milestone-right-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .status-dot {
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #edc902;
              width: 20px;
              height: 20px;
              padding: 4px;

              img {
                width: 10px;
                height: auto;
              }
            }

            .status-check {
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #008a17;
              width: 20px;
              height: 20px;
              
              img {
                height: 8px;
                width: auto;
              }
            }
          }
        }

        .milestone-title {
          font-family: 'Cabin';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #00819d;
        }

        .color-code {
          width: 4px;
          height: 33px;
          display: block;
          margin-top: 12px;
          margin-right: 16px;
        }

        .color-pink {
          background: #df187d;
          // border-left: 4px solid #DF187D;
        }

        .color-orange {
          // border-left: 4px solid orange;
          background: orange;
        }

        .color-main {
          // border-left: 4px solid $mainColor;
          background: $mainColor;
        }

        .progress.yellow {
          .MuiLinearProgress-barColorPrimary {
            background-color: #edc902;
            border-radius: 10px;
          }

          .MuiLinearProgress-colorPrimary {
            background-color: rgba(237, 201, 2, 0.3);
            height: 8px;
            border-radius: 10px;
          }
        }

        .progress {
          text-align: right;
        }

        .progress.green {
          .MuiLinearProgress-barColorPrimary {
            background-color: #008a17;
            border-radius: 10px;
          }

          .MuiLinearProgress-colorPrimary {
            background-color: rgba(0, 138, 23, 0.1);
            height: 8px;
            border-radius: 10px;
          }
        }
      }

      .milestone--table {
        display: none;
      }

      &-right {
        width: 100%;
        padding: 0 16px 16px !important;
      }
    }
  }
}
