.close-button {
	display: flex;
	z-index: 5;
	
	svg {
		fill: black;
		padding: 4px;
		background: $white;
		border-radius: $border-radius-m;
		border: 1px solid $lightBlue; 
		height: 28px;
		width: 28px;
	}
}