.filter {
  padding: 8px 16px;
  background: #61C3D1;
  .filter-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #adadad38;
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    .filter-left {
      display: flex;
      align-items: center;
      .input-field {
        background-color: #fff;
        border: none;
        outline: none;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.4;
        font-family: $openSans !important;
        padding: 0 4px;
      }
      ::placeholder {
        color: black;
		opacity: 1;
		font-size: 18px;
		position: relative;
		top: -1px;
        /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
		color: black;
		opacity: 1;
		font-size: 18px;
		position: relative;
		top: -1px;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
		color: black;
		opacity: 1;
		font-size: 18px;
		position: relative;
		top: -1px;
      }
    }
  }
}

.checkbox--input input {
  padding: 0;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  // border-radius: 5px;
}

.checkbox--input {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
  }
}

.checkbox--input label {
  position: relative;
  font-family: 'Cabin';
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: #888888;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 1000px) {
    margin-bottom: 16px;
  }

  span {
    position: relative;
    top: 1px;
    
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}
.checkbox--input label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #888888;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
  // border-radius: 5px;
  width: 20px;
  height: 20px;
}

.checkbox--input input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid $mainColor;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}