@mixin fontFamilyCabin {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
}

@mixin displayFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {

}

// @media screen and (min-width: 769px) {
  .session--event__list {
    // padding: 1rem 5rem;
    margin-bottom: 48px;

    &__title {
      @include fontFamilyCabin;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #666666;
      margin-bottom: 16px;

    }
    .session--event__list__items {
      @include displayFlexCenter;
      justify-content: space-around;
      margin-bottom: 1rem;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 10px;
      padding: 24px 0;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
      }
      

      /**********  Box 1 ***********/
      .session--event--box.box-first__date-left {
        @include displayFlexCenter;
        justify-content: flex-start;
        flex: 0 1 20%;

        .past-event {
          background: #888888 !important;
        }

        &__inner-first-box {
          background: linear-gradient(270deg, #00819d 0%, #00677e 100%);
          border-radius: 5px;
          width: 60px;
          height: 60px;
          @include displayFlexCenter;
          flex-direction: column;

          p {
            margin: 0;
          }

          &__date-day {
            @include fontFamilyCabin;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.9375px;

            color: #ffffff;
          }

          &__date-month {
            @include fontFamilyCabin;
            font-size: 14px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 2px;
            text-transform: uppercase;

            color: #ffffff;
          }
        }

        &__inner-second-box {
          margin-left: 1rem;

          &__date-week-day,
          &__date-full-date {
            @include fontFamilyCabin;
            font-size: 20px;
            line-height: 22px;
            letter-spacing: -0.5px;
            color: #000000;
            margin: 0;
          }

          &__date-week-day {
            margin-bottom: 0.2rem;
          }
        }
      }
      /**********  Box 1 End ***********/

      /**********  Box 2 ***********/
      .session--event--box.box-second__session-type {
        display: flex;
        align-items: flex-start;
        flex: 0 1 26%;

        img {
          margin-top: 4px;
          height: 16px;
          width: 16px;
          cursor: pointer;
        }

        @media screen and (max-width: 768px) {
          margin-top: 24px;
          margin-bottom: 24px;
        }

        &__inner-second-box {
          margin-left: 1rem;

          p {
            margin: 0;
          }

          &__title {
            @include fontFamilyCabin;
            font-weight: 500;
            font-size: 20px;
            color: #00819d;
            margin-bottom: 8px !important;
            cursor: pointer;
            width: fit-content;
          }

          &__info-text {
            @include fontFamilyCabin;
            font-size: 14px;
            line-height: 17px;
            color: #666666;
          }
        }
      }
      /*********** Box 2 End ************/

      /*********** Box 3 Start ************/
      .session--event--box.box-third__date-right {
        display: flex;
        align-items: flex-start;
        flex: 0 1 18%;

        img {
          width: 16px;
          height: 19px;
        }

        &__inner-second-box {
          margin-left: 1rem;

          p {
            margin: 0;
          }

          &__date-full-date {
            @include fontFamilyCabin;
            font-weight: 700;
            font-size: 14px;
            line-height: 135%;
            letter-spacing: 0.1em;
            color: #000000;
            margin-bottom: 8px !important;
          }

          &__date-time {
            @include fontFamilyCabin;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #666666;
          }
        }
      }
      /*********** Box 3 End ************/

      /*********** Box 4 Start ************/

      .session--event--box.box-fourth__buttons {
        @include displayFlexCenter;
        flex-direction: column;
        flex: 0 1 18%;

        @media screen and (max-width: 768px) {
          width: 100%;
          align-items: flex-end;
          margin-top: 24px;
        }

        &__inner-first-box-btn-connect {
          @include fontFamilyCabin;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          background: #00819d;
          border-radius: 5px;
          width: 190px;
          height: 40px;
          border: 1px solid;
          margin-bottom: 8px;


          @media screen and (max-width: 768px) {
            width: 100%;
          }

          img {
            width: 17px;
            height: 11px;
            margin-right: 1rem;
          }
        }

        &__btn-notes {
          @include fontFamilyCabin;
          font-weight: 500;
          font-size: 14px;
          color: #00819d;
          background: transparent;
          border-radius: 5px;
          width: 190px;
          height: 40px;
          border: 1px solid #00819d;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

        }

        &__inner-second-box-btn-inPerson {
          @include fontFamilyCabin;
          font-weight: 700;
          font-size: 14px;
          line-height: 135%;
          letter-spacing: 0.1em;
          color: #000000;
          border: none;
          padding: 0;
          background: transparent;
          margin-bottom: 8px;
        }

        &__inner-second-box-btn-cancel {
          @include fontFamilyCabin;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #00819d;
          background-color: #ffffff;
          border: none;
          padding: 0;
        }
      }
    }
  }
// }
