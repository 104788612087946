.onboarding {
  .pop-up .pop-up-modal .pop-up-buttons .pop-up-button-two {
    width: 100%;
  }
}

.onboarding-intro {
  .primary-card .primary-card-container {
    padding-bottom: 0;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px !important;
    .onboarding-intro-top {
      margin-top: 40px;
      p {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .onboarding-intro-bottom {
      button {
        margin-top: 16px;
        background: $green;
        width: 100%;
        height: 40px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        letter-spacing: 0.5px;
      }
    }
  }
}