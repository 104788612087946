.lesson-card {
    min-height: 196px;
    width: 271px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;


    @media screen and (max-width: 768px) {
        width: 100%;
    }


    &_image {
        height: 140px;
        width: 271px;
        background-position: top center !important;
        -webkit-background-size:cover !important;
        -moz-background-size:cover !important;
        -o-background-size:cover !important;
        background-size:cover !important;
        background-repeat: no-repeat !important; 


        @media screen and (max-width: 768px) {
            width: 100%;
            height: 190px;
        }
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 8px;

        h1 {
            font-family: 'Cabin';
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            margin: 0;
        }

        button {
            width: 80px;
            height: 20px;
            background: #00819D;
            border-radius: 1.95833px;
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 10px;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            border: none;
        }
    }

    &_info {
        display: flex;
        align-items: center;
        padding: 0 8px 8px;

        img {
            width: 10px;
            height: 10px;

            margin-right: 4px;
        }

        p {
            font-family: 'Cabin';
            font-weight: 600;
            font-size: 10px;
            color: #00819D;
            margin: 0;
        }
    }

    &_information {
        margin-left: 32px;

        @media screen and (max-width: 768px) {
            margin: 0;
        }
        
        p {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 18px;
            letter-spacing: -0.25px;
            color: #000000;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        ul {
            display: flex;
        }

        li {
            padding: 4px 16px;
            height: 30px;
            background: rgba(14, 190, 216, 0.1);
            border: 1px solid #0EBED8;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 768px) {
                border-radius: 10px;
            }

            .circle-bullet {
                width: 10px;
                height: 10px;
                left: 418px;
                background: #0EBED8;
                border-radius: 50%;
                margin-right: 8px;
            }

            .milestone {
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 11px;
                text-transform: capitalize;
                color: #0EBED8;
                margin: 0;
                line-height: 1;

                @media screen and (max-width: 768px) {
                    font-size: 11px;
                }
            }
        }

        li:nth-child(even) {
            border: 1px solid #00819D;
            margin-left: 16px;

            @media screen and (max-width: 768px) {
                margin-left: 8px;
            }

            .circle-bullet {
                background: #00819D;
            }
            
            .milestone {
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 11px;
                text-transform: capitalize;
                color: #00819D;
            }
        }
    }
}

.lesson-card-li {
    align-items: flex-start;
    border-bottom: 1px solid #CCCCCC;
    padding: 20px 0 20px;
    position: relative;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &_top {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
            width: fit-content;
        }
    }

    &_dots {
        position: absolute;
        display: flex;
        top: -8px;
        right: 8px;

        @media screen and (max-width: 768px) {
            position: relative;
            top: 0;
            right: 0;
            margin-top: 8px;
            margin-left: auto;
        }

        span {
            width: 10px;
            height: 10px;
            background: #FFAE68;
            border-radius: 50%;
        }

        span:nth-child(even) {
            margin-left: 8px;
            background: #F87E0D;
        }
    }
}

.lesson-card-li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}