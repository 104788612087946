.fadeOut {
     opacity: 0;
     transition: opacity 0.1s;
     pointer-events: none;
}

.fadeIn {
     opacity: 1;
     transition: opacity 0.1s;
}

.show {
     @media screen and (max-width: 768px) {
          display: block;
     }
}

.hide {
     @media screen and (max-width: 768px) {
          display: none;
     }
}

.change-screen-btn {
     display: block;
     background: #FFFFFF;
     border: 1px solid #00819D;
     border-radius: 5px;
     width: 100%;
     height: 36px;
     font-family: 'Cabin';
     font-weight: 600;
     font-size: 15px;
     letter-spacing: 0.01em;
     color: #00819D;
     margin-bottom: 8px;

     img {
          margin-right: 16px;
     }

     @media screen and (min-width: 768px) {
          display: none;
     }
}