.secondary-header {
	position: fixed;
	width: 100%;
	z-index: 9999;
	
	.secondary-header-toolbar {
		background-color: #61C3D1;
		justify-content: space-between !important;
		display: flex;
		align-items: center;
	  
		.close-button {
			display: flex;
			z-index: 5;
			svg {
				fill: black !important;
				background: #fff;
				border-radius: 5px;
				height: 28px;
				width: 28px;
				padding: 4px;
				border: 1px solid #C4F1F7;
			}	
		}

		.title {
			position: absolute;
			left: 0;
			text-align: center;
			width: 100%;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
		}
		
		.button-text {
		  z-index: 5;
		  background: #46AB47;
		  border-radius: 5px;
		  color: #fff;
		  font-weight: bold;
		  font-size: 14px;
		  border: 1px solid $white;
		  padding: 6px 20px;
		  text-transform: uppercase;
		}
	  
		.button-transparent {
		  opacity: 0.7;
		}
	}
}