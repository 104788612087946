.feedback--tabs {
  height: 100%;

  .feedback--tab--notification {
    width: 8px;
    height: 8px;
    background: #df187d;
    border-radius: 50%;
    position: relative;
    top: -8px;
  }

  .pending-evaluation {
    padding: 0 25px;
  }

  margin-left: 0 !important;

  .MuiTabs-scrollable {
    display: flex;
    justify-content: center;
    margin-bottom: 32px !important;
  }
  .Mui-selected {
    background: rgba(0, 0, 0, 0.1) !important;
  }

  .rubric .rubric-container .accordian-container {
    padding: 0;

    .MuiAccordionSummary-content {
      p {
        color: $mainColor;
        font-family: 'Cabin';
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 1px;
      }
    }
  }

  .rubric .rubric-container .accordian-container .accordian {
    background: transparent;
    box-shadow: none;
    // border-bottom: 1px solid #CCCCCC;
    // padding: 14px 32px 14px 16px;
    padding-bottom: 14px;

    img {
      height: 14px;
      width: 14px;
    }
  }

  .rubric .rubric-container .accordian-container .accordian .MuiCollapse-container {
    padding: 0;
  }

  .rubric .rubric-container .accordian-container .accordian .inner-accordian {
    // border: none;
  }

  .rubric .rubric-container .accordian-container .accordian .MuiAccordionSummary-root {
    padding: 0 24px;
  }

  .rubric .rubric-container .accordian-container .accordian .description {
    background: transparent;
    padding: 0;
    .truncated-text {
      margin-top: 14px;
      border-top: 1px solid #cccccc;
      padding: 24px 24px 0;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .radio {
      input[type='radio']:after {
        width: 16px;
        height: 16px;
        border: 2px solid #bbbbbb;
      }
      input[type='radio']:checked:after {
        width: 16px;
        height: 16px;
        background-color: #284374;
      }
    }
    .radio-button {
      margin-left: 24px;
    }
    .option-text {
      padding: 0 24px 0 52px;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
  }

  .rubric .rubric-container .accordian-container .accordian .inner-accordian {
    padding: 8px 0;
  }

  .comments,
  .comment {
    background: transparent;
  }

  .comments {
    padding: 16px 24px;
  }

  .comments .comments-container .time-stamp {
    background: #00819d !important;
  }

  .rubric
    .rubric-container
    .accordian-container
    .accordian
    .Mui-expanded
    .MuiIconButton-label
    > img {
    background: transparent;
  }
}

.feedback--rubric--wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.feedack--tab---panels {
  height: calc(100% - 70px);
  // overflow-x: hidden;
}

@media screen and (max-width: 1024px) {
  // .feedback--container {
  //     flex-direction: column;
  // }
  .feedback {
    &--container {
    }

    &--resource {
      width: 55vw;
    }

    &--comments {
      width: 45vw;
    }

    .react-video .video-react,
    .react-video video {
      height: 200px !important;
    }
  }
}

@media screen and (max-width: 900px) {
  .feedback--container {
    flex-direction: column;
  }

  .feedback {
    &--resource {
      width: 100vw;
      padding: 32px 16px 48px;
    }

    &--comments {
      width: 100vw;
      height: auto !important;
      min-height: auto;
    }

    &--tabs {
      .coach--tabs {
        display: block !important;
      }
    }

    &--comment {
      padding: 0 16px !important;
    }
  }

  .student--feedback--comments--container {
    margin-bottom: 48px;
  }
}

.evaluation--tab,
.feedback--tab {
  min-height: 40vh;
}