.react--table {
  .coach--mobile--user {
    display: flex;
    justify-content: center;
    // gap: 2rem;
    // padding-top: 10px;
    // padding-bottom: 16px;
     padding: 0 32px;

    .MuiAvatar-root.MuiAvatar-circle.image {
      margin-right: 0;
    }

    .mobile--user--info {
      width: 100%;
      padding: 0 24px;
      .mobile--username,
      .mobile--title {
        margin: 0;
      }

      .mobile--username {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #284374;
      }

      .mobile--title {
        color: #00819d;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      .coach--view--text--mobile {
        margin: 4px 0 8px;
      }

      .coach--view--text--date {
        color: #666666;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .coach--status--container {
      .coach--status {
        margin-left: 0;
        width: 152px;
        font-weight: 400;
      }
    }
  }
}

.react--table.coach--mobile {
  width: 88% !important;

  th {
    .table--colHeaderBox {
      span {
        margin-left: 0;
      }
    }
  }

  .table--colHeaderBox {
    position: relative;
    top: 12px;
    padding-left: 0px !important;
  }
  td {
    padding: 20px 0 30px 0 !important;
  }
}
