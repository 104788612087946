.layout-with-side-bar {
    @media screen and (min-width: 968px) {
        display: flex;
    }

    &__main-content {
        width: 100%;

        &-container {
            padding: 16px 16px 90px;

            @media screen and (min-width: 768px) {
                padding: 0;
            }
        }

        @media screen and (min-width: 768px) {
            width: 100%;
            padding: 40px 32px 80px 90px;
        }

        &__title {
            font-family: 'Cabin';
            font-weight: 700;
            font-size: 24px;
            color: #000000;
            margin: 0;

            @media screen and (min-width: 768px) {
                font-size: 24px;
                margin-bottom: 12px;
            }
        }

        &__subtitle {
            font-family: 'Cabin';
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            margin: 8px 0 0;

            @media screen and (min-width: 768px) {
                font-size: 16px;
                margin: 8px 0 48px;

            }
        }

        .accordian {
            background: #E2E6EC;
            box-shadow: none !important;
            border-radius: 5px;
            border: 1px solid #CCCCCC;
            margin-bottom: 24px !important;
            overflow: hidden;

            .title-img {
                margin-right: 16px;

                @media screen and (max-width: 768px) {
                    height: 22px;
                }
            }

            .edit-img {
                height: 14px;
                width: 14px;
                margin-right: 8px;
            }

            .accordian-title {
                margin-left: 0;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 24px;
                color: #000000;
                margin: 0 16px 0 0;

                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
              }

              .edit-objective {
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 14px;
                color: #00819D;
                margin-right: 8px;
              }
          
            .MuiAccordionSummary-content {
                align-items: center;
                justify-content: space-between;
                width: 100%;

                span {
                    display: flex;
                    align-items: center;
                }    
            }

            .MuiAccordionSummary-root {
                padding-right: 24px;
            }
           
            .MuiAccordionSummary-content,
            .MuiAccordionSummary-content.Mui-expanded {
              margin: 0;
            }
          
            .MuiAccordion-root.Mui-expanded {
              margin: 0;
            }

            .MuiAccordion-root:before {
                border: none !important;
            }
          
            .accordian-summary {
              margin: 0;
              min-height: 0 !important;
              display: flex;
              align-items: center;
              height: 60px;

              .accordian-header {
                  width: 100%;
              }

              @media screen and (max-width: 768px) {
                  height: 50px;
              }

              @media screen and (max-width: 768px) {
                  .arrow-down {
                      width: auto;
                      height: 10px;
                  }
              }

              .support {
                @media screen and (max-width: 768px) {
                    height: 10px;
                }
              }
          
              .accordian-title {
                margin-left: 0;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 24px;
                color: #000000;

                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
              }
            }
          
            .accordian-details {
              flex-direction: column;
              padding: 24px 16px;
              background: #fff;
            }
        }
    }

    &__side-bar-content {
        background: $gray;
        width: 100%;
        
        &-container {
            padding: 24px 16px 90px;

            @media screen and (min-width: 768px) {
                padding: 0;
                max-width: 400px;
            }
        }

        .accordian {
            border: none !important;
            background: none !important;
            border-radius: none !important;
            box-shadow: none !important;
            margin-bottom: 8px;
            overflow: hidden;

            @media screen and (max-width: 768px) {
                margin-top: 24px !important;
            }
          
            .MuiAccordionSummary-content,
            .MuiAccordionSummary-content.Mui-expanded {
              margin: 0;
            }
          
            .MuiAccordion-root.Mui-expanded {
              margin: 0;
            }

            .MuiAccordion-root:before {
                border: none !important;
            }
          
            .accordian-summary {
              margin: 0;
              padding: 0;
              min-height: 0 !important;
          
              .accordian-title {
                margin-left: 0;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin: 0;
                letter-spacing: 0.1em;
              }
            }
          
            .accordian-details {
              flex-direction: column;
              padding: 0;

              .pinned--card--content--image img {
                  width: 18px;
                  height: auto;

                  @media screen and (max-width: 768px) {
                      width: 18px;
                  }
              }

              .pinned--card--image--container {
                  height: 200px;
              }

              .resources-length {
                margin: 8px 0 16px !important;
                color: #666666;
              }
          
              .see-more {
                width: 200px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #00819D;
                border-radius: 5px;
                font-family: 'Cabin';
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 0.01em;
                color: #00819D;
                background: transparent;
                margin: 0 auto;
                margin-top: 8px;
                margin-bottom: 24px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 16px;
                }
              }
          
              .resources-length {
                font-family: 'Cabin';
                font-weight: 400;
                font-size: 15px;
                color: #666666;
                margin: 0;
              }
            }
        }

        @media screen and (min-width: 768px) {
            width: 600px;
            padding: 24px 48px 32px;
            
            .pinned--card {
                max-width: 400px;
            }
            
            .pinned--card--image--container {
                height: 200px;
            }
        }
    }

    hr {
        margin: 24px 0 40px;
        opacity: 0.5;

        @media screen and (max-width: 768px) {
            margin: 0 0 24px;
        }
    }
}


// @media screen and (min-width: 968px) {

// }