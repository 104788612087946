.feedback {
    .added-rubric-option {
        margin-top: 16px;

        span {
            padding: 4px 8px;
        }
    }

    .eval--comments {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .added-rubric-option span:nth-child(2) {
        padding-left: 26px;
    }

    &--rubric--button {
        button {
            font-weight: 600 !important;
            font-size: 15px;
            letter-spacing: 0.5px;
            // height: 100%;
        }
    }

    .coach--add {
        width: 12px;
        height: 12px;
    }

    &--content--container {
        position: relative;
    }

    &--left {
        display: flex;
        justify-content: space-between;

        .coach--status {
            // margin-top: 0;

            @media screen and (max-width: 900px) {
                background: none;
                width: auto;
                align-items: unset;
                // margin-top: 12px;
                position: absolute;
                right: 0;
                top: -12px;

                span {
                    margin: 0;
                }

                p {
                    display: none;
                }
            }
        }
    }

    .empty--space {
        height: 8px;
    }
    .coach--view {
        margin-bottom: 0 !important;
    }
    
    &--user {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .MuiAvatar-circle {
            margin-right: 8px;
            height: 25px;
            width: 25px;
        }

        p {
            color: #284374;
            font-size: 15px;
            margin: 0;
        }
    }

    &--video {
        background:#D9F1F6;
        display: flex;
        justify-content: center;

        video {
            height: 500px !important;
            padding: 0 !important;

            @media screen and (max-width: 768px) {
                height: 200px !important; 
            }
        }

        .video-react .video-react-control:before, .video-react .video-react-big-play-button:before {
            position: relative;
            
        }

        .video-react-progress-control {
            background: rgba(23, 35, 34, 0.75);
            border-radius: 4px;
            height: 32px !important;
            display: flex;
            align-items: center;
            position: relative;
            top: -25px;
            min-width: unset !important;
            width: 100% !important;
            margin-right: 16px !important;
        }

        .video-react-control-bar {
            background: transparent !important;
            padding: 8px !important;
        }

        .video-react .video-react-play-progress {
            background-color: $mainColor !important;
            height: 6px !important;
        }

        .video-react .video-react-control:before {
            font-size: 3em;
        }

        .video-react-big-play-button:before {
            top: -1px !important;
        }

        .video-react-button:before {
            top: -4px !important;
        }

        .video-react-button {
            top: unset !important;
                left: 8px !important;
                bottom: 34px;
                background: #00819D !important;
                border-radius: 4px !important;
                opacity: 1 !important;
                border: none !important;
                width: 65px !important;
                height: 40px !important;
                padding: 0;
                display: flex;
                justify-self: center;
                align-items: center;
                margin-right: 16px !important;


        }

        .video-react-progress-holder {
            border: 1px solid #898F8F;
            padding: 2px;
        }

        .video-react .video-react-progress-holder {
            padding: 2px;
        }

        .video-react .video-react-progress-holder .video-react-play-progress, .video-react .video-react-progress-holder .video-react-load-progress, .video-react .video-react-progress-holder .video-react-tooltip-progress-bar, .video-react .video-react-progress-holder .video-react-load-progress {
            height: 6px;
        }
        
        .video-react-fullscreen-control,
        .video-react-volume-menu-button,
        .video-react-duration,
        .video-react-current-time,
        .video-react-duration-display,
        .video-react-time-divider,
        .video-react-play-progress:before {
            display: none;
            width: 0 !important;
        }

        .video-react {
            margin: 0;
            padding: 0 !important;
            height: 500px !important;

            @media screen and (max-width: 768px) {
                height: 200px !important; 
            }

            .video-react-big-play-button {
                top: unset !important;
                left: 16px !important;
                bottom: 16px;
                background: #00819D !important;
                border-radius: 4px !important;
                opacity: 1 !important;
                border: none !important;
                width: 65px !important;
                height: 40px !important;
                padding: 0;
                display: flex;
                justify-self: center;
                align-items: center;

                .video-react-control-text {
                    display: none;
                }
            }

            // button:before {
            //     top: unset !important;
            //     height: unset !important;
            // }
        }
    }
    
    &--container {
        display: flex;
    }

    &--resource {
        width: 60vw;
        height: 100%;
        padding: 32px 64px 24px;
        
        &--container {
            max-width: 800px;
            width: 100%;
            float: right;

            .coach--link {
                @media screen and (min-width: 900px) {
                    display: none !important;
                  }
            }

            .coach--view {
                // margin-bottom: 16px !important;
          
            }
        
        }
    }

    &--comments {
        width: 40vw;
        background: #EEEEEE;
        padding: 36px 0;
        min-height: calc(100vh - 127px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .coach--tab {
            padding: 4px 8px !important;
          }
    }

    &--title {
        margin: 16px 0 8px;
        width: 90%;
    }

    &--text {
        margin: 8px 0;
    }

   &--description {
        font-size: 17px;
        max-width: 550px;
        // padding-bottom: 16px;
    }

    &--date {
        font-size: 12px;
        color: #666666;
        margin-bottom: 16px;

        @media screen and (max-width: 900px) {
            margin: 12px 0 4px;
        }
    }

    &--buttons {
        display: flex;
        // align-items: center;
        flex-direction: column;

        .coach--link {
            margin: 24px 0 20px;
        }

        .coach--btns--container {
            display: flex;
            align-items: center;
        }
        
        img {
            height: 14px;
        }

        p {
            margin: 0;
            color: $mainColor;
            font-weight: 600;
            padding: 0 24px 0 4px;

            @media screen and (max-width: 355px) {
                padding: 0 16px 0 4px;
            }
        }

        p:last-child {
            padding: 0 0 0 4px;
        }
    }
}

@media screen and (max-width: 600px) {
    .feedback--video .video-react .video-react-big-play-button,
    .video-react-button,
    .video-react .video-react-play-control {
        border-radius: 50% !important;
        width: 33px !important;
        height: 33px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feedback--video .video-react-big-play-button:before {
        top: -6px !important;
        height: 100% !important;
        font-size: 23px;
    }

    .feedback--video .video-react .video-react-button:before {
        font-size: 23px;
        top: -2px !important;
    }

    .video-react .video-react-play-control {
        margin-top: 8px !important;
    }

    .video-react .video-react-play-control:before {
        font-size: 22px !important;
    }
}

.feedback--video {
    .video-react .video-react-slider {
        background: rgba(115, 133, 159, 0) !important;
    }

    .video-react-load-progress {
        height: 6px !important;
        margin-top: 1px;
        width: calc(100% - 2px) !important;
        margin-left: 1px;
    }

    .video-react-play-progress {
        margin-top: 1px;
        max-width: calc(100% - 2px) !important;
        margin-left: 1px;
        height: 6px !important;
    }
    
    .video-react .video-react-load-progress div {
        height: 6px !important;
    }

    .video-react-play-progress,
    .video-react-load-progress {
        margin-top: 1px !important;
        margin-left: 1px !important;
    }

    .video-react-load-progress,
    .video-react .video-react-progress-holder {
        height: 10px;
    }

    .video-react .video-react-progress-holder {
        border-radius: 2px;
    }

    .video-react .video-react-progress-control {
        padding: 0 8px;
        // border-radius: 5px;
    }

    @media screen and (min-width: 900px) {
        // .video-react-button {
        //     bottom: 32px !important;
        // }
    }

}

.bubles {
    height: 6px;
    width: 1px;
    background: #fff;
    position: absolute;
    top: 1px;
    margin-left: -1px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .drop {
    position: absolute;
    top: -17px;
    margin-left: -5px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .video-react .video-react-time-tooltip, .video-react .video-react-mouse-display:after, .video-react .video-react-play-progress:after {
      background-color: #fff !important;
      color: $mainColor !important;
      font-family: $mainFont !important;
      font-size: 12px !important;
      height: 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      width: 40px;
      top: -2.5em !important;
  }

  .video-react-play-progress:before {
    content: "";
    position: absolute !important;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    z-index: 99;
  }