@mixin displayFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fontFamilyCabin {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .sessions-filter {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sessions-filter {
    padding: 32px 0 24px;

    label {
      margin-right: 24px;
    }

    @include displayFlexCenter;
    justify-content: flex-start;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #cccccc;

    &__box1-input {
      @include displayFlexCenter;
      justify-content: space-around !important;
      width: 200px;
      height: 40px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 0 8px;

      &__input {
        @include fontFamilyCabin;
        font-size: 13px;
        letter-spacing: 0.01em;
        color: #888888;
        border: none;
        height: 100%;
        width: 100%;
        outline: none;
      }
    }

    &__box2-checkboxes {
      @include displayFlexCenter;
      margin-left: 6rem;

      &__filter-by-text {
        @include fontFamilyCabin;
        font-size: 11px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #666666;
        margin-right: 24px;
      }
      &__inputs-box {
        @include displayFlexCenter;

        input {
          width: 20px;
          height: 20px;
          display: flex;
          margin-left: 16px;
          border: 1px solid #888888;
          border-radius: 5px;
        }

        label {
          @include fontFamilyCabin;
          font-size: 13px;
          letter-spacing: 0.01em;
          color: #888888;
          margin: 0 0 0 8px;
          
        }
      }
    }
  }
}
