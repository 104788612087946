.age-groups {
  p {
    font-size: 18px;
  }
  form {
    display: flex;
    flex-direction: column;
    .checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .option {
      font-size: 13px;
      margin: 0;
      font-weight: bold;
      font-family: 'Open Sans';
    }
    .checkbox-with-input {
      margin-bottom: 20px;
    }
    .label-container {
      input[type=checkbox] {
        width: 28px;
        height: 28px;
        outline: 2px solid #17A6BA;
        margin: 0;
        margin-right: 1em;
        position: relative;
      }
    }
    label {
      width: fit-content;
      margin-bottom: 0;
    }
    /* The container */
    .label-container {
      display: flex;
      align-items: center;
      font-weight: 900;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    /* Hide the browser's default checkbox */
    .label-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 28px;
      border: 2px solid #17A6BA;
    }
    /* On mouse-over, add a grey background color */
    .label-container:hover input~.checkmark {
      background-color: #fff;
    }
    /* When the checkbox is checked, add a blue background */
    .label-container input:checked~.checkmark {
      background-color: white;
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    /* Show the checkmark when checked */
    .label-container input:checked~.checkmark:after {
      display: block;
    }
    /* Style the checkmark/indicator */
    .label-container .checkmark:after {
      left: 8px;
      top: 4px;
      width: 8px;
      height: 13px;
      border: solid #17A6BA;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}