.recovery {
    background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../images/kids.jpg') top center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 107%;
    // background-position: -100px;
    height: 100vh;
    overflow: hidden;
    color: #4D4D4D;
    font-family: 'Open Sans';

    .primary-card .primary-card-container {
        height: 75vh;

        p {
            font-weight: normal;
            line-height: 2;
        }

        .username-field {
            display: flex;
            flex-direction: column;
            position: relative;
            p {
                position: absolute;
                color: #4D4D4D;
                font-size: 14px;
                font-family: 'Open Sans';
                font-weight: bold;
                margin: 0;
                top: -24px;
            }
            input {
              width: 100%;
              height: 44px;
              outline: none;
              border: none;
              padding-left: 16px;
              z-index: 1;
              font-family: 'Open Sans';
              font-weight: bold;
              background: rgba(141, 220, 232, 0.1);
              border-radius: 5px;
              border-right: 1px solid #1CA6BA;
              border-bottom: 1px solid #1CA6BA;
              font-size: 18px !important;
            }
            input[type=text] {
              font-size: 18px !important;
            }
            ::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #1CA6BA !important;
              font-size: 18px !important;
            }
            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #1CA6BA !important;
              font-size: 18px !important;
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #1CA6BA !important;
              font-size: 18px !important;
            }
        }
        .recover-button {
            margin-top: 24px;
            button {
                font-size: 18px !important;
                font-weight: bold;
                font-family: 'Open Sans';
                padding: 22px 0 !important;
            }
        }

        .button {
            font-weight: normal;
            font-size: 14px;

            a {
                margin-left: 4px;
                font-weight: bold;
                color: #1CA6BA; 
                font-family: 'Open Sans';
            }
        }
    }
}

.create-password {
    background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../images/kids.jpg') top center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 107%;
    background-position: -100px 20%;
    height: 100vh;
    overflow: hidden;
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .form {
      width: 102%;
    }
    .hr {
      border: 0.1px solid #1CA6BA;
      width: 100%;
      height: 0.5px;
    }
    .password-field {
      position: relative;
      img {
        position: absolute;
        top: 32px;
        right: 16px;
        width: 24px;
        z-index: 9999;
      }
    }
    .MuiOutlinedInput-root.Mui-error {
      margin: 0 !important;
    }
    .MuiFormHelperText-root.Mui-error {
      margin-bottom: 8px;
      margin-top: 0 !important;
      position: relative;
      top: -4px;
    }
    input {
      width: 100%;
      outline: none;
      border: none;
      padding-left: 16px;
      z-index: 1;
      margin-bottom: 8px;
      font-weight: bold;
      background: rgba(141, 220, 232, 0.1);
      border-radius: 5px !important;
      border-right: 1px solid #1CA6BA !important;
      border-bottom: 1px solid #1CA6BA !important;
      color: #000 !important;
      padding: 0px 16px !important;
      height: 40px;
    }
    input[type=text] {
      color: #000 !important;
      font-size: 16px !important;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #1CA6BA !important;
      font-size: 16px !important;
    }
    .buttons {
      display: flex;
      margin-top: 16px;
      a {
        background: none;
        border: none;
        border-radius: 5px;
        border: 1px solid #1CA6BA;
        height: auto;
        margin-left: 0;
        height: 40px;
        width: 40px;
        margin-right: 8px;
        svg {
          color: #1CA6BA;
          fill: #1CA6BA;
        }
      }
      .submit-button {
        border-radius: 5px;
        width: 100%;
        background-color: rgba(141, 220, 232, 0.7);
        align-items: center;
        padding: 18px 0;
        text-transform: none;
        font-weight: bolder;
        border: none;
        box-shadow: none;
        height: 40px;
        font-size: 16px;
      }
      .submit-button:disabled {
        color: #FFFFFF;
      }
    }
    .requirements {
      margin: 16px 0;
      p {
        margin: 0;
      }
    }
    .create-password-form {
      .hr {
        margin-top: 8px;
      }
      p {
          color: #4D4D4D;
          font-size: 14px;
          font-family: 'Open Sans';
          font-weight: bold;
          margin: 0;
      }
    }
    @media screen and (max-width: 1400px) {
      background-size: 112%;
      background-position: top;
    }
    @media screen and (max-width: 800px) {
      background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../images/kids.jpg');
      /* Center the image */
      background-repeat: no-repeat;
      background-size: contain;
      /* Do not repeat the image */
    }
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
    .keyboard-date-picker:before, .keyboard-date-picker:after  {
      border-bottom: none !important;
    }
    #field-7-helper-text {
      margin-left: 14px;
    }
    .keyboard-date-picker {
      width: 100%;
      outline: none;
      border: none;
      z-index: 1;
      margin-bottom: 8px;
      font-weight: bold;
      background: rgba(141, 220, 232, 0.1);
      border-radius: 5px !important;
      border-right: 1px solid #1CA6BA !important;
      border-bottom: 1px solid #1CA6BA !important;
      color: #000 !important;
      height: 40px;
  
      button {
        background: none;
      }
  
      svg {
        fill: #1CA6BA;
      }
  
      input {
        border: none !important;
        background: none;
        padding: none;
        margin-bottom: 0;
      }
    }

    .create-buttons {
        padding-bottom: 24px;
    }

    i {
        font-weight: normal;
        display: flex;
        justify-content: center;
        color: #535353 !important;
    }
    .button {
        font-weight: normal;
        font-size: 14px;

        a {
            margin-left: 4px;
            font-weight: bold;
            color: #1CA6BA; 
        }
    }
  }