$color1: #f4f4f4;
$color2: #61C3D1;

.radio {
display: flex;

  .labels {
	  display: flex;
    flex-direction: column;
    margin-left: 4px;
  }

  input[type='radio']:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	position: relative;
	background-color: $white;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid #61C3D1;
}

input[type='radio']:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	position: relative;
  background-color: #61C3D1;
	content: '';
	display: block;
  visibility: visible;
}

  input[type="radio"] {
	  margin: 3px 8px 0 0;

    + .radio-label {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        position: relative;
        top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}