.comment {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  position: relative;
  .comment-content {
    .comment-header {
      display: flex; // align-items: center;
      .comment-name {
        display: flex;
      }
      .bookmark {
        display: flex;
        color: #17A6BA;
        font-weight: bold;
        .bookmark-icon {
          margin-right: 4px;
        }
      }
      .image {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      p {
        margin: 0 4px;
      }
    }
    .text {
      padding: 8px 16px 8px 0;
    }
    .reply {
      text-transform: uppercase;
      color: #17A6BA;
      font-weight: bold;
    }
    .reply-buttons {
      display: flex;
    }
  }
  .more-vert-icon {
    position: absolute;
    top: 8px;
    right: 0;
  }
}

.replies {
  border-bottom: none !important;
}

.reply {
  margin: 0;
  text-transform: uppercase;
  color: #17A6BA;
  font-weight: bold;
  cursor: pointer;
}

.reply-buttons {
  padding: 0 8px;
  display: flex;
}

.resource-comments {
	.comment {
		padding: 8px 0;
	}
	.resource-replies {
	  padding: 8px 0;
	  .comment-content {
		margin-left: 0 !important;
	  }
	}
	.reply-buttons {
	  padding: 0 !important;
	  p span {
		  font-size: 12px;
	  }
	}
  }