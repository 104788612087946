.add-comment-modal {
   z-index: 99999 !important;
   height: 100%;
   position: relative;
  .close-icon {
    svg {
      background: #fff;
      border-radius: 5px;
      fill: rgba(0, 0, 0, 0.8);
      padding: 3px;
    }
  }
  .submit-button {
    background: #46AB47;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border: none;
    padding: 8px 12px;
    text-transform: uppercase;
    z-index: 5;
    border: 1px solid #C5FACF;
  }
}

.form.reply-form {
  padding-top: 57px !important;

  .comment {
    padding: 16px 8px;
  }
}

.form {
  padding-top: 80px !important;
  height: 100vh;
  .MuiInputBase-input {
    border: none;
  }

  .MuiOutlinedInput-notchedOutline {
	  border: none;
  }
}