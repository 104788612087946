.learning {
  ul {
    list-style-type: none !important;
    margin: 0;

    .course-hero {
        li:first-child {
          margin-bottom: 0;
          height: 500px;

          .title h2 {
            font-weight: 400 !important;
          }

          .subtitle p {
            margin: 0 0 6px;

            span {
              line-height: 1;
            }
          }

          .card-full-content {
            max-width: 1600px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }

        @media screen and (max-width: 900px) {
          height: 480px;

          .title h2 {
            text-align: center;
          }

          .info-card .title h2 {
            text-align: left;
          }


          .details .course-info {
            font-size: 16px !important;
          }

          .card-full .card-full-content .buttons .play {
            font-size: 16px !important;
          }
        }
      }
    }

    .course {
      max-width: 1600px;

      li {
        height: 400px;
        list-style: none;

        @media screen and (max-width: 900px) {
          height: 420px;

          .title h2 {
            text-align: center;
          }

          .subtitle p span {
            font-size: 24px !important;
            line-height: 1;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      .course-hero {
        li:first-child {
          padding-bottom: 14px;
          .card-full {
            background-size: 300% !important;
          }
        }
      }
    }

    .course {
      padding: 0 10px;
      li {
        padding: 0 10px;
        .card-full {
          border-radius: 5px;
          border: 1px solid #CCCCCC;
        }
    }
    }
  }
  .MuiBox-root {
    padding: 0;
  }
  .MuiTabs-root {
    border-bottom: 1px solid #CCCCCC;
    .Mui-selected {
      color: $mainColor !important;
      background: #EEEEEE;
      border-radius: 110px;
      font-weight: 600;
    }
  }
  .PrivateTabIndicator-root,
  .MuiTabs-indicator {
    background-color: transparent !important;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    h1 {
      margin: 0;
    }
    .view {
      display: flex;
      align-items: center;
      height: 100%;
      border: 1px solid #c5c8d0;
      border-radius: 6px;
      svg {
        color: #c5c8d0;
        display: flex;
        height: 30px;
        width: 30px;
        padding: 2px;
      }
      .active-left,
      .active-right {
        background-color: #1ca6ba;
        svg {
          color: #fff;
        }
      }
      .active-left {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .active-right {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .MuiBox-root {
    padding: 0;
  }
  .cards-list {
    grid-template-columns: repeat(1, 1fr) !important;
    .card {
      .layout {
        display: block;
        height: 80px;
        a {
          display: flex;
          flex-direction: row-reverse;
          div {
            width: 150px;
            height: 80px;
            img {
              height: 100%;
              width: auto;
            }
          }
          .description {
            width: 100%;
            p {
              font-size: 15px;
            }
            h6 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .cards {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    column-gap: 16px;
    margin: 0 auto;
    padding: 16px;
    .card {
      box-shadow: 0px 9px 15px #8690a538;
      // border: 1px solid #edf0f7;
      padding: 0;
      margin: 16px 0 0;
      border-radius: 10px;
      overflow: hidden;
      background: #fff;
      .description {
        margin: 16px 8px;
        p {
          color: #0db453;
          font-weight: bold;
          font-size: 12px;
          margin: 0;
        }
        h6 {
          margin: 0;
          font-weight: normal;
          font: normal normal normal 14px/21px Roboto;
        }
      }
    }
  }
  ul {
    padding: 0;

    li {
      padding: 0;
      margin: 0;

      .card-full {
        position: relative;
        height: 100%;
        padding: 16px 64px;
        background-position: center !important;
        /* Center the image */
        background-repeat: no-repeat !important;
        /* Do not repeat the image */
        background-size: cover !important;

        .card-full-content {
          position: absolute;
          bottom: 5px;
          width: 100%;
          left: 0;
          padding: 0 20px 20px;
          
          .title {
            display: flex;
            padding: 0 8px;
            h2 {
              font-family: $mainFont;
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #000000;
              margin: auto;
              margin-bottom: 8px;
            }
          }
          .subtitle-container {
            position: relative;
            justify-content: center;

            @media screen and (min-width: 900px) {
              margin-right: 60px;
            }

            .subtitle {
              text-align: center;
              font-size: 14px;
              line-height: 2.2;
              font-weight: 700;
              overflow: hidden;

              // p:hover {
              //   -webkit-line-clamp: 6;
              // }
              p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin: 0 0 6px;
                padding: 0 8px;
                position: relative;
                overflow: hidden;

                span {
                  font-family: $mainFont;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 30px;
                  line-height: 30px;
                  color: #000000;
                  background: transparent;
                  -webkit-box-decoration-break: clone;
                  box-decoration-break: clone;
                  border-radius: 5px;
                  padding: 0 8px;
                  line-height: 1;
                }

                .two-lines:after {
                  content: '...';
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 15px;
                  height: 5px;
                }
              }
            }
          }

          // Course information
          .details {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            cursor: pointer;
            img {
              margin-right: 8px;
            }
            a {
              display: flex;

              // img {
              //   margin-right: 8px;
              // }
            }
            .course-info {
              color: #00819d;
              margin-left: 5px;
              font-family: $mainFont;
              font-style: normal;
              font-weight: 600;
              line-height: 19px; 
              margin: 0;
              padding: 0;
            }
          }
          .card-full-container {
            width: 100%;
            margin: 0 auto;
            font-weight: bold;
            text-align: center;

            .progress-container,
            .minutes-container,
            .rating-container,
            .modules-container {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: 700;
              color: #737373;
              margin-top: 10px;
              font-family: $mainFont;
              p {
                color: #df187d;
                margin: 0;
                padding: 0;
              }
            }
          }
          a {
            text-decoration: none;
          }
          .buttons {
            display: flex;
            justify-content: center;
            .no-courses {
              margin-right: 16px;
            }
            .link {
              color: #fff;
              text-transform: uppercase;
              text-decoration: none;
              font-weight: bold;
              background-color: transparent;
              border-width: 0;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #00819d;
              border-radius: 5px;
              width: 100%;
            }

            .button {
              color: #fff;
              text-decoration: none;
              font-weight: bold;
              padding: 8px 24px;
              font-size: 16px;
              border-radius: 6px;
              .play-icon {
                width: 10px;
                margin-right: 8px;
              }
            }

            .continue {
              background: transparent;
              border: none;
            }

            .enrollButton,
            .play {
              width: 100%;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: $mainFont;
              font-weight: 600;
              letter-spacing: 1px;
              font-size: 15px;
            }

            .details {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .download {
              background: #fff;
              padding: 0 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #ffe3c9;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.learning-default {
  height: calc(100vh - 60px);
  overflow: hidden;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.8)),
    url('../../images/girl.jpeg') no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .add-new-content {
    position: relative;
    background: #fff;
    box-shadow: 2px 2px #17a6ba;
    color: #fff;
    width: auto;
    bottom: 60px;
    border-radius: 3px;
    max-width: 600px;

    h3,
    p {
      color: #000;
    }
  }
}

.outline-video {
  background: transparent;
  border: none;
  width: 100%;
}

.course {
  li {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .course {
    .card-full-content {
      display: flex;
      flex-direction: column;
   }
  }
}

@media screen and (min-width: 900px) {  
  .course-hero {
    li:first-child {
      .card-full-content {
        padding: 60px 20px;

        .details p {
          font-size: 16px;
        }

        .subtitle {
          padding-top: 8px;
        }

        .info-card {
          .subtitle {
            padding-top: 0 !important;
          }
        }

        .title h2 {
          margin-bottom: 0 !important;
        }

        .subtitle-container .subtitle p span {
          font-size: 48px !important;
          line-height: 1;
        }
      }
    }

    .buttons a {
      width: 250px !important;
    }
  }

  .course {
    margin: auto;
    padding: 0 110px !important;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 0 10px;
      width: 50%;
      .card-full {
        border-radius: 5px;
        border: 1px solid #CCCCCC;
      }
    }

    .buttons a {
      width: 150px !important;
    }
  }

  .details {
    margin-bottom: 0 !important;
    cursor: pointer;

    p {
      font-size: 14px;
    }

    a {
      display: flex;
    }
  }

  .card-full-container {
    margin-bottom: 0 !important;
  }

  .card-full-content {
    display: flex;
    justify-content: space-between;

    .box-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    // .box-info-right {
    //   width: 100%;
    //   align-items: flex-end;
    // }

    .title {
      display: block !important;
      padding: 0 !important;
    }

    .subtitle {
      text-align: left !important;
    }

    .subtitle p {
      padding: 0 !important;

      span {
        padding: 0 !important;
        line-height: 1;
      }
    }

    .details {
      justify-content: flex-start !important;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1024px) {
  .course-hero {
    li {
      .card-full-content {
        padding: 30px 110px !important;
      }
    }
  }

  .course {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .course-hero {
    li {
      .card-full-content {
        padding: 60px 120px !important;
      }
    }
  }

  // .course {
  //   padding: 0 100px;
  // }
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
    .course {
    padding: 0 90px !important;
  }
}

@media screen and (max-width: 900px) {
  .course-hero {
    .buttons {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
  .course-hero {
    .card-full-content {
      padding: 0 100px 20px !important;
    }
  }
}