@import './Typography.scss';
html,
body {
  min-height: 100vh; // background: linear-gradient(0deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url('../../../images/mother-giving-baby-boy-massage.jpg');
  // background-position: center;
  // /* Center the image */
  // background-repeat: no-repeat;
  // /* Do not repeat the image */
  // background-size: cover;
  background: white !important;
  font-family: $openSans;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // -o-user-select: none;
  // user-select: none;
  font-family: $mainFont;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $mainFont !important;
}

$borderBottom: 1px solid rgb(226, 226, 226);
.coming-soon {
  text-align: center;
  margin: 32px 0;
}

// #root,
// .makeStyles-container-4 {
//   margin-bottom: 16px;
// }
$border: 1px solid #dadada;
.layout {
  width: 100%;
  height: 100%;
}

a,
button,
button:hover {
  cursor: pointer;
}

.light-button {
  background: #8ddce8 !important;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  padding: 18px 0 !important;
  text-transform: none !important;
  border: none !important;
  box-shadow: none !important;
  height: 40px;
  span {
    font-weight: bold !important;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 18px;
    color: #fff;
  }
}

a {
  text-decoration: none;
}

.dark--submit--button {
  background: #00819d !important;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  text-transform: none !important;
  border: none !important;
  box-shadow: none !important;
  height: 40px;

  span {
    font-weight: bold !important;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 18px;
    color: #fff;
  }
}

.dark-button {
  background: #00819d !important;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  padding: 11px 0 !important;
  text-transform: none !important;
  border: none !important;
  box-shadow: none !important;
  // height: 40px;
  span {
    font-weight: bold !important;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 18px;
    color: #fff;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

.no-profile-image {
  font-size: 14px !important;
  color: #fff !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.desktop-max-width {
  max-width: 1600px;
  margin: 0 auto;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.max-width {
  max-width: 1336px;
  margin: 0 auto;
}

.page-title {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin: 0;

  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
}

#menu- {
  z-index: 99999 !important;
}