.badges {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 9px 15px #8690A538;
    border: 1px solid #EDF0F7;
    margin: 16px 16px 24px;
    padding: 0px 0 16px 16px;
    .lock-icon-wrapper {
      span {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #f5f5f5;
        border: 1px dotted #333;
        position: relative;
        svg {
          color: rgb(175, 175, 175);
        }
      }
    }
  } 