.confirmation {
  padding-top: 16px;
  color: #4D4D4D;
  label {
	  width: fit-content;
  }
  h1 {
    margin: 32px 0;
    font-size: 24px;
    margin-bottom: 16px;
  }
  h3 {
    margin: 48px 0 16px;
    font-size: 18px;
    overflow-wrap: break-word;
  }
  input[type="text"] {
    background: #F3FDFF;
    border-radius: 3px;
    border-right: 2px solid #39B4C5;
    border-bottom: 2px solid #39B4C5;
    color: #39B4C5;
    font-weight: bold;
    font-size: 18px;
    padding: 8px 16px;
    margin-bottom: 16px;
    text-align: center;
  }
  ::placeholder {
    color: #39B4C5 !important;
    font-weight: bold;
    text-align: center;
  }
  button {
    border-radius: 5px;
    background: #39B4C5;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: none;
    border: none;
    color: #fff;
    width: 100%;
    padding: 8px 0;
  }
  button:hover {
    background: #39B4C5;
  }
  .button-light {
    button {
      background: #8DDCE8;
    }
  }
  p {
    margin-bottom: 8px;
  }
  .code-resend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    .resend-disabled {
      color: #4D4D4D !important;
    }
    .resend {
      color: #39B4C5;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .code-sent {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 4px;

    .green-check {
      top: 0;
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    p {
      margin: 0;
      font-family: "Open Sans";
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.confirmation-message {
  a {
    background: #39B4C5;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    position: relative;
    top: 16px;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  a:hover {
    text-decoration: none;
  }
}