@mixin displayFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fontFamilyCabin {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
}

.coach--bar.session {
  box-shadow: none !important;

  .coach--tabs--container {
    margin: 0 7px;
    padding: 0;

    .coach--tabs {
      margin-bottom: 12px;
    }

    .MuiTabs-scroller {
      border-bottom: 1px solid #cccccc;
    }
  }
}

.session--modal--summary,
.session--modal-action-items {
  @include fontFamilyCabin;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  margin-top: 27px;
}

.session--modal--summary-text,
.session--modal-action-items-text {
  @include fontFamilyCabin;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
