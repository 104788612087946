$blue: #144CC7;
$red: #C7144C;
$purple: #5A0AC7;
$lightPurple: #EDE0FF;
$white: #fff;
$black: #000;
$gray: #919191; 
$orange: #FD7A00;
$green: #46AB47;
$lightGreen: #C5FACF;
$burgundy: #DF187D;
$teal: #1CA6BA;
$lightBlue: #8DDCE8;
$aqua: #61C3D1;
$steel: #028c9f;
$mainColor: #00819D;
$gray: #EEEEEE;