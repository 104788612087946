@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

.info-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
    animation: fadeIn linear 0.1s;
    -webkit-animation: fadeIn linear 0.1s;
    -moz-animation: fadeIn linear 0.1s;
    -o-animation: fadeIn linear 0.1s;
    -ms-animation: fadeIn linear 0.1s;
    display: flex;
    justify-content: flex-end;

    .title {
        padding: 0 !important;
    }

    .outline {
        p {
            @media screen and (min-width: 900px) {
                width: 35vw !important;
            }
            @media screen and (max-width: 900px) {
                width: 70vw !important;
            }
        }
    }

    ul li {
        height: auto !important;
        display: flex;
        width: 100%;

        a {
            width: 100%;

            .outline-wrapper {
                // display: flex;
                padding: 8px 0;

                @media screen and (max-width: 900px) {
                    display: flex;
                }

                svg,
                img {
                    margin-right: 8px;
                }

                p {
                    white-space: unset !important;
                    margin: 8px 0;
                }
            }

            .outline-content {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .title {
        display: block !important;
        margin: 20px 0 10px;
        margin-bottom: 6px !important;

        h2 {
            margin-bottom: 0 !important;
            text-align: left !important;

            @media screen and (max-width: 900px) {
                font-size: 12px !important;
                font-family: "Cabin", sans-serif;
                font-style: normal;
                font-weight: normal;
                line-height: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #000000;
                margin: auto;
                margin-bottom: 8px;
            }
        }
    }

    .subtitle {
        text-align: left !important; 
        margin-top: 10px;
        margin-bottom: 2px;

        p {
            font-weight: 500;
            overflow: unset !important;
            display: block !important;

            @media screen and (max-width: 900px) {
                line-height: 2;
                margin: 0;
                width: auto;
                padding: 0 !important;
            }
        }

        @media screen and (max-width: 900px) {
            margin-bottom: 0px;
        }

        span {
            padding: 0 !important;
            font-family: "Cabin", sans-serif;
                font-style: normal;
                font-weight: 500;
                color: #000000;
                line-height: 1;
                width: 100%;
            
            @media screen and (max-width: 900px) {
                font-family: "Cabin", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                color: #000000;
                line-height: 1;
                width: 100%;
 
            }
        }
    }

    .info-card {
        background: #fff;
        padding: 10px 20px 0;
        position: relative;
        height: 100%;

        .MuiLinearProgress-determinate {
            width: 100%;
            margin: 0 auto;
        }

        .lock {
            height: auto;
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: 900px) {
            overflow: hidden !important;
            height: calc(100vh + 200px);
            .anything {
                height: auto !important;
                a {
                    img {
                        margin-left: 12px;
                    }
                }
            }
        }

        button#scrollable-auto-tab-0 {
            margin-right: 24px;
        }

        .info-card-scroll-container {
            height: calc(100vh - 120px);
            overflow-x: auto;
            width: 100%;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none; 
        }

        .info-card-scroll-container::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            background-color: transparent;
          }

        .info-card-scroll-container::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
          }

    }

    .info-card-image-wrapper {
        height: 200px;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: auto;
            width: 120%;
        }
    }

    p {
        font-family: $mainFont;
        line-height: 26px;
        font-size: 17px;
    }

    .tab-panel {
        padding-bottom: 60px !important;
    }

    .close-info-card {
        background: #EEEEEE;
        border-radius: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 40px;
        margin-bottom: 12px;

        img {
            height: 10px;
            width: 10px;
            margin-right: 8px;
        }

        .desktop-x {
            display: none;
        }

        .close-text {
            font-family: $mainFont;
            display: block;
            color: #00819D;
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 600;
        }
    }

    .info-card-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: #EEEEEE;
        //box-shadow: 10px 0px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        @media screen and (max-width: 900px) {
            position: fixed;
        }

        button {
            border: 1px solid $mainColor;
            border-radius: 5px;
            height: 100%;
            font-size: 15px;
            font-family: $mainFont;
            letter-spacing: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;

            img {
                width: 10px;
                height: auto;
            }

            a {
                color: #fff;
            }
        }

        .mobile-info-card-buttons {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .prev-button,
            .next-button {
                width: 40px;
                height: 40px;
                background: transparent;
            }

            .launch-button {
                height: 40px;
                flex: 2;
                background: $mainColor;
                font-weight: 600;
                font-family: $mainFont;
                font-size: 15px;
                letter-spacing: 1px;
                margin: 0 10px;
            }
        }
        
        .info-card-left-buttons {
            display: none;
        }

        .info-card-right-button {
            display: none;
            // height: 100%;
            // button {
            //     background: $mainColor;
            //     color: #fff;
            // }
        }
    }

    span.MuiTab-wrapper { 
        text-transform: none !important;
    }
    
    
    // desktop at 900 and up
    @media screen and (min-width: 900px) {
        .info-card {
            top: 0;
            height: 100%;
            width: 50%;
            background: #fff;
            right: 0;
            width: 50vw;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            position: flex;
            padding: 50px;

            .mobile-info-card-buttons {
                display: none;
            }
            
            .learning-tabs {
                button {
                    // height: 36px;
                    width: 100px;
                    font-family: $mainFont;
                    text-transform: none;
                    font-size: 15px;
                    letter-spacing: 1px;
                    margin: 5px 0 20px;
                    padding: 0;
                    // font-weight: 600;
                    color: #000;
                    opacity: 1;
                }

                .outline {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    // p {
                    //     @media screen and (max-width: 900px) {
                    //         width: 70vw !important;
                    //     }
                    // }
                    li {
                        height: auto;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        a {
                            text-decoration: none;
                            font-family: $mainColor;
                            font-size: 15px;
                            font-weight: 600;
                            letter-spacing: 1px;

                            .outline-wrapper {
                                display: flex;

                                img {
                                    margin: 0 16px;
                                }
                            }
                        }
                    }
                }
            }

            .info-card-image-wrapper {
                height: 400px;
                width: auto;
                overflow: hidden;
                display: flex; 
                justify-content: center;
                align-items: center;

                img {
                    width: 120%;
                }
            }

            .title {
                margin-top: 30px;
                margin-bottom: 12px;
                font-size: 11px !important;
            }

            // .subtitle {
            //     margin-bottom: 10px;
            // }

            .subtitle p,
            .subtitle span,
            .subtitle p span {
                font-size: 48px !important;
                line-height: 1.2 !important;
            }

            .close-info-card {
                background: $mainColor;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                position: absolute;
                left: -25px;
                top: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    margin-right: 0;
                }

                .desktop-x {
                    display: block;
                    width: 14px;
                    height: 14px;
                }

                .mobile-x,
                .close-text {
                    display: none;
                }
            }

            .info-card-bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 60px;
                background: #EEEEEE;
                //box-shadow: 10px 0px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 50px;

                button {
                    border: 1px solid $mainColor;
                    border-radius: 5px;
                    height: 100%;
                    width: 150px;
                    font-weight: 600;
                    font-size: 15px;
                    font-family: $mainFont;
                    letter-spacing: 1px;
                    color: #fff;
                    a {
                        color: #fff;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                
                .info-card-left-buttons {
                    display: flex;
                    height: 100%;
                    button {
                        color: $mainColor;
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: transparent;
                   
                        img {
                            margin: 0 8px;
                            height: 10px;
                            width: auto;
                        }
                    }
                }

                .info-card-right-button {
                    display: block;
                    height: 100%;
                    button {
                        background: $mainColor;
                        color: #fff;
                        width: 250px;
                    }
                }
            }
        }
    }
}

.MuiTab-textColorInherit {
    opacity: 1 !important;
}
