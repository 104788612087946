.pinned--library {
    max-width: 1236px;
    margin: 0 auto;
    padding: 16px;

    .back--button {
        display: block !important;
    }
    
    &--title {
        margin: 0;
        padding: 0;
        font-family: "Cabin", sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.01em;
        margin-bottom: 12px;
    }
}