.resources-page {
  .nav-background {
    color: #144CC7;
  } //     .comment-modal-buttons {
  //       display: flex;
  //       justify-content: space-between;
  //       margin-bottom: 16px;
  //       .annotate {
  //         border-radius: 50px;
  //         background: #144cc7;
  //         color: white;
  //         padding: 0 16px;
  //       }
  //     }
  //  }
}

.video-details-navigation {
  background-color: #61C3D1;
  justify-content: space-between !important;
  .title {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 0;
  }
  .close-icon {
    fill: black;
    background: #fff;
    border-radius: 5px;
    height: 28px;
    width: 28px;
    padding: 4px;
    border: 1px solid #C4F1F7;
    z-index: 5;
  }
  .share-with-users {
    position: relative;
    display: flex;
    align-items: center;
    .avatar {
      // margin-right: 8px;
      border: 2px solid #FF7A00;
      background: #FFE3C9;
    }
    .avatar-3 {
      z-index: 4;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-size: 14px;
      font-weight: bold;
      // margin-right: 8px;
      position: relative;
      // left: -12px;
    }
    .avatar-2 {
      position: relative;
      // position: absolute;
      z-index: 3;
      // left: -12px;
    }
    .avatar-1 {
      position: relative;
      // left: 25px;
      z-index: 2;
      // left: -12px;
    }
    .avatar-0 {
      position: relative;
      z-index: 1;
      // margin-right: 12px;
    }
  }
  .two-avatars {
    .avatar-0 {
      left: 10px;
    }
  }
  .three-avatars {
    .avatar-0 {
      left: 20px;
    }
    .avatar-1 {
      left: 10px;
    }
  }
  .more-avatars {
    .avatar-2 {
      left: 10px;
    }
    .avatar-1 {
      left: 20px;
    }
    .avatar-0 {
      left: 30px;
    }
  }
  .share {
    background: #46AB47;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border: none;
    padding: 8px 12px;
    text-transform: uppercase;
    z-index: 5;
    margin-left: 8px;
  }
  .share-transparent {
    opacity: 0.7;
  }
  .complete-evaluation {
    z-index: 5;
    background: #46AB47;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border: none;
    padding: 8px 12px;
    text-transform: uppercase;
    border: 1px solid $white;
  }
}

.video {
  position: relative;
  .video-react,
  .video-react-playing {
    height: 250px !important;
    padding-top: 0 !important;
  }
  .video-react .video-react-big-play-button {
    border: none;
    background: transparent;
  }
  .video-react-icon-play-arrow:before,
  .video-react .video-react-bezel .video-react-bezel-icon-play:before,
  .video-react .video-react-play-control:before,
  .video-react .video-react-big-play-button:before {
    // text-shadow: 0 0 1px rgb(105, 105, 105);
    font-size: 60px;
  }
  .video-react-control-bar {
    opacity: 0 !important;
  }
  .video-react:hover .video-react-control-bar {
    opacity: 1 !important;
  }
  progress {
    z-index: 9999;
    width: 100%;
    height: 3px;
    background: rgba(255, 255, 255, 0.5);
  }
  progress[value] {
    appearance: none;
    /* Needed for Safari */
    border: none;
    /* Needed for Firefox */
    color: #1CA6BA;
    /* Fallback to a solid color */
    position: absolute;
    bottom: 0;
  }
  /* WebKit styles */
  progress[value]::-webkit-progress-value {
    background-image: linear-gradient( to right, #1CA6BA, #1CA6BA);
    transition: width 1s linear;
  }
  /* Firefox styles */
  progress[value]::-moz-progress-bar {
    background-image: -moz-linear-gradient( right, #1CA6BA, #1CA6BA);
  }
}

video {
  border: none;
  background: transparent;
  outline: none;
}

.review-resources-tabs {
  padding-bottom: 58px;
  .review-resources-tabs-bar {
    padding: 0 8px;
  }
  .MuiFormControl-root.MuiTextField-root {
    margin-top: 16px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiBox-root {
    padding: 0;
  }
  .MuiTabs-root {
    border-bottom: 1px solid #707070;
    min-height: 0 !important;
    z-index: 5;
    .Mui-selected {
      color: #616161;
      font-weight: bold;
    }
  }
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
  .PrivateTabIndicator-root-12 {
    left: 0;
  }
  .MuiTab-root {
    min-height: 0 !important;
  }
  .PrivateTabIndicator-root,
  .MuiTabs-indicator {
    background-color: #1CA6BA !important;
    padding: 0 4px;
    height: 3px !important;
  }
  .video-details {
    margin-top: 8px;
    border-bottom: $border;
    width: 100%;
    padding: 0 8px 4px;
    .description {
      margin-top: 16px;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 700;
    font-family: $openSans;
  }
  .add-notes-container,
  .submit-resource-evaluation,
  .pending-evaluation {
    text-align: center;
    padding: 32px;
    margin: 0 auto;
    h3 {
      color: $gray;
    }
  }
  .pending-evaluation {
    h3 {
      color: black;
      margin: 0;
    }
    .last-updated {
      font-size: 13px;
      margin: 2px 0 8px;
    }
  }
  .pop-up .pop-up-modal {
    .evaluation-select {
      padding: 8px;
      select {
        width: 100%;
        border-radius: 5px;
        padding: 8px;
        color: black;
        padding-right: 30px;
        font-size: 14px;
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: #fff url("../../images/caret-down-solid.svg") no-repeat;
        background-position: right 8px top 50%;
        border: 1px solid rgb(201, 201, 201);
      }
    }
  }
  .evaluation-submit-container {
    padding: 0 8px;
    width: 100%;
    position: absolute;
    bottom: 68px;
    .evaluation-submit {
      background: $green;
      border: none;
      border-radius: 5px;
      color: $white;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      padding: 12px 0;
    }
  }
}

.evaluator-tabs {
  .MuiTabs-flexContainer {
    justify-content: space-around;
  }
}

.comment-modal {
  background: white;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  left: 0;
  .evaluation-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .added-rubric-options {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    span:first-child {
      background: $lightBlue;
      z-index: 2;
    }
    span:nth-child(2) {
      position: relative;
      left: -30px;
      background: $lightGreen;
      z-index: 1;
      padding-left: 30px;
    }
    span {
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 2px 10px;
      font-size: 16px;
      font-weight: 600;
      border: 2px solid $white;
      img {
        padding-right: 8px;
      }
    }
  }
  .add-evaluation {
    background: rgb(244, 250, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding: 12px 16px;
    .feedback {
      display: flex;
    }
    p {
      color: $steel;
      margin: 0;
      font-weight: 500;
      font-size: 17px;
      letter-spacing: 0.5px;
    }
    img {
      margin-right: 12px;
    }
  }
}

.comments-section {
  padding-bottom: 60px;
  width: 100%;
}

.comments {
  padding: 16px;
  text-align: left;
  margin-left: 16px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: 0;
  .comments-container {
    display: flex;
    .comment-inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .time-stamp {
      background: $steel;
      border-radius: 5px;
      color: white;
      height: 20px;
      padding: 0 8px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    .comment-content {
      text-align: left;
      margin-left: 16px;
      .comment-content-header {
        display: flex;
        align-items: center;
        .user-image {
          border-radius: 50%;
          margin-right: 8px;
        }
        p {
          margin: 0 4px;
        }
      }
      .comment {
        margin-top: 4px;
        padding-right: 16px;
        border-bottom: 0;
      }
    }
  }
}

.edit {
  background: white;
  box-shadow: 0px 9px 15px black;
  position: fixed;
  bottom: 57px;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  left: 0;
  .cancel {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .MuiButton-label {
    display: flex;
    justify-content: flex-start;
    margin-left: 8px;
    padding: 8px 0;
  }
}

.complete-review-modal {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  top: 0;
  z-index: 10;
  .complete-review-modal-container {
    padding: 16px;
    height: 220px;
    width: 90%;
    border-radius: 10px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .review-modal-buttons {
      display: flex;
      justify-content: space-between;
    }
    .cancel,
    .confirm {
      width: 46%;
    }
    .cancel {
      border: 1px solid black;
      border-radius: 5px;
    }
    .confirm {
      border: 1px solid #144CC7;
      border-radius: 5px;
      color: white;
      background: #144CC7;
    }
  }
  h1 {
    font-weight: bold;
  }
}

.added-rubric-option {
  display: flex;
  position: relative;
  left: -4px;
  margin-top: 4px;
  span:first-child {
    background: $lightBlue;
    z-index: 2;
  }
  span:nth-child(2) {
    position: relative;
    background: $lightGreen;
    z-index: 1;
    left: -26px;
    padding-left: 30px;
  }
  span {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 600;
    border: 2px solid $white;
  }
}

.resource-avatar {
  width: 22px !important;
  height: 22px !important;
}

.user-image {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.evaluation-tab {
  display: flex;
  .dot {
    width: 8px;
    height: 8px;
    background: #FF7A00;
    border-radius: 50%;
  }
}

.Mui-selected {
  .evaluation-tab .dot {
    display: none;
  }
}

.shared-resources {
    .MuiTabs-flexContainer {
      justify-content: center;
    }
    .comments .comments-container .shared-comment-content {
      margin-left: 0 !important;
    }
}

.shared-resource-viewer-acess {
  .MuiTabs-flexContainer {
    justify-content: flex-start !important;
  }

  .MuiTabs-indicator {
    background: none !important;
    height: 0 !important;
  }
}