.coach--top--btns {
  // position: absolute;
  // right: 16px;
  display: flex;
  
  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
    justify-content: center;
    padding: 10px 8px;
    z-index: 99;
    background-color: white;
  }

  .btn-white {
    margin-right: 6px;
  }

  .btn-blue {
    margin-left: 6px;
  }

  button {
    font-weight: 600;
    font-size: 15px;
    width: 200px;
    height: 40px;
    font-family: $mainFont;
    // letter-spacing: 1px;
  }

  .coach--add {
    margin-right: 8px;
    width: 10px;
    height: 10px;
    filter: brightness(0) invert(1);

    @media screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }

  .btn-white {
    background: #fff;

  }
}
