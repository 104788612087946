.back--button {
    display: none !important;
    background: transparent;
    border: 1px solid $mainColor !important;
    border-radius: 5px;
    color: $mainColor !important;
    width: 121px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 16px 0 !important;

    img {
        margin-right: 16px;
    }

    span {
        font-family: 'Cabin';
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.01em;
        text-transform: none;
        padding: 0;
    }

    @media screen and (min-width: 900px) {
        display: block !important;
    }
}

.show-button {
    display: block !important;
}