.primary-card {
  background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../images/kids.jpg') top center;
  /* Center the image */
  background-repeat: no-repeat;
  background-size: 107%;
  background-position: -100px 20%;
  overflow: hidden;
  font-weight: bold;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100vh;
  color: #4D4D4D;
  @media screen and (max-width: 1400px) {
    background-size: 112%;
    background-position: top;
  }
  @media screen and (max-width: 800px) {
    background: linear-gradient(0deg, rgb(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../images/kids.jpg');
    /* Center the image */
    background-repeat: no-repeat;
    background-size: contain;
  }
  .percentage {
    position: absolute;
    width: 80px;
    height: 80px;
    top: -40px;
    right: 16px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #1CA6BA;
    .CircularProgressbar .CircularProgressbar-trail {
      stroke: rgba(28, 165, 186, 0.2) !important;
    }
    .CircularProgressbar-path {
      stroke: #1CA6BA;
    }
    svg {
      fill: #1CA6BA !important;
    }
    .percentage-text {
      border-radius: 50%;
      border: 1px solid #1CA6BA;
      height: 68px;
      width: 68px;
      position: absolute;
      display: flex;
      color: #4D4D4D;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 5px;
      left: 5px;
      span {
        text-align: center;
        position: relative;
        top: -1px;
      }
      h2 {
        font-size: 20px;
        margin: 0;
      }
      p {
        font-size: 9px;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
  .primary-card-container {
    height: 90vh;
    border: 1px solid #1CA6BA;
    border-right: 3px solid #1CA6BA;
    border-bottom: 3px solid #1CA6BA;
    background: #fff;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 56px 16px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    img {
      position: absolute;
      width: 80px;
      top: -40px;
    }
    .hr {
      border: 0.1px solid #1CA6BA;
      width: 100%;
      height: 0.5px;
    }
    .invalid {
      svg {
        background: red;
        border-radius: 50%;
      }
    }
    .valid {
      svg {
        background: $green;
        border-radius: 50%;
      }
    }
  }
  .form {
    overflow-x: scroll;
    padding: 0 !important;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
  }
  .form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  
  .form::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #1CA6BA;
  }
  .primary-card-buttons {
    position: relative;
    .buttons {
      display: flex;
      margin-top: 16px;
      button {
        background: none;
        border: none;
        border-radius: 5px;
        border: 1px solid #1CA6BA;
        height: auto;
        margin-left: 0;
        height: 40px;
        width: 40px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #1CA6BA;
          fill: #1CA6BA;
        }
      }
      a {
        background: none;
        border: none;
        border-radius: 5px;
        border: 1px solid #1CA6BA;
        height: auto;
        margin-left: 0;
        height: 40px;
        width: 40px;
        margin-right: 8px;
        svg {
          color: #1CA6BA;
          fill: #1CA6BA;
        }
      }
      .continue {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 100%;
        background-color: rgba(141, 220, 232, 0.7);
        align-items: center;
        text-transform: none;
        font-weight: bolder;
        border: none;
        box-shadow: none;
        height: 40px;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        margin-right: 0;
      }
    }
  }
  .continue-dark {
    background-color: #1CA6BA !important;
  }
  .primary-card-button {
    .button {
      display: flex;
      margin-top: 16px;
      .continue {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 100%;
        background-color: #1CA6BA;
        align-items: center;
        padding: 18px 0;
        text-transform: none;
        font-weight: bolder;
        border: none;
        box-shadow: none;
        height: 40px;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
  #text {
    margin-left: 16px;
  }
}