.creds-container {
  padding: 1rem 4rem;
  padding: 1.3rem 0.7rem 0.5rem 0.7rem;
  .creds-title {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1rem;
    color: #000000;
  }
  .creds-ul {
    .creds-li {
      display: flex;
      justify-content: space-between;
      height: 150px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 10px;
      margin-bottom: 0.7rem;
      padding: 2.5rem;

      .creds-li-left {
        display: flex;
        align-items: center;
        flex-grow: 2;

        img {
          width: 90px;
        }

        .creds-info-box {
          padding-left: 1rem;

          .creds-title {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 0.3rem !important;
            margin: 0;
            letter-spacing: 0;
          }

          .creds-date {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            color: #888888;

            @media screen and (max-width: 968px) {
              font-size: 14px;
            }
          }
        }
      }

      .creds-li-right {
        display: flex;
        justify-content: right;
        align-items: center;

        .btn-share-cred,
        .btn-download-cred {
          width: 200px;
          height: 50px;
          border: 1px solid #00819d;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 15px;
            margin-right: 16px;

            @media screen and (max-width: 968px) {
              margin-right: 8px;
            }
          }

          span {
            font-family: 'Cabin';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.01em;
          }
        }

        .btn-share-cred {
          background-color: #00819d;
          // margin-right: 0.5rem;
          order: 2;

          span {
            color: #ffffff;
            letter-spacing: 0.02em;
          }
        }

        .btn-download-cred {
          margin-right: 0.3rem;

          background-color: #ffffff;
          span {
            color: #00819d;
          }
        }
      }
    }
  }
}

.creds-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-share-cred,
  .btn-download-cred {
    width: 49%;
    height: 50px;
    border: 1px solid #00819d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 968px) {
      height: 40px !important;
    }

    img {
      margin-right: 16px;

      @media screen and (max-width: 968px) {
        margin-right: 6px;
        
      }
    }

    span {
      font-family: 'Cabin';
      font-weight: 500;
      font-size: 14px;
      /* identical to box height, or 100% */
      letter-spacing: 0.01em;
    }
  }

  .btn-share-cred {
    background-color: #00819d;
    margin-right: 0.5rem;
    
    img {
      @media screen and (max-width: 968px) {
        width: 14px;
      }
    }

    span {
      color: #ffffff;
    }
  }

  .btn-download-cred {
    background-color: #ffffff;

    img {
      @media screen and (max-width: 968px) {
        width: 13px;
      }
    }

    span {
      color: #00819d;
    }
  }
}

@media screen and (min-width: 1020px) {
  .creds-container {
    padding: 1rem 4rem;
    .creds-title {
      font-size: 18px;
    }
    .creds-ul {
      .creds-li {
        margin-bottom: 1.5rem;

        .creds-li-left {
          img {
            width: 106px;
          }
          .creds-info-box {
            padding-left: 2rem;
            .creds-title {
              margin-bottom: 1rem !important;
            }
            .creds-date {
              font-size: 20px;
            }
          }
        }

        .creds-li-right {
          .btn-share-cred,
          .btn-download-cred {
            img {
              height: 20px;
            }
            span {
              font-size: 15px;
            }
          }

          .btn-share-cred {
            margin-right: 0.5rem;
            order: 1;
          }

          .btn-download-cred {
            margin-right: 0;
            order: 2;
          }
        }
      }
    }
  }
}
