.profile {
  height: calc(100vh - 60px);
  .icon {
    color: #4cbbca;
  }
  .profile-scroll {
    position: relative;
  }
  .editIcon {
    color: #707071 !important;
  }
  .MuiFormControl-root {
    width: 100%;
    // max-width: 500px;
  }
  .MuiOutlinedInput-input {
    padding: 10px;
  }
  .MuiTextField-root {
    width: 95%;
    // max-width: 375px;
  }
  .MuiTextField-root,
  .MuiGrid-container {
    flex-wrap: nowrap;
    grid-gap: 5px;
  }
  .MuiListItemText-secondary {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  form > div:first-child {
    align-items: start;
  }
  .MuiListItemText-secondary > p {
    // width: 55%;
    max-width: 500px;

    svg {
      right: 0;
    }
  }
  button {
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    
  }

  // span {
  //   font-weight: bold;
  // }
  .listItemText > span:first-child {
    font-weight: bold;
  }
  .caption {
    color: #000000;
  }
  ul {
    padding: 0;
  }
  .picture-button {
    position: absolute;
    left: 53%;
    top: 40%;
  }
  .avatar-header {
    font: normal normal bold 42px/55px $headerFont;
    margin: 0;
    position: relative;
  }
  .slick-slide {
    padding: 0 16px;
  }
  .card {
    border-radius: 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 9px 15px #8690a538;
    border: 1px solid #edf0f7;
    .description {
      padding: 8px 16px;
      p {
        color: #144cc7;
        font-weight: bold;
        font-size: 12px;
        margin: 0 0 32px;
      }
      h6 {
        margin: 0;
        font-weight: normal;
        font: normal normal normal 14px/21px Roboto;
      }
    }
    a {
      color: #144cc7;
      font-weight: bold;
    }
  }
  .card-two {
    text-align: center;
    h1 {
      margin: 16px 0 0 !important;
      width: 100%;
      color: #144cc7;
    }
    h1,
    h3 {
      margin: 0;
    }
    .steps {
      padding: 16px;
      display: flex;
      margin-top: 16px;
      justify-content: center;
      .steps-container {
        margin: 0 auto;
        width: 24%;
        .steps-line {
          height: 8px;
          width: 100%;
          margin-bottom: 8px;
          border-radius: 10px;
          background: #144cc7;
        }
        p {
          margin: 0;
          line-height: 1;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
  .menu-link {
    padding: 16px;
    border-bottom: $borderBottom;
    a {
      text-decoration: none;
      color: #000;
      font-size: 15px;
    }
  }
  .sign-out {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 18px;
  }
  .sign-out:hover {
    cursor: pointer;
  }
  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 16px;
  }

  .pencil {
    height: 15px;
  }
  
  @media screen and (min-width: 900px) {
    .picture-button {
      left: 51%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .profile {
    height: calc(100vh - 64px);
  }
}

.save-profile-edit {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 100%;
    background: #25C522;
    font-weight: bold;
    color: #fff;
  }
}

.profile--input {
  margin-top: 8px;
  margin-bottom: 4px;
  font-family: $mainFont;
  font-size: 14px;

  input {
    border: 1px solid rgb(179, 179, 179);
    outline: none;
    border-radius: 3px;
    margin-left: 4px;
    padding: 4px 8px;
    font-size: 12px;
  }
}


// .option--input {
//   position: absolute;
//   margin-top: -28px;
//   right: 16px;
// }

// .option--comma {
//   margin-right: 3px;
// }

.option--comma:last-child {
  display: none;
}

.save-button {
  display: flex;
  justify-content: center;
}

.profile-wrapper .save-button button,
.profile-select {
  max-width: 500px;
  margin: 0 auto;
}