.list--card {
    border-bottom: 1px solid #CCCCCC;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    color: #666666;
    padding: 16px 0;
    // margin: 0 auto;
    // max-width: 1236px;
    width: 100%;

    &--content {
        &--title {
            width: 350px;

            @media screen and (max-width: 600px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &--svg--type {
        // width: 30px;
        img {
            width: 18px;
            height: auto;
        }

        .img {
            width: 18px;
        }
    }

    &--container--date {
        width: 100px;
    }
    

    td {
        // width: 100%;
        // margin-right: 64px;
        border-bottom: 1px solid #CCCCCC;
        padding: 16px 0;

        .list--flex--end {
            justify-content: flex-end;
        }
        span {
            display: flex !important;
            align-items: center;
             .title {
                margin: 0 12px;
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 16px;
                color: #00819D;
                cursor: pointer;
                width: fit-content;
            }
        }

       .rating {
            margin-right: 20px;
            display: flex;
            align-items: center;

            p {
                margin: 0;
                margin-left: 4px;
                color: $mainColor;
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 12px;
            }
        }

    }

    @media screen and (max-width: 1240px) {
        padding: 0 32px 24px;
    }

    p {
        margin: 0;
    }

    &--circle--red {
        background: #DF187D;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        cursor: pointer;
    }

    &--circle--gray {
        background: #eee;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        cursor: pointer;
    }


    &--container {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        // p {
        //     margin-right: 64px;
          
        // }

        &--title {
            p {
                margin: 0 12px;
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 16px;
                color: #00819D;
            }
        }

        &--content {
            color: #666666;
            font-family: 'Cabin';
            font-weight: 400;

        }

        &--type {
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            color: #666666;
        }

        &--time {
            font-size: 12px;
            background: #EEEEEE;
            border-radius: 3px;
            width: 50px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--rating {
            margin-right: 20px;
            display: flex;
            align-items: center;

            p {
                margin: 0;
                margin-left: 4px;
                color: $mainColor;
                font-family: 'Cabin';
                font-weight: 500;
                font-size: 12px;
            }
        }

        &--title, 
        &--content {
            display: flex !important;
            align-items: center;
        }
    }

    &--sharing,
    &--tag {
        margin-right: 16px;
    }
}

.list--card--mobile {
    span {
        align-items: flex-start !important;
    }
    &--content {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;
    }

    .title {
        margin-left: 0 !important;
        font-size: 15px !important;
    }

    .list--card--container--type {
        margin-bottom: 4px;
    }

    .list--card--container--date {
        font-size: 12px !important;
        margin-top: 4px;
    }

    .time {
        height: 16px !important;
    }

    &--image {
        width: 40px;
    }
    
    &--flex--end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &--right {
        display: flex;
        align-items: center;

        img {
            height: 13px;
            width: auto;
        }
    }

    .rating {
        margin-top: 12px;
        align-items: center !important;
        justify-content: space-between;
        width: 100%;
    }

    .list--card--circle--gray,
    .list--card--circle--red {
        margin-left: 0 !important;
        align-items: center !important;
        justify-content: center !important;
    }
}