.library-search-users {
    margin-top: 0px;
    font-family: $mainFont;

    .filter-left .img-left {
        margin-left: 0;
    }

    h4 {
        color: #000;
        margin: 0;
        margin-bottom: 16px;
        font-size: 24px;
        font-family: $mainFont !important;
    }

	.search-content {
		position: relative;
		top: 12px;
		margin: 0;
	}

	.group-avatar {
		width: 22px;
		height: 22px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $mainColor;
	}

    .MuiAvatar-colorDefault {
        background-color: $mainColor !important;
    }

	.video-image {
		width: 38px;
		height: 38px;
		margin-right: 24px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $mainColor;
	}

	.add-padding {
		padding-bottom: 40px;
	}
	.filter-left {
		position: relative;
	}
	.custom-select {
		margin-left: 14px;
		margin-top: 6px;
		margin-bottom: 6px;

		select {
			-webkit-appearance: menulist-button;
			color: $mainColor;
			border: none;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 14px;
            font-family: $mainFont !important;
		  }
		  
		  select:invalid {
			color: $mainColor;
		  }
	}
	.message {
		padding: 32px 0;
        font-family: $mainFont !important;
	}

    textarea {
        font-family: $mainFont !important;
    }

	::placeholder {
		font-size: 18px;
		color: #707070;
        font-family: $mainFont !important;
	}

	input {
		outline: none;
        font-family: $mainFont !important;
	}

    .user-list {
       height: auto; 

        li {
            padding: 16px 0 !important;
        }
    }
}